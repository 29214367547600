import React, { useCallback, useState } from "react";
import styled from "styled-components";

import IconButton from "@components/IconButton";
import { CasesIcon, ChemicalsIcon, CostsIcon } from "@components/SVGs";

import { ChemicalLibraryDialog, ProjectCostDialog } from "@features/ChemicalLibrary";

const ActionBtnContainer = styled.div`
  position: absolute;
  left: 50%;
`;

const IconBtnWithLabel = styled.div`
  display: flex;
  flex-flow: column;
  padding: 5px;
  align-items: center;
  color: rgba(255, 255, 255);

  span {
    font-size: 14px;
    font-family: NotoSans;
    font-weight: 700;
  }

  &:hover {
    color: #007672;
    background: #6dd8cd;
    border: 0px solid #6dd8cd;
    border-radius: 5px;

    svg, path {
      fill: #007672;
    }
  }
`;

const ButtonsConfigList = [
  { id: "chemicalsBtn", label: "Chemicals", icon: <ChemicalsIcon /> },
  { id: "costsBtn", label: "Costs", icon: <CostsIcon /> },
  { id: "casesBtn", label: "Cases", icon: <CasesIcon /> },
];

const RibbonIconButtons = () => {

  const [activeAction, setActiveAction] = useState(null);

  const handleButtonClick = useCallback(({ target }) => setActiveAction(target.id), []);
  const handleDailogClose = useCallback(() => setActiveAction(null), []);

  //TODO: Need to enable it in SIT and Prod once the feature is ready
  if (process.env.REACT_APP_PROJECT_ENV?.toUpperCase() !== "DEV") return null;

  return (
    <>
      <ActionBtnContainer>
        {ButtonsConfigList.map((item, index) => (
          <IconButton id={item.id} key={index} onClick={handleButtonClick}>
            <IconBtnWithLabel>
              {item.icon}
              <span>{item.label}</span>
            </IconBtnWithLabel>
          </IconButton>
        ))}
      </ActionBtnContainer>
      {activeAction === "chemicalsBtn" && <ChemicalLibraryDialog onClose={handleDailogClose} />}
      {activeAction === "costsBtn" && <ProjectCostDialog onClose={handleDailogClose} />}
    </>
  );
};

export default RibbonIconButtons;