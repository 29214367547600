import { useReportUtils } from "@features/report-new/useReportUtils";

import { WPUfWaterQualityTableDataRows as rowsData } from "./WPUfWaterQualityTable.constants";

export const useWPUfWaterQualityTableData = () => {
  const { ufReportRespData, formatUnitLabel, formatConvertValue } = useReportUtils();
  const { uf_water_quality = {} } = ufReportRespData || {};

  const convertToUfWaterQualityTableData = () => {
    const data = Object.entries(rowsData).reduce((acc, curr) => {
      const [key, row] = curr;
      const { feed, product, display = true } = uf_water_quality[key] || {};
      if (!display) return acc;
      acc.push({
        col1: row.label,
        col2: formatUnitLabel(row.unit),
        col3: formatConvertValue(feed, row.unit),
        col4: formatConvertValue(product, row.unit),
      });
      return acc;
    }, []);

    return data;
  };

  return { convertToUfWaterQualityTableData };
};
