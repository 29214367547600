import styled from "styled-components";

import { colors, fontStyles } from "../styles/Theme";

import "@common/styles/notoSansFont.css";
import "@common/styles/diodrumFont.css";

const getMarginLeft = (locationCheck, tabCheck) => {
  if (locationCheck === "/home" && tabCheck !== "error") {
    return "19%";
  }
  if (tabCheck === "IXD" || tabCheck === "UF" || tabCheck === "IXMB" || tabCheck === "Report New") {
    return "24%";
  }
  return "0%";
};

const FooterStyled = styled.footer`
  padding: 20px 30px;
  background-color: ${colors.PrimaryDarkAquaMarine};
  display: flex;
  margin-left: ${props => getMarginLeft(props.locationCheck, props.tabCheck)};
  .footer-list {
    width: 55%;
    z-index: 9;
    .footer_list_items {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 10px;
      .vertical_line {
        width: 1px;
        height: 12px;
        background-color: #fff;
      }
      a {
        font-family: "DiodrumRegular";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #6dd8cd;
        text-decoration: none;
      }
    }
    .footer_list_items a {
      :first-child {
        padding-left: 0px;
      }
      :last-child {
        border-right: none;
      }
    }
    .copyright {
      max-height: 46px;
      p {
        color: #fff;
        font-family: "NotoSansRegular", "sans-serif";
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
      }
    }
  }

  .footer-logo {
    width: 45%;
    display: flex;
    gap: 12px;
    justify-content: end;
    align-items: center;
    .logo-text {
      h6 {
        font-family: "DiodrumSemiBold";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: right;
        justify-content: end;
        color: #ffffff;
      }
      .text-right {
        font-family: "DiodrumRegular";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 0px;
        display: flex;
        justify-content: end;
        color: #ffffff;
        margin-bottom: 8px;
      }
    }
    .color2 {
      ${fontStyles.diodrum11Light}
      color:${colors.LightLightTeal};
      display: flex;
      justify-content: end;
      margin-bottom: 0;
    }
    img {
      width: 67px;
      height: 72px;
    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    padding: 15px 15px;
  }
  @media (max-width: 1200px) {
    margin-left: 0px;
  }
`;

export default FooterStyled;
