import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";
import {
  ChemicalCategroyRenderer,
  ChemicalConentrationRenderer,
  ChemicalDisplayNameRenderer,
  ChemicalDropdownRenderer,
  ChemicalProjectCostRenderer,
} from "../ChemicalTableRenderer";

export const PROJECT_COST_TABLE_CONFIG = [
  {
    id: 1,
    headerName: CHEMICALS_LIBRARY_STRINGS.displayName,
    field: "displayName",
    cellRenderer: ChemicalDisplayNameRenderer,
    width: 150,
  },
  { id: 2, headerName: CHEMICALS_LIBRARY_STRINGS.chemicalName, field: "chemicalName", width: 180 },
  {
    id: 3,
    headerName: CHEMICALS_LIBRARY_STRINGS.bulkConcentration,
    field: "bulkConcentration",
    width: 115,
    cellRenderer: ChemicalConentrationRenderer,
  },

  {
    id: 4,
    headerName: CHEMICALS_LIBRARY_STRINGS.chemicalCat,
    field: "chemicalCat",
    width: 125,
    cellRenderer: ChemicalCategroyRenderer,
  },
  {
    id: 5,
    headerName: CHEMICALS_LIBRARY_STRINGS.symbol,
    field: "symbol",
    width: 100,
  },
  {
    id: 6,
    headerName: CHEMICALS_LIBRARY_STRINGS.bulkDensity,
    field: "bulkDensity",
    width: 100,
  },
  {
    id: 7,
    headerName: CHEMICALS_LIBRARY_STRINGS.bulkPrice,
    field: "bulkPrice",
    width: 128,
    cellRenderer: ChemicalProjectCostRenderer,
  },
  {
    id: 8,
    headerName: CHEMICALS_LIBRARY_STRINGS.costType,
    field: "costType",
    width: 110,
    cellRenderer: ChemicalDropdownRenderer,
  },
];
