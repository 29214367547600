import { reportStrings } from "@constants/report.strings";

import { useReportUtils } from "@features/report-new/useReportUtils";

import {
  WPUfPumpHydraulicsTableColumns as columns,
  WPUfPumpHydraulicsTableRowsData as rowsData,
  WPUfPumpHydraulicsUnitMapping as unitMapping,
} from "./WPUfPumpHydraulicsTable.constants";

export const useWPUfPumpHydraulicsTableData = () => {
  const {
    ufReportRespData,
    formatConvertValue,
    formatLabel,
    formatUnitLabel,
    formatChemicalNameConc,
    formatCostUnitLabel,
    formatConvertCostValuePerUnit,
  } = useReportUtils();
  const { pump_electrical } = ufReportRespData || {};

  const convertToUfPumpHydraulicsTableData = () => {
    const firstRow = columns.reduce((acc, curr) => {
      if (curr.field === "cost") {
        acc.cost = formatCostUnitLabel(unitMapping[curr.field]);
      } else {
        acc[curr.field] = formatUnitLabel(unitMapping[curr.field]);
      }
      return acc;
    }, {});

    const data = Object.entries(rowsData).reduce((acc, curr) => {
      const [rowKey, row] = curr;
      const { label, isChemicalPump } = row;
      const apiSingleRowData = pump_electrical?.[rowKey] || {};
      const { display = true, name, concentration } = apiSingleRowData;

      if (!display) return acc;

      const singleRow = columns.reduce((acc, curr) => {
        const colKey = curr.field;
        if (colKey === "label") {
          const labelValue = isChemicalPump
            ? formatChemicalNameConc(name, concentration) + " " + reportStrings.meteringPump
            : label;
          const indentLevel = isChemicalPump ? 1 : 0;
          acc.label = formatLabel(labelValue, indentLevel);
        } else if (colKey === "cost") {
          acc[colKey] = formatConvertCostValuePerUnit(apiSingleRowData?.[colKey], unitMapping[curr.field], 2);
        } else {
          // precision is always 2 for this table overriding units precision
          acc[colKey] = formatConvertValue(apiSingleRowData?.[colKey], unitMapping[curr.field], 2);
        }
        return acc;
      }, {});

      acc.push(singleRow);
      return acc;
    }, []);

    // adding unit row at the top
    data.unshift(firstRow);

    return data;
  };

  return { convertToUfPumpHydraulicsTableData };
};
