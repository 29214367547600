import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactFlow, { ReactFlowProvider } from "reactflow";

import { TECHNOLOGIES } from "@constants/global.constants";

import useGlobalDataHandler from "@hooks/useGlobalDataHandler";

import { convertHtmlToBase64, isProdEnv } from "@utils/appUtils";

import CloseBlackIcon from "@common/icons/CloseBlackIcon";
import InfoPictonBlueIcon from "@common/icons/InfoPictonBlueIcon";
import { setSystemDiagramBase64 } from "@common/ReportSlice";
import CustomButton from "@common/styles/components/buttons/standard/CustomButton";
import TechButtons from "@common/styles/components/buttons/techButtons/TechButtons";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import { colors } from "@common/styles/Theme";
import DynamicLoadder from "@common/utils/DynamicLoadder";
import { MyError } from "@common/utils/ErrorCreator";
import ErrorPopup from "@common/utils/ErrorPopup";
import GlobalUnitConversion from "@common/utils/GlobalUnitConversion";
import { updateUnitTypeFlow } from "@common/utils/GlobalUnitConversionSlice";

import { useLazyGetAllDataQuery, useUpdateDataMutation } from "@services/apiConfig";

import { updateShowFinalParameterTab, updateViewReport } from "../ix/IXDSlice";
import { isIXTechnology } from "../ix/IXDUtilityFunction";

import CustomeEdge from "./customnodes/CustomeEdge";
import DeleteNode from "./customnodes/DeleteNode";
import EndPointNode from "./customnodes/EndPointNode";
import Technology from "./customnodes/technology.json";
import TechnologyNode from "./customnodes/TechnologyNode";
import FeedTechnologyStyled from "./FeedTechnologyStyled";
import {
  addNode,
  deleteNode,
  onConnect,
  onEdgesChange,
  onNodesChange,
  setNodeAndEdge,
  setUpdateCanvas,
  setUpdateCategory,
} from "./processDiagramSlice";

import "reactflow/dist/style.css";
import "./index.css";

let id = 0;
const getId = () => `${id++}`;

const FeedTechnology = () => {
  const { isTechDisabled } = useGlobalDataHandler();
  const [notification, setNotification] = useState(true);
  const [techAddedError, setTechAddedError] = useState(false);
  const [getSystemData, responseSystemData] = useLazyGetAllDataQuery();
  const [updateData, response] = useUpdateDataMutation();
  const [techCate, setTechCat] = useState([]);
  const [getCateData, responseCateData] = useLazyGetAllDataQuery();
  const dispatch = useDispatch();
  const reactFlowWrapper = useRef(null);
  const {
    nodes,
    edges,
    isLoading,
    techNolist,
    technologyAdded,
    feedWaterData,
    canvasStyle,
    selectedEndNode,
    feedFlowRate,
    productFlowRate,
    lstTechnologyLists,
    needToRetriveData,
    systemDesignCaseTreatmentVM,
  } = useSelector(state => state.processDiagramSlice);
  const { ufReportLoading } = useSelector(state => state.ReportUF);
  const ProjectInfoStore = useSelector(state => state.projectInfo.data);
  const userId = useSelector(state => state.userInfo.data.UserId);
  const { getCategoryData } = useSelector(state => state.processDiagramSlice);
  const projectID = ProjectInfoStore?.projectID ? ProjectInfoStore.projectID : 1;
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const GlobalUnitConversionStore = useSelector(state => state.GUnitConversion.data);

  const nodeTypes = useMemo(
    () => ({
      endPointNode: EndPointNode,
      techNode: TechnologyNode,
      deleteType: DeleteNode,
    }),
    [],
  );

  const isInersect = ({ position }) => {
    const { width } = canvasStyle;
    const { x, y } = position;
    return x > width - 201.94 && x < width && y > 228 && y < 346;
  };
  useEffect(() => {
    dispatch(updateUnitTypeFlow(unit.selectedUnits[1]));
  }, [unit.selectedUnits[1]]);

  useEffect(() => {
    if (responseSystemData.isSuccess || !isLoading || !ufReportLoading) {
      // waiting for the component to render the system diagram
      setTimeout(() => {
        ConvertSysDiagramToBase64();
      }, 250);
    }
  }, [responseSystemData, isLoading, ufReportLoading]);

  const onCanvasClone = clonedDoc => {
    /* The purpose of this function is to replace the input fields with divs when converting to canvas
    The reason behind this is that the input fields texts are having misalignment issue in the canvas */
    clonedDoc.querySelectorAll("input").forEach(input => {
      if (input.id === "productFlowRate" || input.id === "feedFlowRate") {
        const div = document.createElement("div");
        div.textContent = input.value;
        div.style.width = "100%";
        div.style.padding = getComputedStyle(input).padding;
        div.style.lineHeight = getComputedStyle(input).lineHeight;
        div.style.height = getComputedStyle(input).height;
        input.replaceWith(div);
      }
    });
    const parentDiv = clonedDoc.querySelector(".react-flow__viewport");
    parentDiv.style.backgroundColor = "white";
    // this height is a static height for now , else the height of the canvas will be very large
    parentDiv.style.height = "94px";
  };

  const ConvertSysDiagramToBase64 = async () => {
    const base64 = await convertHtmlToBase64(".react-flow__viewport", { onclone: onCanvasClone });
    dispatch(setSystemDiagramBase64(base64));
  };

  useEffect(() => {
    if (projectID !== 1 || getCategoryData) {
      getCateData(`masterdata/api/v1/CategoryTreatment?projectID=${projectID}`);
      handleResize();
    }
  }, [projectID, getCategoryData]);

  useEffect(() => {
    if (responseCateData.isError) {
      throw new MyError("CategoryTreatment Api Error", responseCateData.error.status, "ApiError");
    }
    if (responseCateData.isSuccess) {
      const temp = [];
      responseCateData.data.map(cat => {
        const catName = isIXTechnology(cat.treatmentName) ? "IX" : cat.treatmentName;
        if (cat.isSelected === 1 && !isTechDisabled(catName)) {
          temp.push(cat.treatmentName);
        }
      });
      setTechCat(temp);
      dispatch(setUpdateCategory(false));
    }
  }, [responseCateData]);

  const handleClose = () => {
    setNotification(false);
  };

  const onDragStart = (event, nodeType) => {
    const transferData = JSON.stringify(nodeType);
    event.dataTransfer.setData("application/reactflow", transferData);
    event.dataTransfer.effectAllowed = "move";
  };

  const onDragOver = useCallback(event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = event => {
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const { type, ...data } = JSON.parse(event.dataTransfer.getData("application/reactflow"));

    if (typeof type === "undefined" || !type) {
      return;
    }
    if (data.label === "Adjust Final pH") {
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const id = getId();
      const newNode = {
        id: id,
        type,
        position,
        data: data,
      };
      dispatch(addNode(newNode));
      dispatch(updateViewReport("false"));
      dispatch(updateShowFinalParameterTab(false));
    } else {
      if (technologyAdded) {
        setTechAddedError(true);
      } else {
        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top,
        });
        const id = getId();
        const newNode = {
          id: id,
          type,
          position,
          data: data,
        };
        dispatch(addNode(newNode));
        dispatch(updateViewReport("false"));
        dispatch(updateShowFinalParameterTab(false));
      }
    }
    event.preventDefault();
  };

  const isValidConnection = edge => {
    const { target, source } = edge;
    const targetAvailable = edges.find(item => item.target === target);
    const sourceAvailable = edges.find(item => item.source === source);
    return !targetAvailable && !sourceAvailable;
  };
  //dj---------------------------------------------------------

  const handleResize = () => {
    if (reactFlowWrapper.current) {
      const containerWidth = reactFlowWrapper.current.offsetWidth;
      dispatch(
        setUpdateCanvas({
          width: containerWidth,
          height: reactFlowWrapper.current.offsetHeight,
        }),
      );
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // handleResize();
  useEffect(() => {
    handleResize();
    if (reactFlowWrapper.current?.firstChild?.firstChild?.firstChild?.firstChild.style.transform) {
      // console.log("reactFlowWrapper",reactFlowWrapper.current.firstChild.firstChild.firstChild.firstChild.style.transform);
      reactFlowWrapper.current.firstChild.firstChild.firstChild.firstChild.style.transform =
        "translate(0px, 0px) scale(1)";
    }
  }, [feedWaterData, reactFlowWrapper]);
  //--------------------------------------------------------
  useEffect(() => {
    if (needToRetriveData) {
      updateData({
        Method: "masterdata/api/v1/SystemDesign",
        feedFlow: selectedEndNode != "startNode",
        flowValue:
          selectedEndNode == "startNode"
            ? Number(
                GlobalUnitConversion(GlobalUnitConversionStore, feedFlowRate, "m³/h", unit.selectedUnits[1]).toFixed(2),
              )
            : Number(
                GlobalUnitConversion(GlobalUnitConversionStore, productFlowRate, "m³/h", unit.selectedUnits[1]).toFixed(
                  2,
                ),
              ),
        caseID: feedWaterData.caseID,
        projectID: feedWaterData.projectID,
        waterTypeID: feedWaterData.waterTypeID,
        userID: userId,
        processMap: { nodes: nodes, edges: edges },
        lstTechnologyLists,
      });
    }
  }, [needToRetriveData]);
  useEffect(() => {
    if (response.isSuccess) {
      const url =
        feedWaterData.caseID === 0
          ? `masterdata/api/v${1}/SystemDesign?userID=${userId}&projectID=${feedWaterData.projectID}`
          : `masterdata/api/v${1}/SystemDesign?userID=${userId}&projectID=${feedWaterData.projectID}&caseID=${feedWaterData.caseID}`;
      const response = getSystemData(url);
    }
  }, [response]);

  useEffect(() => {
    if (responseSystemData.status == "fulfilled" && needToRetriveData) {
      dispatch(
        setNodeAndEdge({
          ...responseSystemData.data,
          ["flow"]: Number(
            GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseSystemData.data.flow,
              unit.selectedUnits[1],
              "m³/h",
            ).toFixed(2),
          ),
        }),
      );
    }
  }, [responseSystemData]);

  const isUFReportDataLoading = () =>
    ufReportLoading && systemDesignCaseTreatmentVM.find(item => item.treatmentName == "UF");

  const isIXDDisabled = ({ label }) =>
    (isProdEnv() && label === TECHNOLOGIES.IXD) || !techCate.includes(label) === !techNolist.includes(label);

  return (
    <>
      <FeedTechnologyStyled className='g-0'>
        {notification && (
          <div className='notification'>
            <div className='wrapper'>
              <InfoPictonBlueIcon />
              <ul className='notification-details'>
                <li>
                  <CustomHeading
                    fontFamily='DiodrumRegular'
                    fontSize='14px'
                    fontWeight='400'
                    color={colors.Black}
                    label='You are viewing technologies as per your preference and can edit them from Project Settings.'
                  />
                </li>
                <li>
                  <CustomHeading
                    fontFamily='DiodrumRegular'
                    fontSize='14px'
                    fontWeight='400'
                    color={colors.Black}
                    label='Specify either Feed flow rate OR Product flow rate.'
                  />
                </li>
                <li>
                  <CustomHeading
                    fontFamily='DiodrumRegular'
                    fontSize='14px'
                    fontWeight='400'
                    color={colors.Black}
                    label='Select technologies by dragging and dropping the icons from the top panel.'
                  />
                </li>
              </ul>
            </div>
            <CustomButton label={<CloseBlackIcon />} id='closeBtn' onClick={handleClose} />
          </div>
        )}
        <ErrorPopup
          show={techAddedError}
          close={() => setTechAddedError(false)}
          message={"Only one technology can be added!"}
        />

        <div className='water-technology'>
          {Technology.data.map((tech, index) => (
            <StyledCard className={tech.className} key={index} borderRadius='4px'>
              <div className='process-name'>
                <CustomHeading
                  label={tech.techName}
                  fontFamily='DiodrumRegular'
                  fontSize='12px'
                  fontWeight='400'
                  color={colors.Black}
                />
              </div>
              <div className='tech-btn-group'>
                {tech.subTech.map((item, ind) => (
                  <div
                    key={ind}
                    onDragStart={event =>
                      onDragStart(event, {
                        ...item.nodeData,
                        treatmentObjID: item.treatmentObjID,
                      })
                    }
                    draggable={techCate.includes(item.label)}
                  >
                    <TechButtons
                      label={item.label}
                      disabled={isIXDDisabled(item)}
                      isDraggable
                      isArrow={item.isArrow}
                      id={item.id}
                      onClick={() => (technologyAdded ? setTechAddedError(true) : null)}
                    />
                  </div>
                ))}
              </div>
            </StyledCard>
          ))}
          <StyledCard className='bulk-demineralization' borderRadius='4px'>
            <div className='process-name'>
              <CustomHeading
                label='Water Chemistry Adjustments'
                fontFamily='DiodrumRegular'
                fontSize='12px'
                fontWeight='400'
                color={colors.Black}
              />
            </div>
            <div className='tech-btn-group'>
              <div
                onDragStart={event =>
                  onDragStart(event, {
                    label: "Adjust Final pH",
                    color: "green",
                    type: "techNode",

                    treatmentObjID: 12,
                  })
                }
                draggable={false}
              >
                <TechButtons label='Adjust Final pH' disabled={true} isArrow={false} id='APH' isDraggable={true} />
              </div>
            </div>
          </StyledCard>
        </div>

        <DynamicLoadder isLoading={isLoading || isUFReportDataLoading()}>
          <div className='feed-water-process'>
            <div className='sys-diagram-wrapper'>
              <div className='dndflow'>
                <ReactFlowProvider>
                  <div className='reactflow-wrapper' ref={reactFlowWrapper}>
                    <ReactFlow
                      nodes={nodes}
                      edges={edges}
                      nodeTypes={nodeTypes}
                      edgeTypes={{ customeEdge: CustomeEdge }}
                      onNodeDragStop={(e, node) => {
                        if (isInersect(node)) dispatch(deleteNode(node));
                      }}
                      onNodesChange={e => dispatch(onNodesChange(e))}
                      onEdgesChange={e => dispatch(onEdgesChange(e))}
                      onConnect={e => dispatch(onConnect(e))}
                      onInit={setReactFlowInstance}
                      onDrop={onDrop}
                      onDragOver={onDragOver}
                      preventScrolling={false}
                      isValidConnection={edge => isValidConnection(edge)}
                      deleteKeyCode={"Delete"}
                      panOnDrag={false}
                      panOnScroll={false}
                      zoomOnScroll={false}
                      zoomOnDoubleClick={false}
                      onNodesDelete={node => dispatch(deleteNode(node[0]))}
                      nodeExtent={[
                        [0, 0],
                        [canvasStyle.width, 346],
                      ]}
                      translateExtent={[
                        [0, 0],
                        [canvasStyle.width, 346],
                      ]}
                      Viewport={{ x: 0, y: 0, zoom: 1 }}
                    ></ReactFlow>
                  </div>
                </ReactFlowProvider>
              </div>
            </div>
          </div>
        </DynamicLoadder>
      </FeedTechnologyStyled>
    </>
  );
};

export default FeedTechnology;
