import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfStorageTanksTableColumns as columns } from "./WPUfStorageTanksTable.constants";

export const WPUfStorageTanksTable = () => {
  const { uf_Storage_tanks: rows } = useSelector(state => state.report.ufReportTableData);

  return (
    <WPTable
      sectionHeader={reportStrings.ufStorageTanks}
      columns={columns}
      rows={rows}
      footNotesList={[reportStrings.storageTanksTableFootNotes1, reportStrings.storageTanksTableFootNotes2]}
    />
  );
};
