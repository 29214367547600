export const DesignStrings = {
  ufFeedFlowRate: "UF Feed Flow Rate",
  automatic: "Automatic",
  ufTechnologySelection: "UF Technology Selection",
  ufTechnology: "UF Technology",
  designFlowFluxRates: "Design Instantaneous (Gross) Flux and Flow Rates",
  backwashFlush: "Backwash Flux",
  cebFlux: "CEB Flux",
  forwardFlushFlow: "Forward Flush Flow",
  airFlow: "Air Flow",
  aerationAirFlow: "Aeration Air Flow",
  cIPRecycleFlowRate: "CIP Recycle Flow Rate",
  miniCIPRecycleFlowRate: "mini-CIP Recycle Flow Rate",
  forwardFlush: "Forward Flush",
  uFSpecialFeatures: "UF Special Features",
  addChemicalDegas: "Add Chemicals/Degas",
  cEBOnly: "CEB only",
  miniCIPOnly: "mini-CIP only",
  CEBAndMiniCIP: "CEB and mini-CIP",
  designCycleInterval: "Design Cycle Intervals",
  filtrationDuration: "Filtration Duration",
  miniCip: "mini-CIP",
  acidCEB: "Acid CEB",
  disinfectionCEB: "Disinfection CEB",
  membraneIntegrityTesting: "Membrane Integrity Testing",
  offlineTimeperUnit: "Offline Time per Unit",
  airScour: "Air Scour",
  drain: "Drain",
  bWRinseDrainBottom: "BW Rinse Drain Bottom",
  bWRinseDrainTop: "BW Rinse Drain Top",
  cebTemprature: "CEB Temperature",
  digramForFeedWater: "Use design temperature from feed water",
  waterSource: "Water Source",
  cebWaterSource: "CEB Water Source",
  mineralAcid: "Mineral Acid",
  organicAcid: "Organic Acid",
  alkaliOxidantCEB: "Alkali/Oxidant CEB",
  alkali: "Alkali",
  oxidant: "Oxidant",
  duration: "Duration",
  durations: "Durations",
  chemicalSoakingDuration: "Chemical Soaking Duration",
  filtratetoLevel: "Filtrate to Level",
  lumenFill: "Lumen Fill",
  backwashesbetweenAirScour: "Backwashes between Air Scour",
  backwashTemprature: "Backwash Temperature",
  backwashWaterSource: "Backwash Water Source",
  forwardFlushWaterSource: "Forward Flush Water Source",
  backwashProtocol: "Backwash Protocol",
  cIP: "CIP",
  filtrateFlux: "Filtrate Flux",
  cipRecycleFlowRateInfo:
    "The CIP Recycle Flow Rate refers to the amount of fluid entering the system through the CIP Inlet Pipe during the cleaning process. During this step, the flow divides into two parts: one part is  the filtrate and the other part is the concentrate. Both parts are then circulated back to the modules.",
};

export const IngeString = {
  topbackwash: "BW Drain Bottom",
  bottomBackwash: "BW Drain Top",
  cebTopBackwash: "CEB Drain Bottom",
  cebBottomBackwash: "CEB Drain Top",
  selectedConfRackLabel: "T-Rack Design",
  recommConfigSliderMinRange: "Min T-Rack Size Limit: ",
  recommConfigSliderMaxRange: "Max T-Rack Size Limit: ",
};

export const DupontString = {
  topbackwash: "Top Backwash",
  bottomBackwash: "Bottom Backwash",
  cebTopBackwash: "Top Backwash",
  cebBottomBackwash: "Bottom Backwash",
  selectedConfRackLabel: "IP Rack Design",
  recommConfigSliderMinRange: "Min IP Rack Size Limit: ",
  recommConfigSliderMaxRange: "Max IP Rack Size Limit: ",
};

export const ValidationStrings = {
  slsRecommendedRange: "SLS Recommended Range",
  cipMineralAcidConectration: "CIP Mineral Acid Concentration",
  cipOrganicAcidConcentration: "CIP Organic Acid Concentration",
  cipAlkaliConcentration: "CIP Alkali Concentration",
  cipOxidantConcentration: "CIP Oxidant Concentration",
  cipRecycle: "CIP Recycle",
  cipHeatingStep: "CIP Heating Step",
  cipSoak: "CIP Soak",
};

export const FolderStrings = {
  restoreFolder: "Restore Folder",
  folderCreateSuccess: "The folder has been successfully created.",
  folderExists: "A folder with this name already exists. Specify a different name.",
};

export const IXStrings = {};

export const IXDStrings = {
  demineralization: "IX Demineralization",
  backwashFrequency: "Backwash Frequency (Cycles Between BW)",
  backwashExpansion: "Backwash Expansion (%)",
  backwashDuration: "Backwash Duration (min)",
};

export const IXMBStrings = {
  demineralization: "Mixed Bed",
  backwashFrequency: "MB Separation Backwash Frequency(Cycles)",
  backwashExpansion: "MB Separation Backwash Expansion",
  backwashDuration: "MB Separation Backwash Duration (min)",
  regenrationHoldDownFaction: "SBA/SAC Regeneration Flow Ration",
  WaterChemistryAdjustments: "Water Chemistry Adjustments",
};

export const ImportProjectStrings = {
  projectAlreadyExists: "Project already exists",
  projectNameKey: "diffgr:diffgram/NewDataSet/Project/ProjectName",
  errorParsingXML: "Error parsing XML",
  checkProjectDetails: "Check Project Details...",
  import: "Import",
  renameYourProject: "Rename your project",
  errorImportingFile: "Error importing your project",
  pleaseCheckTheFileName: "Please check the project name before importing.",
  editFileName: "Edit Project Name",
  projectNameExistDoYouWantToReplace: "Project name already exist, Do you want to replace?",
  projectReplaceConfirmation: "Are you really sure you want to replace the existing project with this one?",
  cancel: "Cancel",
  replace: "Replace",
  rename: "Rename",
  replaceProject: "Replace Project",
  errorExportingFile: "Error exporting your project",
  exporingProject: "Exporting Project",
  duplicatingProject: "Duplicating Project",
  successfulExport: "Project has been exported successfully!",
};
