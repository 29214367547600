import React, { useMemo } from "react";
import PropTypes from "prop-types";

import "./index.scss";

/**
 * @typedef {"text" | "contained" | "outlined"} Variant
 * @typedef {"small" | "medium" | "large"} Size
 * @typedef {"primary" | "danger" | "warning"} Type
 */

export const WPBUTTON_VARIANTS = {
    text: "text",
    contained: "contained",
    outlined: "outlined"
};

export const WPBUTTON_SIZES = {
    small: "small",
    medium: "medium",
    large: "large"
};

export const WPBUTTON_TYPES = {
    primary: "primary",
    danger: "danger",
    warning: "warning"
};

/**
 * @param {Object} props
 * @param {string} props.id
 * @param {Variant} [props.variant="contained"]
 * @param {Type} [props.type="primary"]
 * @param {Size} [props.size="medium"]
 * @param {React.ReactNode} props.children
 * @param {function} [props.onClick]
 * @param {boolean} [props.disabled]
 */
const WPButton = ({ id, variant, type, size, children, onClick, disabled }) => {
    const handleClick = () => {
        if (onClick) onClick({ target: { id } });
    };

    const cssClasses = useMemo(() => {
        const result = `wp-btn wp-btn__${variant}--${type} wp-btn--${size}`;
        return result;
    }, [variant, type, size]);

    return (
        <button id={id} name={id} onClick={handleClick} className={cssClasses} disabled={disabled}>
            {children}
        </button>
    );
};

WPButton.propTypes = {
    id: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(Object.values(WPBUTTON_VARIANTS)),
    type: PropTypes.oneOf(Object.values(WPBUTTON_TYPES)),
    size: PropTypes.oneOf(Object.values(WPBUTTON_SIZES)),
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
};

WPButton.defaultProps = {
    variant: WPBUTTON_VARIANTS.contained,
    type: WPBUTTON_TYPES.primary,
    size: WPBUTTON_SIZES.medium,
};

export default WPButton;