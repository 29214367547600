import React, { useState } from "react";

import { useCheckProjectExistence } from "@hooks/useCheckProjectExistence";

import { ImportProjectStrings } from "@utils/StringConstants";

import CustomModal from "@components/CustomModal";

import InputTextWithNameValidation from "@common/styles/components/inputs/inputTextWithNameValidation";

import "./index.css";

const EditProjectNamePopup = ({ projectName, handleClose, editedName, setEditedName, handleReplace, isNew }) => {
  const [doesProjectExist, setDoesProjectExist] = useState(false);
  const [checkProjectExistence, isCheckingProjectName] = useCheckProjectExistence();
  const isImportDisabled =
    !doesProjectExist ||
    isCheckingProjectName ||
    editedName?.trim() === "" ||
    editedName === null ||
    (editedName === projectName && !isNew) ||
    editedName.length < 3;

  return (
    <>
      <CustomModal
        header={ImportProjectStrings.renameYourProject}
        headerHelper={ImportProjectStrings.pleaseCheckTheFileName}
        confirmBtn='Import'
        onModalClose={handleClose}
        width='512px'
        confirmBtnDisabled={isImportDisabled}
      >
        <div className='edit-text'>
          <sup className='astrix'>*</sup>
          {ImportProjectStrings.editFileName}
        </div>

        <InputTextWithNameValidation
          value={projectName}
          doesProjectExist={doesProjectExist}
          onValidationChange={setDoesProjectExist}
          validateInput={checkProjectExistence}
          setEditedName={setEditedName}
          errorMessage={ImportProjectStrings.projectNameExistDoYouWantToReplace}
          handleReplace={handleReplace}
          replaceBtnLabel={ImportProjectStrings.replaceProject}
        />
      </CustomModal>
    </>
  );
};

export default EditProjectNamePopup;
