import React, { useMemo } from "react";
import styled from "styled-components";

import CustomTooltip from "@common/styles/components/tooltip/CustomTooltip";

const StyledButton = styled.button`
  /* height: 32px;
  width: 32px; */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const IconButton = ({ tooltip, placement, children, onClick, id, className, disabled }) => {
  const handleClick = () => {
    if (onClick) onClick({ target: { id } });
  };
  const tooltipMsg = useMemo(() => disabled ? "" : tooltip, [disabled, tooltip]);
  return (
    <CustomTooltip text={tooltipMsg} placement={placement}>
      <StyledButton data-tip={tooltipMsg} onClick={handleClick} id={id} className={className} disabled={disabled}>
        {children}
      </StyledButton>
    </CustomTooltip>
  );
};

export default IconButton;
