import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SELECTED_UNIT, UNITS } from "@constants/units.constant";

import useUFChemicalsHandler from "@hooks/useUFChemicalsHandler";
import useUnitConversion from "@hooks/useUnitConversion";

import { convertUptoDigits } from "@utils/appUtils";

import CustomCard from "@components/CustomCard";
import InputRanges from "@components/InputRanges";

import CustomLabel from "@common/styles/components/headings/CustomLabel";
import CustomInput from "@common/styles/components/inputs/CustomInput";
import { handleTabAndEnter } from "@common/styles/components/inputs/InputFocusUtililty";
import InputWithText from "@common/styles/components/inputs/InputWithText";
import CustomSelect from "@common/styles/components/selects/CustomSelect";

import ProjectErrorPopup from "@features/modals/ProjectErrorPopup";

import { CHEMICAL_TYPES, MAX_TEMP_CELCIUS, UF_CHEMICAL_SCREENS } from "../constants/UFConstants";
import UFChemicalFields from "../UFChemicalFields";
import { getFixedRange, provideRange } from "../UFConfiguration/UFHelper";
import { updateUFStoreData } from "../UFSlice";
import UFSystemDesignDiagram from "../UFSystemDesignDiagram";

import StyledMiniCip from "./StyledMiniCip";

const UFMiniCip = ({ useConfigObj }) => {
  const dispatch = useDispatch();

  const { unitConversionByName } = useUnitConversion();
  const { isValueInPh, getUFChemicalData, getChemicalRanges } = useUFChemicalsHandler();

  const [inputError, setInputError] = useState();
  const [isFocused, setIsFocused] = useState(null);

  const mCipData = useSelector(state => state.UFStore.data);
  const { ufInputRangeConfig, ufInputRangeConfigByWaterType } = useSelector(state => state.UFStore);
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const { waterType, chemicalType } = useSelector(state => state.UFStore.cipDropdownData);
  const { tempDesign } =
    useSelector(state => state.Feedsetupdetailsdatapanel?.streamData?.lstrequestsavefeedwater[0]?.streams[0]) || {};

  const selectedTempUnit = useMemo(() => unit.selectedUnits[SELECTED_UNIT.TEMPERATURE], [unit.selectedUnits]);

  const validations = {
    ...getChemicalRanges(isValueInPh ? "ph" : "mgL", UF_CHEMICAL_SCREENS.MINI_CIP),
    chemicalSoakingDuration_MiniCIP: provideRange(
      "mCIP Soak",
      getFixedRange(5, 60),
      ufInputRangeConfigByWaterType,
      ufInputRangeConfig,
    ),
    bWStepInMiniCIP: getFixedRange(1, 10),
    recycleTemperature_MiniCIP: {
      minValue: +unitConversionByName(tempDesign, selectedTempUnit, UNITS.celsius),
      maxValue: +unitConversionByName(MAX_TEMP_CELCIUS, selectedTempUnit, UNITS.celsius),
    },
    cIPRinseSoakCycle_MiniCIP: getFixedRange(1, 10),
    rinseBWCycle_MiniCIP: getFixedRange(1, 10),
    recycleDuration_MiniCIP: provideRange(
      "mCIP Recycle",
      getFixedRange(5, 60),
      ufInputRangeConfigByWaterType,
      ufInputRangeConfig,
    ),
    heatingStepDuration_MiniCIP: provideRange(
      "CIP Heating Step",
      getFixedRange(1, 360, 60),
      ufInputRangeConfigByWaterType,
      ufInputRangeConfig,
    ),
  };

  const updateStoreData = newData => dispatch(updateUFStoreData(newData));

  const closeErrorMessag = () => setInputError();
  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      closeErrorMessag();
      handleTabAndEnter(event);
    }
  };

  const checkError = id => !(validations[id]?.minValue <= mCipData[id] && validations[id]?.maxValue >= mCipData[id]);
  const handleFocus = id => setIsFocused(id);
  const setDesignTemp = () => updateStoreData({ recycleTemperature_MiniCIP: tempDesign || 0 });

  const handleBlur = (e, label) => {
    const { name, value } = e.target;
    const { minValue, maxValue } = validations[name];
    if (value < minValue || value > maxValue) {
      const errorMsg = `The ${label} value entered is outside the allowed range 
                        (${convertUptoDigits(minValue)} to ${convertUptoDigits(maxValue)}). Please revise your input.`;
      setInputError(errorMsg);
      setTimeout(() => e.target.focus(), 0);
    } else {
      updateStoreData({ data: { [name]: convertUptoDigits(value) }, calcEngineDataRefreshCount: 1 });
      setIsFocused(null);
      setInputError();
    }
  };

  useEffect(() => {
    setDesignTemp();
  }, [tempDesign]);

  const handleSelect = e => {
    const { value, name, id } = e.target;
    const chemicalValue = getUFChemicalData(id, value, "CIP");
    const eventData = { target: { id, value: chemicalValue, name: `${id}Value_MiniCIP` } };
    handleInputChange(eventData);
    dispatch(
      updateUFStoreData({ data: { [name]: value.toString() }, calcEngineDataRefreshCount: 1, isUfDataUpdated: true }),
    );
  };

  const handleEnableandDisable = e => {
    const { name, checked, id } = e.target;
    const transferData = {
      target: {
        name: `${id}ChemId_MiniCIP`,
        value: checked ? chemicalType[id][0].id.toString() : 0,
        id: id,
      },
    };
    handleSelect(transferData);
    updateStoreData({ data: { [name]: checked }, calcEngineDataRefreshCount: 1 });
  };

  const handleInputChange = e => {
    let { name, value } = e.target;
    value = value === "" ? "" : value;
    if (!isNaN(value)) {
      updateStoreData({ data: { [name]: value }, isUfDataUpdated: true });
    }
  };

  const getValue = field => (isFocused === field ? mCipData[field] : convertUptoDigits(mCipData[field]));

  return (
    <>
      <StyledMiniCip>
        <UFSystemDesignDiagram useConfigObj={useConfigObj} />
        <div className='cip-temp-water-cycle'>
          <CustomCard
            className='cip-temp-card'
            header='mini-CIP Temperature'
            tooltipLabel='miniCIPs can be done with heated water to improve cleaning efficiency'
          >
            <div className='temp-cip'>
              <CustomLabel label='Recycle Temperature' />
              <InputWithText
                type='number'
                disabled
                inputText={unit.selectedUnits[2]}
                placeholder='0.00'
                name='recycleTemperature_MiniCIP'
                onChange={handleInputChange}
                value={unitConversionByName(tempDesign, selectedTempUnit, UNITS.celsius)}
                tabIndex={1}
              />
            </div>
          </CustomCard>
          <CustomCard
            className='cycles-card'
            header='Cycles'
            tooltipLabel='Provide number of initial BW cycles, chemical rinse/soak cycles, and final rinse BW cycles.'
          >
            <div className='cycle-input-wrapper'>
              <div className='initial-bw-cycle'>
                <CustomLabel label='BW Cycles within a mini-CIP' />
                <CustomInput
                  type='number'
                  name='bWStepInMiniCIP'
                  isError={checkError("bWStepInMiniCIP")}
                  placeholder='0'
                  value={getValue("bWStepInMiniCIP")}
                  onChange={handleInputChange}
                  onKeyDown={evt =>
                    ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                  }
                  onWheel={e => e.target.blur()}
                  isFocused={isFocused === "bWStepInMiniCIP"}
                  onBlur={e => handleBlur(e, "BW Cycles within a mini-CIP")}
                  onFocus={() => handleFocus("bWStepInMiniCIP")}
                  tabIndex={2}
                />
                <InputRanges ranges={validations.bWStepInMiniCIP} />
              </div>
              <div className='rinse-bw-cycle'>
                <CustomLabel label='Rinse BW Cycles within mini-CIP' />
                <CustomInput
                  type='number'
                  name='rinseBWCycle_MiniCIP'
                  value={getValue("rinseBWCycle_MiniCIP")}
                  onChange={handleInputChange}
                  onKeyDown={evt =>
                    ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                  }
                  onWheel={e => e.target.blur()}
                  isError={checkError("rinseBWCycle_MiniCIP")}
                  placeholder='0'
                  isFocused={isFocused === "rinseBWCycle_MiniCIP"}
                  onBlur={e => handleBlur(e, "Rinse BW Cycles within mini-CIP")}
                  onFocus={() => handleFocus("rinseBWCycle_MiniCIP")}
                  tabIndex={3}
                />
                <InputRanges ranges={validations.rinseBWCycle_MiniCIP} />
              </div>
            </div>
          </CustomCard>
          <CustomCard
            className='cip-water-card'
            header='Water Source'
            tooltipLabel='Select source of water for cleaning protocols, RO permeate available if RO in design.'
          >
            <div>
              <CustomLabel label='mini-CIP Water Source' />
              <CustomSelect
                onChange={handleSelect}
                name='uFMiniCIPWaterTypeID'
                value={mCipData.uFMiniCIPWaterTypeID}
                id='waterType'
              >
                {waterType.map(item => (
                  <option key={item.uFCIPWaterTypeID} value={item.uFCIPWaterTypeID}>
                    {item.cIPWaterTypeName}
                  </option>
                ))}
              </CustomSelect>
            </div>
          </CustomCard>
        </div>
        <div className='acid-alkaline-surfactant'>
          <CustomCard
            className='acid-cip-card'
            header='Acid mini-CIP'
            tooltipLabel='Select mineral acid and organic acid chemical reagents and target doses or pH'
          >
            <UFChemicalFields
              type={CHEMICAL_TYPES.mineral}
              chemicalRanges={validations}
              screen={UF_CHEMICAL_SCREENS.MINI_CIP}
              tabIndex={4}
            />
            <UFChemicalFields
              type={CHEMICAL_TYPES.organic}
              chemicalRanges={validations}
              screen={UF_CHEMICAL_SCREENS.MINI_CIP}
              tabIndex={5}
            />
          </CustomCard>

          <CustomCard
            className='alkaline-cip-card'
            header='Alkali/Oxidant mini-CIP'
            tooltipLabel='Select alkali and oxidant chemical reagents and target doses or pH.'
          >
            <UFChemicalFields
              type={CHEMICAL_TYPES.alkali}
              chemicalRanges={validations}
              screen={UF_CHEMICAL_SCREENS.MINI_CIP}
              tabIndex={6}
            />
            <UFChemicalFields
              type={CHEMICAL_TYPES.oxidant}
              chemicalRanges={validations}
              screen={UF_CHEMICAL_SCREENS.MINI_CIP}
              tabIndex={7}
            />
            <div className='CIP-scaling'>
              <CustomLabel label='CIP Scaling Potential' />
              <InputWithText
                type='number'
                disabled
                name='lSI_MiniCIP'
                inputText='LSI'
                isFocused={isFocused === 9}
                onBlur={e => handleBlur(e, "CIP Scaling Potential")}
                onFocus={() => handleFocus(9)}
                onChange={handleInputChange}
                value={convertUptoDigits(mCipData.lSI_MiniCIP)}
                tabIndex={8}
              />
            </div>
          </CustomCard>
          <CustomCard
            className='surfactant-card'
            header='Surfactant mini-CIP'
            tooltipLabel='Select surfactant chemical reagent and target doses or pH.'
          >
            <UFChemicalFields
              type={CHEMICAL_TYPES.oxidant2}
              chemicalRanges={validations}
              screen={UF_CHEMICAL_SCREENS.MINI_CIP}
              disabled
              tabIndex={9}
            />
          </CustomCard>
        </div>

        <div className='duration'>
          <CustomCard
            className='duration-card'
            header='Duration'
            tooltipLabel='Duration of chemical treatment steps. Initial and rinse BW steps follow normal BW cycle durations.'
          >
            <div className='duration-input-wrapper'>
              <div>
                <CustomLabel label='Heating Step' />
                <InputWithText
                  type='number'
                  disabled
                  name='heatingStepDuration_MiniCIP'
                  inputText='min'
                  placeholder='0.00'
                  value={mCipData.heatingStepDuration_MiniCIP}
                  tabIndex={10}
                />
              </div>
              <div>
                <CustomLabel label='Recycle' />
                <InputWithText
                  type='number'
                  name='recycleDuration_MiniCIP'
                  isError={checkError("recycleDuration_MiniCIP")}
                  inputText='min'
                  placeholder='0.00'
                  defaultValue='30'
                  isFocused={isFocused === "recycleDuration_MiniCIP"}
                  value={getValue("recycleDuration_MiniCIP")}
                  onChange={handleInputChange}
                  onBlur={e => handleBlur(e, "Recycle")}
                  onFocus={() => handleFocus("recycleDuration_MiniCIP")}
                  tabIndex={11}
                />
                <InputRanges ranges={validations.recycleDuration_MiniCIP} />
              </div>
              <div>
                <CustomLabel label='Chemical Soaking' />
                <InputWithText
                  type='number'
                  name='chemicalSoakingDuration_MiniCIP'
                  isError={checkError("chemicalSoakingDuration_MiniCIP")}
                  inputText='min'
                  placeholder='0.00'
                  defaultValue='90'
                  value={getValue("chemicalSoakingDuration_MiniCIP")}
                  onChange={handleInputChange}
                  isFocused={isFocused === "chemicalSoakingDuration_MiniCIP"}
                  onBlur={e => handleBlur(e, "Chemical Soaking")}
                  onFocus={() => handleFocus("chemicalSoakingDuration_MiniCIP")}
                  tabIndex={12}
                />
                <InputRanges ranges={validations.chemicalSoakingDuration_MiniCIP} />
              </div>
              <div>
                <CustomLabel label='Rinse/Soak Cycles' />
                <CustomInput
                  type='number'
                  name='cIPRinseSoakCycle_MiniCIP'
                  value={getValue("cIPRinseSoakCycle_MiniCIP")}
                  onChange={handleInputChange}
                  onKeyDown={evt =>
                    ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                  }
                  onWheel={e => e.target.blur()}
                  isError={checkError("cIPRinseSoakCycle_MiniCIP")}
                  placeholder='0'
                  defaultValue='1'
                  isFocused={isFocused === "cIPRinseSoakCycle_MiniCIP"}
                  onBlur={e => handleBlur(e, "Rinse/Soak Cycles")}
                  onFocus={() => handleFocus("cIPRinseSoakCycle_MiniCIP")}
                  tabIndex={13}
                />
                <InputRanges ranges={validations.cIPRinseSoakCycle_MiniCIP} />
              </div>
            </div>
          </CustomCard>
        </div>
        {inputError && <ProjectErrorPopup show close={closeErrorMessag} message={inputError} />}
      </StyledMiniCip>
    </>
  );
};

export default UFMiniCip;
