import { useMemo } from "react";
import { useSelector } from "react-redux";

import { isIXTechnology } from "@features/feedwater/ix/IXDUtilityFunction";

const useProjectDetails = () => {
  const { data, projectData, projectConfig } = useSelector(state => state.projectInfo);
  const { countries } = useSelector(state => state.globalAppData);

  const caseConfig = projectConfig.caseConfig;

  const { projectInfoVM, appInfoVM } = projectData;

  // to return current active case detail, case name and case note can be updated via case management modal and project info modal
  const activeCase = useMemo(() => {
    const { caseID, caseName, caseNote } =
      caseConfig?.caseList?.find(item => item.displayOrder === 1) || projectInfoVM || {};
    return { activeCaseId: caseID, activeCaseName: caseName, activeCaseNote: caseNote };
  }, [caseConfig, projectInfoVM]);

  const PROJECT_TECH = useMemo(() => (data ? data.Tchnology : ""), [data]);

  const IS_IX_PROJECT = useMemo(() => isIXTechnology(data.Tchnology), [data]);

  const countryName = useMemo(
    () => countries?.find(country => country.countryID == projectInfoVM?.countryID)?.countryName,
    [projectInfoVM?.countryID],
  );

  const {
    projectNumber,
    projectName,
    createdDate,
    updatedDate,
    designer,
    designerCompany,
    customer,
    marketsegmentName,
    projectNotes,
    projectID,
  } = projectInfoVM || {};

  const projectDetails = {
    projectID,
    projectNumber,
    projectName,
    createdDate,
    updatedDate,
    designer,
    designerCompany,
    customer,
    countryName,
    marketsegmentName,
    projectNotes,
    appVersion: appInfoVM?.appVersion,
    ...activeCase,
  };

  return { PROJECT_TECH, IS_IX_PROJECT, projectDetails };
};

export default useProjectDetails;
