import { reportStrings } from "@constants/report.strings";

import { typographyVariants, WPTypogrpahy } from "@components/WPTypography";

import "./WPReportTableNotes.scss";

export const WPReportTableNotes = ({ type, list }) => (
  <div className='table-notes'>
    <WPTypogrpahy
      variant={typographyVariants.xsText}
      text={type === "footnotes" ? reportStrings.footNotes : reportStrings.remarks}
      className='notes'
      fontWeight={700}
    />
    {list?.map((note, index) => (
      <WPTypogrpahy key={index} variant={typographyVariants.xsText}>
        {type === "footnotes" ? (
          <>
            <sup className='super-script'>{String.fromCharCode(97 + index)}</sup> {note}
          </>
        ) : (
          `${index + 1} ${note}`
        )}
      </WPTypogrpahy>
    ))}
  </div>
);

WPReportTableNotes.defaultProps = {
  type: "footnotes",
};
