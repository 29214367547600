import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

import { TOAST_DEFAULT_DURATION } from "@constants/global.constants";

import IconButton from "@components/IconButton";

import CloseCircleIcon from "@common/icons/CloseCircleIcon";
import CloseIcon from "@common/icons/CloseIcon";
import SuccessIcon from "@common/icons/SuccessIcon";
import WarningIcon from "@common/icons/WarningIcon";
import StyledModal from "@common/styles/components/modals/CustomModal";

import { TOAST_NOTIFICATION_TYPES, useToastNotification } from "./WPToastNotificationProvider";

const TOAST_ICONS = {
    [TOAST_NOTIFICATION_TYPES.SUCCESS]: <SuccessIcon />,
    [TOAST_NOTIFICATION_TYPES.ERROR]: <CloseCircleIcon />,
    [TOAST_NOTIFICATION_TYPES.WARNING]: <WarningIcon />,
};

const StyleDiv = styled.div`
    display: flex;
    .icon{  margin-right: 10px;  }
    .message { flex: 1; }
`;

const WPToastNotification = () => {
    const { toastData, removeToastNotification } = useToastNotification();

    useEffect(() => {
        if (toastData) {
            const duration = toastData.duration || TOAST_DEFAULT_DURATION;
            const timer = setTimeout(() => removeToastNotification(), duration);
            return () => clearTimeout(timer);
        }
    }, [toastData, removeToastNotification]);

    if (!toastData) return null;

    return (
        <StyledModal show onHide={removeToastNotification} centered maxWidth='416px' className="toast-container">
            <Modal.Body>
                <StyleDiv>
                    <span className="icon">{TOAST_ICONS[toastData.type]}</span>
                    <span className="message">{toastData.message}</span>
                    <IconButton onClick={removeToastNotification} tooltip="Close" >
                        <CloseIcon />
                    </IconButton>
                </StyleDiv>
            </Modal.Body>

        </StyledModal>
    );
};

export default WPToastNotification;