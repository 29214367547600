const API_URLS = {
  ufDetails: "uf/api/v1/UFDetails",
  ufCalcReport: "uf/api/v1/UFCalcReport",
  ixdUsers: "userprofile/api/v1/IXDUsers",
  feedWater: "masterdata/api/v1/FeedWater",
  contriesList: "masterdata/api/v1/Country",
  importProject: "userprofile/api/v1/Import",
  exportProject: "userprofile/api/v1/Export",
  projectState: "masterdata/api/v1/ProjectState",
  projectStatus: "masterdata/api/v1/EntityCheck",
  marketSegment: "masterdata/api/v1/MarketSegment",
  waterLibrary: "userprofile/api/v1/WaterLibrary",
  duplicateProject: "masterdata/api/v1/DuplicateProject",
  unreadNotifications: "masterdata/api/v1/UnreadNotification",
  chemicalLibrary: "masterdata/api/v1/OperatingCostChemical",
};

export default API_URLS;
