import { useMemo } from "react";
import { useSelector } from "react-redux";

import { DEFAULT_CURRENCY_SYMBOL, UNIT_PRECISION_MAPPING } from "@constants/units.constant";

import useUnitConversion from "@hooks/useUnitConversion";

import { convertUptoDigits, isNullOrUndefined } from "@utils/appUtils";

// unit precision is 2 for all units
export const useReportUtils = () => {
  const { convertFromMetric, activeUnits, convertCurrencyPerUnitFromMetric } = useUnitConversion();

  const { data: calcEngineData } = useSelector(state => state.report.ufCalcReportApiResp) || {};

  const ufReportRespData = useMemo(() => calcEngineData, [calcEngineData]);

  const { currencyConfig } = useSelector(state => state.projectInfo.projectConfig);

  const activeCurrencySymbol = useMemo(
    () => currencyConfig.selectedCurrency.currencyUnit || DEFAULT_CURRENCY_SYMBOL,
    [currencyConfig],
  );

  const formatLabelValue = (label, value, unit, precision) => {
    if (unit) {
      value = formatConvertValue(value, unit, precision);
    }
    return `${label} = ${value}`;
  };

  const formatValue = (value, precision) => convertUptoDigits(value, precision);

  const formatConvertValue = (value, unit, precision) => {
    if (isNullOrUndefined(value) || value === "") return "";
    // activeUnits[unit] returns the unit as string ex: °C, kW etc
    const unitVal = activeUnits[unit] || unit;

    // certain fields needs to override the default precision of obtained by unit , in that case precision passed in argument will be used
    const precisionValue = precision ?? UNIT_PRECISION_MAPPING[unitVal];

    if (typeof unit === "number") {
      return convertFromMetric(value, unit, precisionValue);
    }

    return formatValue(value, precisionValue);
  };

  // here the unit is always a denominator unit
  // $/kg will be converted to $/lb when unit is of type weight
  const formatConvertCostValuePerUnit = (value, unit, precision) => {
    if (isNullOrUndefined(value)) {
      return "";
    }
    const unitVal = activeUnits[unit] || unit;
    const precisionValue = precision ?? UNIT_PRECISION_MAPPING[unitVal];

    return convertCurrencyPerUnitFromMetric(value, unit, precisionValue);
  };

  const formatKeyValue = (key, value) => `${key}: ${value}`;

  const formatUnitLabel = (unitValue, unitSubText = "") => {
    if (!unitValue) return "";
    const unitLabel = activeUnits[unitValue] || unitValue;
    return `(${unitLabel + unitSubText})`;
  };

  const formatCostUnitLabel = (unitValue, unitSubText = "") => {
    const unitLabel = activeUnits[unitValue] || unitValue;
    const formattedUnitValue = ` ${activeCurrencySymbol}/${unitLabel}`;
    return formatUnitLabel(formattedUnitValue, unitSubText);
  };

  const formatConvertValueWithUnit = (value, unit, precision) => {
    const unitLabel = activeUnits[unit] || unit;
    if (isNullOrUndefined(value)) {
      return "";
    }
    return formatConvertValue(value, unit, precision) + " " + unitLabel;
  };

  const formatChemicalNameConc = (name, concentration) => `${name} (${concentration * 100}%)`;

  // indentLevel is used to add indentation (white spaces nbsp;) to the label based on the level
  const formatLabel = (label, indentLevel = 0) => `${"\u00A0".repeat(indentLevel * 4)}${label}`;
  return {
    ufReportRespData,
    formatLabelValue,
    formatKeyValue,
    formatUnitLabel,
    formatValue,
    formatConvertValue,
    formatLabel,
    formatChemicalNameConc,
    formatConvertValueWithUnit,
    formatCostUnitLabel,
    formatConvertCostValuePerUnit,
  };
};
