import { reportStrings } from "@constants/report.strings";

// The order of the keys in the object will be the order of the sections in the report
export const ufSectionsLabels = {
  sytem_diagram: reportStrings.systemDiagram,
  uf_system_overview: reportStrings.ufSystemOverview,
  uf_operating_conditions: reportStrings.ufOperatingConditions,
  uf_design_quality: reportStrings.ufDesignWarnings,
  uf_water_quality: reportStrings.UfWaterQuality,
  uf_configuration_options: reportStrings.ufConfigurationOptions,
  flow_details: reportStrings.ufFlowDetails,
  uf_system_module: reportStrings.ufSystemSizeModuleDetails,
  uf_Power_ratings: reportStrings.ufPressureRatings,
  pump_electrical: reportStrings.ufPumpHydraulicElectricalCost,
  uf_Storage_tanks: reportStrings.ufStorageTanks,
};

export const sectionLabels = {
  ufSectionData: reportStrings.ultraFiltration,
};

// key is to be same as redux state key
export const reportSectionLabels = {
  ufSectionData: {
    name: reportStrings.ultraFiltration,
    subLabels: ufSectionsLabels,
  },
};
