import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfOperatingConditionsTableColumns as columns } from "./WPUfOperatingConditionsTable.constants";

export const WPUfOperatingConditionsTable = () => {
  const { uf_operating_conditions: rows } = useSelector(state => state.report.ufReportTableData);

  return <WPTable sectionHeader={reportStrings.ufOperatingConditions} columns={columns} rows={rows} />;
};
