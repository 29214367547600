import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SELECTED_UNIT } from "@constants/units.constant";

import { capitalize, groupBy } from "@utils/appUtils";
import DupontLogger from "@utils/DupontLogger";

import {
  CHEMICAL_RANGES_KEY_MAPPING,
  CHEMICAL_TYPES,
  COMPANY_CODE,
  NO_WATER_CHEMISTRY_TDS_DELTA,
  UF_CHEMICAL_FIELDS,
  UF_CHEMICAL_SCREENS,
  UF_SPECIAL_FEATURE,
  WATER_TYPE_IDS,
} from "@features/feedwater/uf/constants/UFConstants";
import { updateUFStoreData } from "@features/feedwater/uf/UFSlice";
import { isIngeSelected } from "@features/feedwater/uf/ufUtils";

const ChemicalAdjustmentMapping = {
  [CHEMICAL_TYPES.mineral]: "phDown",
  [CHEMICAL_TYPES.oxidant]: "oxidant",
};

const useUFChemicalsHandler = () => {
  const Logger = DupontLogger("useUFChemicalsHandler");
  const dispatch = useDispatch();

  const { UFStore, Feedsetupdetailsdatapanel, projectInfo } = useSelector(state => state);
  const { unitConfig } = projectInfo?.projectConfig || {};

  const { waterTypeID, waterSubTypeID, totalDissolvedSolutes } =
    Feedsetupdetailsdatapanel.streamData?.lstrequestsavefeedwater[0]?.streams[0] || {};
  const {
    data: UFData,
    cipDropdownData,
    ufDoseGuidline,
    ufPHGuidline,
    ufChemicalAdjustment,
    ufInputRangeConfig,
  } = UFStore;

  const { chemicalType } = cipDropdownData || {};

  const SCREENS_NAME = useMemo(() => Object.values(UF_CHEMICAL_SCREENS), [UF_CHEMICAL_SCREENS]);
  const isValueInPh = useMemo(() => totalDissolvedSolutes > NO_WATER_CHEMISTRY_TDS_DELTA, [totalDissolvedSolutes]);
  const isWasteWater = useMemo(() => waterTypeID?.toString() === WATER_TYPE_IDS.WASTE_WATER.toString(), [waterTypeID]);

  const { selectedCompany, isInge } = useMemo(
    () => ({
      selectedCompany: COMPANY_CODE[UFData.pUFTechnologyID],
      isInge: isIngeSelected(UFData.pUFTechnologyID),
    }),
    [UFData.pUFTechnologyID],
  );

  const selectedTempUnit = useMemo(
    () => unitConfig?.selectedUnits[SELECTED_UNIT.TEMPERATURE],
    [unitConfig?.selectedUnits],
  );

  const ACTIVE_CHEMICAL_SCREENS = useMemo(() => {
    if (+UFData.ufSpecialFeatureID === +UF_SPECIAL_FEATURE.isCebOnly) {
      return SCREENS_NAME.filter(screen => screen !== UF_CHEMICAL_SCREENS.MINI_CIP);
    } else if (+UFData.ufSpecialFeatureID === +UF_SPECIAL_FEATURE.isMiniCipOnly) {
      return SCREENS_NAME.filter(screen => screen !== UF_CHEMICAL_SCREENS.CEB);
    }
    return SCREENS_NAME;
  }, [UFData.ufSpecialFeatureID]);

  const ufInputRangeConfigGroupedByCompany = useMemo(
    () => groupBy(ufInputRangeConfig, "companyName"),
    [ufInputRangeConfig],
  );

  const getChemicalById = (type, id) => chemicalType[type]?.find(chem => `${chem.id}` === `${id}`);

  const getTargetPh = (isMineral, screen) => {
    const guidelineName = `${screen} ${isMineral ? "Acid" : CHEMICAL_TYPES.alkali}`.toLowerCase();
    const phData = ufPHGuidline.find(
      item => item.waterSubTypeId === waterSubTypeID && item.guidelineName.toLowerCase() === guidelineName,
    );
    return phData?.targetValuePH ?? 0;
  };

  const getConcentration = (type, id, screen) => {
    let value = 0;
    const isMineral = type === CHEMICAL_TYPES.mineral;
    screen = screen === UF_CHEMICAL_SCREENS.MINI_CIP ? UF_CHEMICAL_SCREENS.CIP : screen;
    if (isValueInPh) {
      value = getTargetPh(isMineral, screen);
    } else {
      value = getChemicalDoseValue(type, id, screen);
    }
    return value;
  };

  const getChemicalDoseValue = (type, id, screen) => {
    const chemData = getChemicalById(type, id);
    screen = screen === UF_CHEMICAL_SCREENS.BW ? "Backwash" : screen;
    const chemicalDoseGuideline =
      chemData &&
      ufDoseGuidline.find(item => {
        if (type === CHEMICAL_TYPES.disOxidant) {
          return item.guidelineName === "Dis. CEB NaOCl Dose";
        } else {
          return (
            item.waterSubTypeId == waterSubTypeID &&
            item.symbol == chemData.symbol &&
            item.guidelineName.includes(screen)
          );
        }
      });
    if (chemicalDoseGuideline) {
      return chemicalDoseGuideline.targetDose;
    }
    if (type === CHEMICAL_TYPES.disOxidant) {
      return "100";
    }
    return 0;
  };

  const getUFChemicalData = (type, id, screen) => {
    let value = 0;
    if (id !== 0) {
      if (type === CHEMICAL_TYPES.mineral || type === CHEMICAL_TYPES.alkali) {
        value = getConcentration(type, id, screen);
      } else {
        value = getChemicalDoseValue(type, id, screen);
      }
    }
    return value;
  };

  const defaultChemicals = useMemo(() => {
    let chemicalIDs = {};
    if (chemicalType) {
      const findChemicalId = chemicals => (chemicals && chemicals[0]?.id) || 0;
      chemicalIDs = Object.values(CHEMICAL_TYPES).reduce(
        (ids, type) => ({ ...ids, [type]: findChemicalId(chemicalType[type]) }),
        {},
      );
    }
    return chemicalIDs;
  }, [chemicalType]);

  /**
   * Generates an object containing keys for chemical properties based on the given type and screen.
   *
   * @param {string} type - The type of chemical.
   * @param {string} screen - The screen identifier.
   * @returns {Object} An object with keys for enabled indicator, chemical ID, value, and value in pH indicator.
   *
   * Example:
   * getChemicalKeys('mineral', 'CIP') returns:
   * {
   *   enabledKey: 'mineralEnabled_Ind_CIP',
   *   chemIdKey: 'mineralChemId_CIP',
   *   valueKey: 'mineralValue_CIP',
   *   valueInPhKey: 'mineralValueInPh_Ind_CIP'
   * }
   */
  const getChemicalKeys = (type, screen) => ({
    enabledKey: `${type}Enabled_Ind_${screen}`,
    chemIdKey: `${type}ChemId_${screen}`,
    valueKey: `${type}Value_${screen}`,
    valueInPhKey: `${type}ValueInPh_Ind_${screen}`,
  });

  const getChemicalValue = (type, screen) => {
    const { enabledKey, chemIdKey } = getChemicalKeys(type, screen);
    return UFData[enabledKey] ? getUFChemicalData(type, UFData[chemIdKey], screen) : 0;
  };

  /**
   * Retrieves the chemical values and their pH indicators for a given screen.
   *
   * @param {string} screen - The screen identifier.
   * @returns {Object} An object with keys for chemical values and their pH indicators.
   *
   * Example:
   * getChemicalValuesInPH('CIP') returns:
   * {
   *   mineralValueInPh_Ind_CIP: isValueInPh,
   *   mineralValue_CIP: getChemicalValue('mineral'),
   *   alkaliValueInPh_Ind_CIP: isValueInPh,
   *   alkaliValue_CIP: getChemicalValue('alkali')
   * }
   */
  const getChemicalValuesInPH = screen => {
    const chemTypes = [CHEMICAL_TYPES.mineral, CHEMICAL_TYPES.alkali];

    return chemTypes.reduce((acc, type) => {
      const { valueKey, valueInPhKey } = getChemicalKeys(type, screen);
      return {
        ...acc,
        [valueInPhKey]: isValueInPh,
        [valueKey]: getChemicalValue(type, screen),
      };
    }, {});
  };

  const updateUFChemicalValuesInPH = () => {
    const ACTIVE_SCREEN_WITH_PH = ACTIVE_CHEMICAL_SCREENS.filter(screen => screen != UF_CHEMICAL_SCREENS.BW);
    const data = ACTIVE_SCREEN_WITH_PH.reduce((acc, screen) => ({ ...acc, ...getChemicalValuesInPH(screen) }), {});

    dispatch(updateUFStoreData({ data }));
  };

  const getChemicalDetails = (chemicalType, screen, isEnabled) => {
    const chemID = defaultChemicals[chemicalType];
    let value = 0;

    if (isEnabled) {
      const screenType = UF_CHEMICAL_SCREENS.MINI_CIP === screen ? UF_CHEMICAL_SCREENS.CIP : screen; // For mini-cip also, need to get values same as CIP
      value = getUFChemicalData(chemicalType, chemID, screenType);
      isEnabled = isEnabled && value > 0;
    }
    const { enabledKey, chemIdKey, valueKey } = getChemicalKeys(chemicalType, screen);
    return {
      [enabledKey]: isEnabled,
      [chemIdKey]: isEnabled ? chemID : "0",
      [valueKey]: value,
    };
  };

  const getDefaultValue = (screen, isEnabled = true) => ({
    ...getDefaultValueAcid(screen, isEnabled),
    ...getDefaultValueAlkaliOxidant(screen, isEnabled),
  });

  const getDefaultValueAcid = (screen, isEnabled = true) => ({
    ...getChemicalDetails(CHEMICAL_TYPES.organic, screen, false),
    ...getChemicalDetails(CHEMICAL_TYPES.mineral, screen, isEnabled),
  });

  const getDefaultValueAlkaliOxidant = (screen, isEnabled = true) => ({
    ...getChemicalDetails(CHEMICAL_TYPES.alkali, screen, isEnabled),
    ...getChemicalDetails(CHEMICAL_TYPES.oxidant, screen, isEnabled),
  });

  const getSurfactantDefaultValue = isEnabled => {
    const isSurfactantEnabled = isWasteWater && isInge && isEnabled;
    const chemID = isSurfactantEnabled ? defaultChemicals[CHEMICAL_TYPES.oxidant2] : "0";
    return {
      oxidant2Enabled_Ind_CIP: isSurfactantEnabled,
      oxidant2ChemId_CIP: chemID,
      oxidant2Value_CIP: isSurfactantEnabled
        ? getUFChemicalData(CHEMICAL_TYPES.oxidant2, chemID, UF_CHEMICAL_SCREENS.CIP)
        : 0,
    };
  };

  const setSufactantDefaultValue = isEnabled => {
    const data = getSurfactantDefaultValue(isEnabled);
    dispatch(updateUFStoreData({ data }));
  };

  /**
   * Sets the default values for UF chemicals based on the current UF Data.
   * If waterSubTypeID is present, it extracts the relevant chemical data from UFData,
   * calculates the default values for various chemical screens (CIP, MINI_CIP, CEB),
   * and updates the UF store with the combined data.
   * Returns the combined data object.
   */
  const setUFChemicalsDefaultValues = () => {
    const isCEBOnly = +UFData.ufSpecialFeatureID === +UF_SPECIAL_FEATURE.isCebOnly;
    let data = {};

    if (waterSubTypeID) {
      const { cIP, miniCIP, acidCEB, alkaliOxidantCEB, disinfectionCEB } = UFData;
      const cipData = getDefaultValue(UF_CHEMICAL_SCREENS.CIP, cIP !== 0);
      const minCipData = getDefaultValue(UF_CHEMICAL_SCREENS.MINI_CIP, miniCIP !== 0 && !isCEBOnly);
      const cebAcid = getDefaultValueAcid(UF_CHEMICAL_SCREENS.CEB, acidCEB !== 0);
      const cebAlkali = getDefaultValueAlkaliOxidant(UF_CHEMICAL_SCREENS.CEB, alkaliOxidantCEB !== 0);
      const cebDisinfection = getDisinfectCEBDefaultDoseValue(disinfectionCEB !== 0);
      const cipSurfactant = getSurfactantDefaultValue(isWasteWater && isInge);
      const bwData = getChemicalDetails(CHEMICAL_TYPES.oxidant, UF_CHEMICAL_SCREENS.BW, true);

      data = { ...cipData, ...minCipData, ...cebAcid, ...cebAlkali, ...cebDisinfection, ...cipSurfactant, ...bwData };
      dispatch(updateUFStoreData({ data }));
    }

    return data;
  };

  const getDisinfectCEBDefaultDoseValue = isEnabled =>
    getChemicalDetails("disOxidant", UF_CHEMICAL_SCREENS.CEB, isEnabled);

  const setDisingectCEBDefaultValues = isEnabled => {
    const data = getDisinfectCEBDefaultDoseValue(isEnabled);
    dispatch(updateUFStoreData({ data }));
  };

  // this method sets default chemical dose values for particular chemical type
  const setDefaultChemicalDoseValues = (screen, chemicalType, isEnabled) => {
    if (screen === UF_CHEMICAL_SCREENS.CEB) {
      if (chemicalType === CHEMICAL_TYPES.disOxidant) {
        setDisingectCEBDefaultValues(isEnabled);
      } else if (chemicalType === CHEMICAL_TYPES.organic || chemicalType === CHEMICAL_TYPES.mineral) {
        const data = getDefaultValueAcid(screen, isEnabled);
        dispatch(updateUFStoreData({ data }));
      } else if (chemicalType === CHEMICAL_TYPES.oxidant || chemicalType === CHEMICAL_TYPES.alkali) {
        const data = getDefaultValueAlkaliOxidant(screen, isEnabled);
        dispatch(updateUFStoreData({ data }));
      }
    } else if (screen === UF_CHEMICAL_SCREENS.MINI_CIP) {
      updateMiniCIPChemicals(isEnabled);
      setSufactantDefaultValue(false);
    } else if (screen === UF_CHEMICAL_SCREENS.CIP) {
      updateCIPChemicals(isEnabled);
      setSufactantDefaultValue(isEnabled);
    }
  };

  const updateCIPChemicals = isEnabled => {
    const data = getDefaultValue(UF_CHEMICAL_SCREENS.CIP, isEnabled);
    dispatch(updateUFStoreData({ data }));
  };

  const updateMiniCIPChemicals = isEnabled => {
    const data = getDefaultValue(UF_CHEMICAL_SCREENS.MINI_CIP, isEnabled);
    dispatch(updateUFStoreData({ data }));
  };

  const getCEBMiniCIPChemicalData = (miniCipValue, cebValue) => ({
    ...getDefaultValue(UF_CHEMICAL_SCREENS.MINI_CIP, miniCipValue),
    ...getDefaultValue(UF_CHEMICAL_SCREENS.CEB, cebValue),
  });

  /**
   * Updates the chemical data based on the changes in the feature.
   *
   * @param {number} feature - The new feature value.
   *
   * This function determines the appropriate chemical data to update based on the
   * current and previous feature values. It handles different scenarios where the
   * feature can be CEB only, MINI_CIP only, or both. Depending on the feature change,
   * it sets the chemical data accordingly and dispatches an action to update the store.
   *
   * Steps:
   * 1. Convert the feature parameter to a number and store it in featureNum.
   * 2. Retrieve the previous feature value from UFData and store it in prevFeatureNum.
   * 3. Destructure the constants isCebOnly, isMiniCipOnly, and isCebAndMiniCip from UF_SPECIAL_FEATURE.
   * 4. Initialize an empty object data to hold the chemical data.
   * 5. Check if the previous feature was both CEB and MINI_CIP:
   *    - If true, determine if the new feature is CEB only and set data accordingly.
   * 6. If the new feature is CEB only, set data using getCEBMiniCIPChemicalData with appropriate values.
   * 7. If the new feature is MINI_CIP only, set data using getCEBMiniCIPChemicalData with appropriate values.
   * 8. If none of the above conditions are met, set data based on the previous feature value.
   * 9. Dispatch an action to update the store with the new chemical data.
   */
  const updateChemicalOnFeatureChanges = feature => {
    const prevFeatureNum = UFData.ufSpecialFeatureID;
    const { isCebOnly, isMiniCipOnly, isCebAndMiniCip } = UF_SPECIAL_FEATURE;
    let data = {};

    if (prevFeatureNum === isCebAndMiniCip) {
      const isCEBOnlyFeature = feature === isCebOnly;
      data = isCEBOnlyFeature
        ? getDefaultValue(UF_CHEMICAL_SCREENS.MINI_CIP, false)
        : getDefaultValue(UF_CHEMICAL_SCREENS.CEB, false);
    } else if (feature === isCebOnly) {
      data = getCEBMiniCIPChemicalData(false, true);
    } else if (feature === isMiniCipOnly) {
      data = getCEBMiniCIPChemicalData(true, false);
    } else {
      data =
        prevFeatureNum === isCebOnly
          ? getDefaultValue(UF_CHEMICAL_SCREENS.MINI_CIP, true)
          : getDefaultValue(UF_CHEMICAL_SCREENS.CEB, true);
    }
    Logger.log("updateChemicalOnFeatureChanges: ", data);
    dispatch(updateUFStoreData({ data }));
  };

  const getChemicalRanges = (valueType, screen) => {
    const ranges = Object.values(CHEMICAL_TYPES).reduce((acc, chemType) => {
      const key = `${chemType}Value_${screen}`;
      valueType = chemType === CHEMICAL_TYPES.mineral || chemType === CHEMICAL_TYPES.alkali ? valueType : null;
      const value = getSingleChemicalRange(chemType, valueType, screen);
      return { ...acc, [key]: value };
    }, {});
    return ranges;
  };

  const getSingleChemicalRange = (key, valueType, screen) => {
    let labelKey = CHEMICAL_RANGES_KEY_MAPPING[key][screen];
    if (!labelKey) {
      return {};
    }
    if (valueType === "ph") {
      labelKey = labelKey.replace(" Concentration", "");
    }
    const fields = ufInputRangeConfigGroupedByCompany[selectedCompany.toLowerCase()];
    return fields.find(i => i.label === labelKey) || {};
  };

  const compareChemical = (source, target) =>
    source &&
    target &&
    source?.chemicalName === target.chemicalName &&
    source?.bulkConcentration !== target.bulkConcentration;

  const getDiffChemicalDiffConcentrationMsg = (targetChemical, type) => ({
    header: `${capitalize(type)} acid concentration different`,
    description: `Do you want to use ${targetChemical.bulkConcentration}% 
                    concentration for all ${targetChemical.chemicalName} selections?`,
  });

  /**
   * Validates the concentration of a chemical based on its type, ID, and screen type.
   *
   * @param {string} type - The type of the chemical (e.g., oxidant).
   * @param {number} chemId - The ID of the chemical to validate.
   * @param {string} screenType - The type of screen where the validation is being performed.
   *
   * @returns {{header,description} |null} - Returns a Message Object if there is a chemical with a different concentration,
   *                          otherwise returns null.
   *
   * The function performs the following steps:
   * 1. Retrieves the target chemical by its type and ID.
   * 2. Capitalizes the first letter of the chemical type.
   * 3. Defines a helper function `hasDifferentConcentration` to compare the target chemical with another chemical.
   * 4. Checks if the chemical type is 'oxidant' and the screen type is not 'UF_CHEM_ADJUSTMENT_SCREEN'.
   *    - If true, retrieves the source chemical ID from `ufChemicalAdjustment` data.
   *    - If the source chemical has a different concentration, returns a message indicating the difference.
   * 5. Iterates over a list of screens to check if there is any chemical with a different concentration.
   *    - If found, returns a message indicating the difference.
   * 6. If no differences are found, returns null.
   */
  const validateChemicalConcentration = (type, chemId, screenType) => {
    const targetChemical = getChemicalById(type, chemId);

    const hasDifferentConcentration = sourceChemId => {
      const sourceChemical = getChemicalById(type, sourceChemId);
      if (!sourceChemical) return false;
      return compareChemical(targetChemical, sourceChemical);
    };

    const ChemicalAdjustmentType = ChemicalAdjustmentMapping[type];
    const chemAdjustmentId = ufChemicalAdjustment?.data?.[ChemicalAdjustmentType]?.chemicalId;

    if (chemAdjustmentId) {
      if (hasDifferentConcentration(chemAdjustmentId)) {
        return getDiffChemicalDiffConcentrationMsg(targetChemical, type);
      }
    }
    const isChemWithDiffCon = ACTIVE_CHEMICAL_SCREENS.some(screen => {
      const sourceChemId = UFData[`${type}ChemId_${screen}`];
      return screenType !== screen && hasDifferentConcentration(sourceChemId);
    });

    if (isChemWithDiffCon) {
      return getDiffChemicalDiffConcentrationMsg(targetChemical, type);
    }

    return null;
  };

  const getSelectedChemicalByType = type => {
    const chemIds = SCREENS_NAME.reduce((acc, screen) => {
      const chemId = UFData[`${type}ChemId_${screen}`];
      if (chemId > 0 && !acc.includes(chemId)) {
        acc.push(chemId);
      }
      return acc;
    }, []);

    return chemIds.length ? chemIds.map(chemId => getChemicalById(type, chemId) || {}) : null;
  };

  const updateChemicalWithSameConentration = (type, selectedChemical) => {
    let chemAdjustment = JSON.parse(JSON.stringify(ufChemicalAdjustment));

    const data = SCREENS_NAME.reduce((acc, screen) => {
      const { enabledKey, chemIdKey } = getChemicalKeys(type, screen);
      if (UFData[enabledKey]) {
        acc = { ...acc, [chemIdKey]: selectedChemical };
      }
      return acc;
    }, {});

    if (type === CHEMICAL_TYPES.oxidant && chemAdjustment.data?.oxidant) {
      const { data } = chemAdjustment;
      data.oxidant.chemicalId = +selectedChemical;
      chemAdjustment = { ...chemAdjustment, data };
    }
    dispatch(updateUFStoreData({ data, ufChemicalAdjustment: chemAdjustment }));
  };

  const getConcentrationValidationMsg = diffConcentrationChemical => {
    let result = null;
    if (diffConcentrationChemical.size) {
      const items = Array.from(diffConcentrationChemical)
        .map(item => UF_CHEMICAL_FIELDS[item])
        .join(", ");
      let activeScreens = [...ACTIVE_CHEMICAL_SCREENS];
      activeScreens.pop();
      activeScreens = [...activeScreens, "Backwash", "Chemical Adjustment"];
      const ulElement = activeScreens.reduce((acc, item) => acc + `<li>${item}</li>`, "<ul>") + "</ul>";

      result = {
        header: `${items} having different concentration`,
        description: `Use same concentration in: ${ulElement}`,
      };
      return result;
    }
  };

  const validateChemicalsConcentrations = () => {
    const firstScreen = ACTIVE_CHEMICAL_SCREENS[0];
    const diffConcentrationChemical = new Set();

    Object.keys(CHEMICAL_TYPES).forEach(chemicalType => {
      const chemId = UFData[`${chemicalType}ChemId_${firstScreen}`];
      ACTIVE_CHEMICAL_SCREENS.forEach((_, count) => {
        if (validateChemicalConcentration(chemicalType, chemId, count + 1)) {
          diffConcentrationChemical.add(chemicalType);
        }
      });
    });

    return getConcentrationValidationMsg(diffConcentrationChemical);
  };

  return {
    isValueInPh,
    selectedTempUnit,
    getChemicalKeys,
    getChemicalRanges,
    getUFChemicalData,
    updateMiniCIPChemicals,
    getSelectedChemicalByType,
    updateUFChemicalValuesInPH,
    setUFChemicalsDefaultValues,
    setDefaultChemicalDoseValues,
    validateChemicalConcentration,
    updateChemicalOnFeatureChanges,
    validateChemicalsConcentrations,
    updateChemicalWithSameConentration,
  };
};

export default useUFChemicalsHandler;
