import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getRequest } from "src/api/baseApi";

import API_URLS from "@constants/api.urls";

import DupontLogger from "@utils/DupontLogger";

import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";

const useChemicalLibrary = () => {
  const Logger = DupontLogger("useChemicalLibrary");
  const [chemicals, setChemicals] = useState([]);
  const [loaderText, setLoaderText] = useState(null);

  const { UserId } = useSelector(state => state.userInfo.data) || {};

  const fetchChemicals = useCallback(async () => {
    setLoaderText(CHEMICALS_LIBRARY_STRINGS.loadingData);
    try {
      const { data } = await getRequest(API_URLS.chemicalLibrary, { userid: UserId });
      setChemicals(data?.lstChemicalVMs ?? []);
    } catch (error) {
      Logger.error("Error fetching data:", error);
    } finally {
      setLoaderText(null);
    }
  }, [UserId]);

  const updateProjectCosts = updatedCosts => {
    setLoaderText(CHEMICALS_LIBRARY_STRINGS.updatingData);
    //ToDO need actual API intergration
    Logger.log("updatedProjectCosts - updatedCosts: ", updatedCosts);
    setTimeout(() => setLoaderText(null), 2000);
  };

  useEffect(() => {
    if (UserId) {
      fetchChemicals();
    }
  }, [UserId, fetchChemicals]);

  return { loaderText, chemicals, updateProjectCosts };
};

export default useChemicalLibrary;
