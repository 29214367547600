import React from "react";
import DOMPurify from "dompurify";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import { CustomModalButtons } from "@constants/global.constants";

import { ExclamationIconRound, WarningIcon } from "@components/SVGs";
import WPButton from "@components/WPButton";

import CheckCircleIcon from "@common/icons/CheckCircleIcon";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import StyledModal from "@common/styles/components/modals/CustomModal";

import "./index.css";

export const DIALOG_TYPES = {
  ERROR: "danger",
  WARNING: "warning",
  SUCCESS: "success",
};

const ICONS = {
  [DIALOG_TYPES.WARNING]: <WarningIcon />,
  [DIALOG_TYPES.ERROR]: <ExclamationIconRound />,
  [DIALOG_TYPES.SUCCESS]: <CheckCircleIcon />,
};

/**
 * ConfirmationPopup is a reusable modal component that displays a confirmation dialog.
 * It shows a header, an optional description, and buttons for confirming or canceling an action.
 * The `dangerouslySetInnerHTML` attribute is used to set the inner HTML of the description.
 * DOMPurify is used to sanitize the HTML content to prevent XSS attacks.
 */
const ConfirmationPopup = ({ header, type, onClose, description, confirmBtn, cancelBtn }) => {
  const handleClick = ({ target }) => onClose(target.id);

  return (
    <StyledModal show centered onHide={onClose} backdrop='static' maxWidth='416px' className='confirmation-popup'>
      <Modal.Body>
        <div className='message-pop-up'>
          <div className='icon-container'>{ICONS[type]}</div>
          <div className='message-margin'>
            <CustomHeading label={header} fontFamily='DiodrumSemiBold' fontSize='16px' />
            {description && (
              <div
                fontFamily='NotoSans'
                className='description-container'
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
              />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {cancelBtn && (
          <WPButton variant='outlined' type={type} id={CustomModalButtons.CANCEL} onClick={handleClick}>
            {cancelBtn}
          </WPButton>
        )}
        {confirmBtn && (
          <WPButton type={type} id={CustomModalButtons.CONFIRM} onClick={handleClick}>
            {confirmBtn}
          </WPButton>
        )}
      </Modal.Footer>
    </StyledModal>
  );
};

ConfirmationPopup.propTypes = {
  header: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.keys(ICONS)).isRequired,
  onClose: PropTypes.func.isRequired,
  description: PropTypes.string,
  confirmBtn: PropTypes.string.isRequired,
  cancelBtn: PropTypes.string,
};

export default ConfirmationPopup;
