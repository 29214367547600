import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequest } from "src/api/baseApi";

import API_URLS from "@constants/api.urls";
import { TECHNOLOGIES } from "@constants/global.constants";

import { isProdEnv } from "@utils/appUtils";
import DupontLogger from "@utils/DupontLogger";

import { updateGlobalData } from "@common/GlobalDataSlice";
import { updateIXPermission } from "@common/UserInfoSlice";

const useGlobalDataHandler = () => {
  const Logger = DupontLogger("useGlobalDataHandler");
  const dispatch = useDispatch();

  const { countries, marketSegments, waterLibraryList } = useSelector(state => state.globalAppData);
  const { UserId } = useSelector(state => state.userInfo.data);
  const { isIXPermission } = useSelector(state => state.userInfo);

  const fetchCountries = async () => {
    if (countries.length > 0) return;
    try {
      const response = await getRequest(API_URLS.contriesList);
      dispatch(updateGlobalData({ type: "countries", value: response.data }));
    } catch (error) {
      Logger.error("Error fetching countries: ", error);
    }
  };

  const fetchWaterLibraryList = async () => {
    if (waterLibraryList.length > 0) return;
    try {
      const { data } = await getRequest(API_URLS.waterLibrary);
      const { predefinedLibrary, userLibrary } = data.reduce(
        (acc, item) => {
          if (item.isSystem) {
            acc.predefinedLibrary.push(item);
          } else {
            acc.userLibrary.push(item);
          }
          return acc;
        },
        { predefinedLibrary: [], userLibrary: [] },
      );
      const waterLibraryList = { predefinedLibrary, userLibrary };
      dispatch(updateGlobalData({ type: "waterLibraryList", value: waterLibraryList }));
    } catch (error) {
      Logger.error("Error fetching waterLibraryData: ", error);
    }
  };

  const fetchUnreadNotifications = useCallback(async userID => {
    try {
      const response = await getRequest(API_URLS.unreadNotifications, { userID });
      dispatch(updateGlobalData({ type: "unreadNotifications", value: response.data }));
    } catch (error) {
      Logger.error("Error fetching unread notifications: ", error);
    }
  });

  const fetchMarketSegments = useCallback(async userID => {
    if (marketSegments.length > 0) return;
    try {
      const response = await getRequest(API_URLS.marketSegment, { userID });
      dispatch(updateGlobalData({ type: "marketSegments", value: response.data }));
    } catch (error) {
      Logger.error("Error fetching unread notifications: ", error);
    }
  });

  useEffect(() => {
    Logger.info("fetchUnreadNotifications UserId: ", UserId);
    if (UserId) {
      fetchUnreadNotifications(UserId);
      fetchMarketSegments(UserId);
    }
  }, [UserId]);

  const checkIXPermission = useCallback(async userEmail => {
    const isProd = isProdEnv();
    try {
      let hasIXAccess = true;
      if (isProd) {
        const { data } = await getRequest(API_URLS.ixdUsers);
        hasIXAccess = data.some(({ email }) => email === userEmail);
      }
      dispatch(updateIXPermission(hasIXAccess));
    } catch (error) {
      Logger.error("Error fetching fetchIXDUser: ", error);
      if (isProd) {
        dispatch(updateIXPermission(false));
      }
    }
  }, []);

  const isTechDisabled = useCallback(
    techName => techName?.toUpperCase() === TECHNOLOGIES.IX && !isIXPermission,
    [isIXPermission],
  );

  return { fetchCountries, checkIXPermission, isTechDisabled, fetchWaterLibraryList };
};

export default useGlobalDataHandler;
