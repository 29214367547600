import React from "react";
import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { useReport } from "@hooks/useReport";

import IconButton from "@components/IconButton";
import DownloadIcon from "@components/SVGs/DownloadIcon";
import PrintIcon from "@components/SVGs/PrintIcon";
import { WPTypogrpahy } from "@components/WPTypography";

import { colors } from "@common/styles/Theme";

import { WPUfConfiguringationOptionsTable } from "@features/report-new/uf/WPUfConfiguringationOptionsTable";
import { WPUfDesignWarningsTable } from "@features/report-new/uf/WPUfDesignWarningsTable";
import { WPUfFlowDetailsTable } from "@features/report-new/uf/WPUfFlowDetailsTable";
import { WPUfOperatingConditionsTable } from "@features/report-new/uf/WPUfOperatingConditionsTable";
import { WPUfPressureRatingsTable } from "@features/report-new/uf/WPUfPressureRatingsTable";
import { WPUfPumpHydraulicsTable } from "@features/report-new/uf/WPUfPumpHydraulicsTable";
import { WPUfStorageTanksTable } from "@features/report-new/uf/WPUfStorageTanksTable";
import { WPUfSystemOverview } from "@features/report-new/uf/WPUfSystemOverview";
import { WPUfSystemSizeModuleDetailsTable } from "@features/report-new/uf/WPUfSystemSizeModuleDetailsTable";
import { WPUfWaterQualityTable } from "@features/report-new/uf/WPUfWaterQualityTable";

import { WPReportProjectInfoTable } from "../WPReportProjectInfoTable";
import { ufSectionsLabels } from "../WPReportSideNavFilter/WPReportSideNavFilter.constants";
import { WPReportSystemDiagram } from "../WPReportSystemDiagram";

const ufComponentMapping = {
  sytem_diagram: WPReportSystemDiagram,
  uf_system_overview: WPUfSystemOverview,
  uf_operating_conditions: WPUfOperatingConditionsTable,
  uf_configuration_options: WPUfConfiguringationOptionsTable,
  uf_design_quality: WPUfDesignWarningsTable,
  flow_details: WPUfFlowDetailsTable,
  uf_water_quality: WPUfWaterQualityTable,
  uf_Power_ratings: WPUfPressureRatingsTable,
  pump_electrical: WPUfPumpHydraulicsTable,
  uf_Storage_tanks: WPUfStorageTanksTable,
  uf_system_module: WPUfSystemSizeModuleDetailsTable,
};

export const WPReportContentHeader = () => {
  const { generatePDF } = useReport();

  return (
    <div className='report-content-header'>
      <WPTypogrpahy asElement='h2' color={colors.PrimaryDarkAquaMarine} fontSize={14}>
        {reportStrings.ufSummaryReport}
      </WPTypogrpahy>
      <div>
        <IconButton className='mx-3' id='download-report' tooltip='Download Report' onClick={generatePDF}>
          <DownloadIcon />
        </IconButton>
        <IconButton className='mx-3' id='print-report' tooltip='Print Report'>
          <PrintIcon />
        </IconButton>
      </div>
    </div>
  );
};

export const WPReportContent = () => {
  const reportSectionData = useSelector(state => state.report.reportSectionData);

  const { ufSectionData } = reportSectionData;

  return (
    <>
      <WPReportContentHeader />
      <div id='content-to-print' className='report-content-main'>
        <WPReportProjectInfoTable />
        {Object.keys(ufSectionsLabels).map(key => {
          if (!ufSectionData[key]) {
            return null;
          }
          const Component = ufComponentMapping[key];
          return <Component key={key} />;
        })}
      </div>
    </>
  );
};
