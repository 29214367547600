import { useSelector } from "react-redux";

import { WPReportSectionHeader } from "../WPReportSectionHeader";

export const WPReportSystemDiagram = () => {
  const systemDiagramBase64 = useSelector(state => state.report.systemDiagramBase64);
  return (
    <>
      <WPReportSectionHeader text='System Diagram' />
      <img width='100%' src={systemDiagramBase64} />
    </>
  );
};
