import React, { useState } from "react";
import styled from "styled-components";

import ToggleSwitch from "@common/styles/components/switches/CustomSwitch";

const ToggleContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const ChemicalToggleRenderer = ({ data }) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const handleToggleChange = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    return (
        <ToggleContainer id='Toggle-container'>
            <ToggleSwitch
                small
                id={"toggle"}
                name={"toggle"}
                disabled={data.isSystem}
                checked={data.isSystem || data.showDropDown}
                onChange={handleToggleChange}
            />
        </ToggleContainer>
    );
};

export default ChemicalToggleRenderer;
