import { useReportUtils } from "@features/report-new/useReportUtils";

import {
  WPUfStorageTanksTableColumns as columns,
  WPUfStorageTanksTableRowsData as rowsData,
  WPUfStorageTanksTableUnitMapping as unitMapping,
} from "./WPUfStorageTanksTable.constants";

export const useWPUfStorageTanksTableData = () => {
  const { ufReportRespData, formatConvertValue, formatUnitLabel, formatLabel } = useReportUtils();
  const { uf_Storage_tanks } = ufReportRespData || {};

  const convertToUfStorageTanksTableData = () => {
    // unit row
    const firstRow = columns.reduce((acc, curr) => {
      acc[curr.field] = formatUnitLabel(unitMapping[curr.field]);
      return acc;
    }, {});

    const data = Object.entries(rowsData).reduce((acc, curr) => {
      const [rowKey, row] = curr;
      const { label, chemical } = row;
      const apiSingleRowData = uf_Storage_tanks?.[rowKey] || {};
      const { display = true, Min_recommended_volume, Bulk_conc } = apiSingleRowData;

      if (!display) return acc;

      const labelValue = chemical ? `${chemical.name} (${chemical.symbol})` : label;
      const labelIndentLevel = chemical ? 1 : 0;

      acc.push({
        label: formatLabel(labelValue, labelIndentLevel),
        // precision is 0
        bulk_conc: formatConvertValue(Bulk_conc, unitMapping.bulk_conc, 0),
        // No trailing zeros for min_recommended_volume, this is an exception
        min_recommended_volume: Number(
          formatConvertValue(Min_recommended_volume, unitMapping.min_recommended_volume, 3),
        ),
      });
      return acc;
    }, []);

    // adding unit row at the top
    data.unshift(firstRow);

    return data;
  };

  return { convertToUfStorageTanksTableData };
};
