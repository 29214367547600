import React, { useCallback } from "react";
import PropTypes from "prop-types";

import CustomSelect from "@common/styles/components/selects/CustomSelect";

import { CHEMICAL_UNIT_TYPES } from "../chemicalLibrary.constants";

const ChemicalDropdownRenderer = React.memo(({ value, context, data }) => {
    const handleDropdownChange = useCallback(
        ({ target: { value: costType } }) => {
            context.onDataUpdate?.({ ...data, costType });
        },
        [context, data],
    );

    return (
        <CustomSelect
            id={`chemical_dropdown_${data.iD}`}
            name={`chemical_dropdown_${data.iD}`}
            value={value}
            onChange={handleDropdownChange}
        >
            {CHEMICAL_UNIT_TYPES.map(item => (
                <option key={item} id={`chemical_dropdown_${data.iD}_option_${item}`} value={item}>
                    {item}
                </option>
            ))}
        </CustomSelect>
    );
});

ChemicalDropdownRenderer.displayName = "ChemicalDropdownRenderer";

ChemicalDropdownRenderer.propTypes = {
    value: PropTypes.string.isRequired,
    context: PropTypes.shape({ onDataUpdate: PropTypes.func }),
    data: PropTypes.object.isRequired,
};

export default ChemicalDropdownRenderer;
