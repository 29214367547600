import { reportStrings } from "@constants/report.strings";
import { UNIT_TYPES, UNITS } from "@constants/units.constant";

export const WPUfWaterQualityTableColumns = [
  {
    field: "col1",
    rowHeader: true,
  },
  {
    field: "col2",
    rowHeader: true,
  },
  {
    field: "col3",
    headerName: reportStrings.feed,
  },
  {
    field: "col4",
    headerName: reportStrings.product,
  },
];

export const WPUfWaterQualityTableDataRows = {
  source: {
    label: reportStrings.source,
  },
  temperature_design: {
    label: reportStrings.tempDesign,
    unit: UNIT_TYPES.TEMPERATURE,
  },
  turbidity: {
    label: reportStrings.turbidity,
    unit: UNITS.ntu,
  },
  Organics_TOC: {
    label: reportStrings.organicsTOC,
    unit: UNITS.MiligramPerLiter,
  },
  TSS: {
    label: reportStrings.tss,
    unit: UNITS.MiligramPerLiter,
  },
  TDS: {
    label: reportStrings.tds,
    unit: UNITS.MiligramPerLiter,
  },
  pH: {
    label: reportStrings.ph,
    unit: UNITS.MiligramPerLiter,
  },
};
