import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { useReport } from "@hooks/useReport";

import { colors } from "@common/styles/Theme";
import DynamicLoadder from "@common/utils/DynamicLoadder";

import { WPReportContent } from "./common/WPReportContent";
import { WPReportSideNav } from "./common/WPReportSideNav";
import { useUfReport } from "./uf/useUfReport";

const WPReportStyled = styled.div`
  .report-wrapper {
    min-height: 100vh;
    margin-top: 10px;
  }
  .report-sidenav-wrapper {
    display: block;
    width: 24%;
    position: fixed;
    background-color: ${colors.White};
    overflow-y: auto;
    height: 100vh;
  }
  .report-content-wrapper {
    margin-left: auto;
    width: 76%;
  }
  .report-sidenav-content {
    padding: 16px;
  }
  .report-content {
    width: 100%;
    padding: 16px;
  }
  .report-content-main {
    background-color: ${colors.White};
    padding: 12px;
  }
  .report-content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
  }
`;

export const WPReport = () => {
  const { ufCalcReportApiResp } = useSelector(state => state.report);
  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);
  const { getUfReportData } = useReport();
  const { convertUfCalcReportToTableData } = useUfReport();

  useEffect(() => {
    getUfReportData();
  }, []);

  useEffect(() => {
    convertUfCalcReportToTableData();
  }, [unitConfig.selectedUnits, ufCalcReportApiResp]);

  return (
    <WPReportStyled>
      <DynamicLoadder isLoading={ufCalcReportApiResp.isLoading}>
        <div className='report-wrapper'>
          <div className='report-sidenav-wrapper'>
            <div className='report-sidenav-content'>
              <WPReportSideNav />
            </div>
          </div>
          <div className='report-content-wrapper'>
            <div className='report-content'>
              <WPReportContent />
            </div>
          </div>
        </div>
      </DynamicLoadder>
    </WPReportStyled>
  );
};

export default WPReport;
