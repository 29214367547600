import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postRequest } from "src/api/baseApi";
import { downloadExportedProject } from "src/api/importApi/importApi";

import API_URLS from "@constants/api.urls";
import { APP_TOAST_NOTIFICATION } from "@constants/message.constants";

import { downloadBlobData } from "@utils/appUtils";
import { ImportProjectStrings } from "@utils/StringConstants";

import { useFullScreenLoader } from "@components/WPFullScreenLoader";
import { useToastNotification } from "@components/WPToastNotification";

import { updateProjectInfo } from "@common/ProjectInfoSlice";

export const useExportProject = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showFullScreenLoader, hideFullScreenLoader } = useFullScreenLoader();

  const { showSuccessNotification, showErrorNotification } = useToastNotification();

  const showErrorToast = description => showErrorNotification(description);

  const exportProject = async (projectId, projectName) => {
    if (!projectId) return false;

    const params = new URLSearchParams({ projectId });

    try {
      showFullScreenLoader(ImportProjectStrings.exporingProject);
      const { status, data } = await downloadExportedProject(params);

      if (status === 200) {
        downloadBlobData(data, `${projectName}.dwpx`);
        showSuccessNotification(APP_TOAST_NOTIFICATION.PROJECT_EXPORTED);
      } else {
        showErrorToast(APP_TOAST_NOTIFICATION.PROJECT_EXPORT_FAILED);
      }
    } catch (error) {
      showErrorToast(error.message);
    } finally {
      hideFullScreenLoader();
    }
  };

  const duplicateProject = async ({ ProjectId }) => {
    try {
      showFullScreenLoader(ImportProjectStrings.duplicatingProject);
      const { data } = await postRequest(API_URLS.duplicateProject, { projectID: ProjectId });
      showSuccessNotification(APP_TOAST_NOTIFICATION.PROJECT_DUPLICATED);

      const projInfo = {
        title: data.projectName,
        projectID: data.projectID,
        projectName: data.projectName,
        Technology: data.technologyName,
        caseID: 0,
      };

      dispatch(updateProjectInfo(projInfo));
      navigate("/FeedWaterHome", { state: { ...projInfo } });
    } catch (error) {
      showErrorToast(APP_TOAST_NOTIFICATION.PROJECT_DUPLICATE_FAILED);
    } finally {
      hideFullScreenLoader();
    }
  };

  return {
    exportProject,
    duplicateProject,
  };
};
