import { reportStrings } from "@constants/report.strings";
import { UNIT_TYPES, UNITS } from "@constants/units.constant";

export const WPUfPumpHydraulicsTableColumns = [
  {
    field: "label", // custom field not present in the API response
    rowHeader: true,
    headerName: reportStrings.pump,
  },
  {
    field: "peak_flowrate",
    headerName: reportStrings.peakFlowRate,
  },
  {
    field: "avg_pressure",
    headerName: reportStrings.avgPressure,
  },
  {
    field: "mech_power",
    headerName: reportStrings.mechanicalPower,
  },
  {
    field: "elec_power",
    headerName: reportStrings.electricalPower,
  },
  {
    field: "engery",
    headerName: reportStrings.energy,
  },
  {
    field: "cost",
    headerName: reportStrings.cost,
  },
];

export const WPUfPumpHydraulicsTableRowsData = {
  feed: {
    label: reportStrings.feed,
  },
  feed_HCL: {
    isChemicalPump: true,
  },
  feed_PACl: {
    isChemicalPump: true,
  },
  feed_NaOCl: {
    isChemicalPump: true,
  },
  Backwash_pe: {
    label: reportStrings.backwash,
  },
  Backwash_NaOCl: {
    isChemicalPump: true,
  },
  CEB_pe: {
    label: reportStrings.ceb,
  },
  CEB_HCL: {
    isChemicalPump: true,
  },
  CEB_Citricacid: {
    isChemicalPump: true,
  },
  CEB_NaOH: {
    isChemicalPump: true,
  },
  CEB_NaOCl_fouling: {
    isChemicalPump: true,
  },
  CEB_NaOCl_disinf: {
    isChemicalPump: true,
  },
  Mini_CIP_pe: {
    label: reportStrings.miniCip,
  },
  Mini_CIP_H2SO4: {
    isChemicalPump: true,
  },
  Mini_CIP_Citricacid: {
    isChemicalPump: true,
  },
  Mini_CIP_NaOH: {
    isChemicalPump: true,
  },
  Mini_CIP_NaOCl: {
    isChemicalPump: true,
  },
  Mini_CIP_SLS: {
    isChemicalPump: true,
  },
  CIP_pe: {
    label: reportStrings.cip,
  },
  CIP_HCL: {
    isChemicalPump: true,
  },
  CIP_Citricacid: {
    isChemicalPump: true,
  },
  CIP_NaOH: {
    isChemicalPump: true,
  },
  CIP_NaOCl: {
    isChemicalPump: true,
  },
  CIP_SLS: {
    isChemicalPump: true,
  },
  CIP_Solution_Heating: {
    label: reportStrings.cipSolutionHeating,
  },
  Air_compressor_scour: {
    label: reportStrings.airCompressorScour,
  },
  Air_compressor_ADBW: {
    label: reportStrings.airCompressorAdbw,
  },
  Electrical_Valves: {
    label: reportStrings.electricalValves,
  },
  PLC_Instrumentation: {
    label: reportStrings.plcAndInstrumentation,
  },
  Total_electrical_cost: {
    label: reportStrings.totalElectricalCost,
  },
};

export const WPUfPumpHydraulicsUnitMapping = {
  peak_flowrate: UNIT_TYPES.FLOW,
  avg_pressure: UNIT_TYPES.PRESSURE,
  mech_power: UNIT_TYPES.POWER,
  elec_power: UNIT_TYPES.POWER,
  engery: UNITS.kiloWattHourPerDay,
  cost: UNITS.day, // this is cost $/d
};
