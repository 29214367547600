import { useEffect, useMemo, useState } from "react";
import { Card, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { UNIT_TYPES } from "@constants/units.constant";

import useUFChemicalsHandler from "@hooks/useUFChemicalsHandler";
import useUFConfig from "@hooks/useUFConfig";
import { useUFInputRanges } from "@hooks/useUFInputRanges";
import useUFTechnologyStrings from "@hooks/useUFTechnologyStrings";
import useUnitConversion from "@hooks/useUnitConversion";

import { convertUptoDigits, isValueInRange } from "@utils/appUtils";
import { DesignStrings } from "@utils/StringConstants";

import InputRanges from "@components/InputRanges";

import InfoIcon from "@common/icons/InfoIcon";
import PlusIcon from "@common/icons/PlusIcon";
import StandardLinkButtonWithIcon from "@common/styles/components/buttons/standard/StandardLinkButtonWithIcon";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomRadioCheck from "@common/styles/components/checkboxs/CustomRadioCheck";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import ErrorMessage from "@common/styles/components/headings/ErrorMessage";
import IconWithTooltip from "@common/styles/components/headings/IconWithTooltip";
import { handleTabAndEnter } from "@common/styles/components/inputs/InputFocusUtililty";
import InputWithText from "@common/styles/components/inputs/InputWithText";
import CustomSelect from "@common/styles/components/selects/CustomSelect";
import { colors } from "@common/styles/Theme";

import ProjectErrorPopup from "../../modals/ProjectErrorPopup";
import { updateFeedSetupStoreData } from "../feedsetup/FeedsetupSlice";
import { setCustomOfflineTimePerUnit, setUfDataUpdate, updateUFStoreData } from "../uf/UFSlice";

import {
  CHEMICAL_TYPES,
  FIELD_OFFLINE_TIME_PER_TRAIN,
  UF_CHEMICAL_SCREENS,
  UF_SPECIAL_FEATURE,
} from "./constants/UFConstants";
import ModuleSelection from "./UFConfiguration/ModuleSelection";
import ChemicalAdjustmentModal from "./ChemicalAdjustmentModal";
import DesignStyled from "./DesignStyled";
import UFSystemDesignDiagram from "./UFSystemDesignDiagram";
import { isIngeSelected } from "./ufUtils";

const Design = () => {
  const dispatch = useDispatch();

  const { updateNonMiniCIPFieldsValue } = useUFConfig();
  const { convertFromMetric } = useUnitConversion();
  const { getUFFieldsDefaultValues, getConfigForForwardFlushFlow } = useUFInputRanges();

  const { updateChemicalOnFeatureChanges, setDefaultChemicalDoseValues } = useUFChemicalsHandler();
  const UFStore = useSelector(state => state.UFStore);

  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const { feedFlowRate, productFlowRate, selectedEndNode } = useSelector(state => state.processDiagramSlice);
  const { tempDesign } =
    useSelector(state => state.Feedsetupdetailsdatapanel?.streamData?.lstrequestsavefeedwater[0]?.streams[0]) || {};

  const { activeUFModule, defaultInputRangeConfig, ufTechnologies, data: UFData } = UFStore;

  const [message, setMessage] = useState("");
  const [isFocused, setIsFocused] = useState(null);
  const [isFieldValid, setIsFieldValid] = useState(false);

  const [initialUfData, setIntialUfData] = useState(null);
  const [isChemicaldoseSetToDefault, setIsChemicalDoseSetToDefault] = useState(null);
  const [DISABLED_INPUTS, setDISABLED_INPUTS] = useState([]);
  const [customValue, setCustomValue] = useState(UFData.offlinetimepertrain);
  const [openChemicalAdjustment, setOpenChemicalAdjustment] = useState(false);

  const ufTechnologyStrings = useUFTechnologyStrings();

  const technologyID = UFData.pUFTechnologyID;
  const flowRateSelected = selectedEndNode == "startNode" ? "Feed" : "Product";
  const flowRateValueSelected = flowRateSelected == "Feed" ? feedFlowRate : productFlowRate;

  const updateStoreData = newData => dispatch(updateUFStoreData(newData));

  // initial UFData when the component is mounted
  useEffect(() => {
    setIntialUfData(UFData);
  }, []);

  /**
   * This useEffect checks if the special feature ID is 'isCebOnly' when the active UF module changes.
   * If true, it dispatches an action to update the UF store data with miniCIP set to 0.
   * The setTimeout ensures the dispatch action is executed after the component data has beed updated and avoid override.
   */
  useEffect(() => {
    const isCebOnly = +UFData.ufSpecialFeatureID === +UF_SPECIAL_FEATURE.isCebOnly;
    setTimeout(() => {
      if (isCebOnly) {
        dispatch(updateUFStoreData({ data: { miniCIP: 0 } }));
      }
    }, 100);
  }, [activeUFModule]);

  useEffect(() => {
    if (isIngeSelected(technologyID)) {
      setDISABLED_INPUTS([ufTechnologyStrings.airFlow, ufTechnologyStrings.aerationAirFlow]);
    } else {
      setDISABLED_INPUTS([
        ufTechnologyStrings.automatic,
        ufTechnologyStrings.aerationAirFlow,
        ufTechnologyStrings.disinfectionCEB,
      ]);
    }
  }, [technologyID]);

  const isMiniCIPOnly = useMemo(
    () => +UFData.ufSpecialFeatureID === +UF_SPECIAL_FEATURE.isMiniCipOnly,
    [UFData.ufSpecialFeatureID],
  );

  useEffect(() => {
    if (UFStore.isOfflineTimeChanged && customValue != 12 && customValue != 0) {
      updateStoreData({ data: { offlinetimepertrain: customValue } });
    }
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleTabAndEnter(event);
    }
  };

  /* Handling Chemical Adjustment Popup */
  const handleOpenChemicalAdjustment = () => setOpenChemicalAdjustment(true);
  const handleFocus = id => setIsFocused(id);

  // currently this mapping is used in blur handler to display label name in error modal
  const stringMapping = {
    filtrateFlux: ufTechnologyStrings.filtrateFlux,
    backwashFlux: ufTechnologyStrings.backwashFlush,
    cEBFlux: ufTechnologyStrings.cebFlux,
    airFlow: ufTechnologyStrings.airFlow,
    aerationAirFlow: ufTechnologyStrings.aerationAirFlow,
    forwardFlushFlow: ufTechnologyStrings.forwardFlushFlow,
    recycleFlowRate: ufTechnologyStrings.cIPRecycleFlowRate,
    recycleFlowRate_MiniCIP: ufTechnologyStrings.miniCIPRecycleFlowRate,
    disinfectionCEB: ufTechnologyStrings.disinfectionCEB,
    acidCEB: ufTechnologyStrings.acidCEB,
    miniCIP: ufTechnologyStrings.miniCip,
    alkaliOxidantCEB: ufTechnologyStrings.alkaliOxidantCEB,
    cIP: ufTechnologyStrings.cIP,
    backwash_design: ufTechnologyStrings.filtrationDuration,
    offlinetimepertrain: ufTechnologyStrings.offlineTimeperUnit,
  };

  // TODO: Refactored most of the DRY code however the logic is still the same to be revisited later
  const handleBlur = e => {
    let valueIsSafe = false;

    const { name, value } = e.target;
    if (isRangeError(name, value)) {
      setIsFieldValid(true);
      const { minValue, maxValue } = validations[name];
      setMessage(
        // eslint-disable-next-line max-len
        `The ${stringMapping[name]} value entered is outside the allowed range (${minValue} to ${maxValue}). Please revise your input.`,
      );
      setIsFocused(name);
      setTimeout(() => e.target.focus(), 0);
    } else {
      valueIsSafe = true;
      setIsFieldValid(false);
    }
    if (valueIsSafe) {
      setIsFocused(null);
      let data = {};
      if (name === "offlinetimepertrain") {
        const newValue = Number(value);
        setCustomValue(newValue);
        data = { [name]: newValue };
      } else if (name === "forwardFlushFlow") {
        data = { [name]: convertUptoDigits(value) };
      } else if (name === "filtrateFlux") {
        const ffConfig = getConfigForForwardFlushFlow(activeUFModule, value);
        data = { [name]: parseFloat(value) };
        if (ffConfig.length > 0) {
          const forwardFlushFlow = ffConfig[0].defaultValue;
          data = { ...data, forwardFlushFlow };
        }
      } else {
        const floatValue = parseFloat(value);
        const isValidNumber = !isNaN(floatValue) && floatValue === Math.floor(floatValue);
        const val = isValidNumber ? Math.floor(floatValue) : convertUptoDigits(floatValue);
        data = { [name]: val.toString() };
      }
      setChemicalDoseDefaultValues(name, value);
      dispatch(updateUFStoreData({ data, calcEngineDataRefreshCount: 1 }));
    }
  };

  const chemicalDosemapping = {
    disinfectionCEB: { screen: UF_CHEMICAL_SCREENS.CEB, chemicalType: CHEMICAL_TYPES.disOxidant },
    acidCEB: { screen: UF_CHEMICAL_SCREENS.CEB, chemicalType: CHEMICAL_TYPES.mineral },
    alkaliOxidantCEB: { screen: UF_CHEMICAL_SCREENS.CEB, chemicalType: CHEMICAL_TYPES.alkali },
    cIP: { screen: UF_CHEMICAL_SCREENS.CIP },
    miniCIP: { screen: UF_CHEMICAL_SCREENS.MINI_CIP },
  };

  const setChemicalDoseDefaultValues = (name, value) => {
    const { screen, chemicalType } = chemicalDosemapping[name] || {};
    if (!screen) return;
    if (Number(value) === 0) {
      setDefaultChemicalDoseValues(screen, chemicalType, false);
      setIsChemicalDoseSetToDefault({ ...isChemicaldoseSetToDefault, [name]: true });
    }
    // If previous value is zero and new value is not zero then set default values
    // the or condition is to handle a specific scenario  ex: acidCeb 30 --> 0 --> 57
    else if ((Number(initialUfData[name]) === 0 && Number(value) !== 0) || isChemicaldoseSetToDefault?.[name]) {
      setDefaultChemicalDoseValues(screen, chemicalType, true);
    }
  };

  /* Saving User Inputs in Component Internal State */
  const handleUFDesignTech = ({ target }) => {
    const { name, value } = target;
    const techId = Number(value);
    dispatch(updateFeedSetupStoreData({ isUpdateActiveModule: true }));
    updateStoreData({ data: { [name]: techId, ufSpecialFeatureID: 1 } });
  };

  const handleInputChange = ({ target }) => {
    let { name, value } = target;
    value = value || "";

    if (!isNaN(value)) {
      const newData = { [name]: value };

      if (name === "offlinetimepertrain") {
        const valueNumber = Number(value);
        newData[name] = valueNumber;
        setCustomValue(valueNumber);
        dispatch(setCustomOfflineTimePerUnit(true));
      }
      if (name === "ufSpecialFeatureID") {
        updateChemicalOnFeatureChanges(value);
        updateNonMiniCIPFieldsValue(value);
        updateMiniCipRecycleFlowRate(value);
      }
      updateStoreData({ data: { ...newData } });
      dispatch(setUfDataUpdate(true));
    }
  };

  const updateMiniCipRecycleFlowRate = specialFeatureId => {
    const { isCebOnly } = UF_SPECIAL_FEATURE;

    // recycleFlowRate_MiniCIP value should be zero when cebOnly spefial feature is selected.
    // when moving from ceb only to any other special feature should updated to recycleFlowRate value
    const { recycleFlowRate_MiniCIP, miniCIP } = UFData;
    let data = { recycleFlowRate_MiniCIP, miniCIP };
    if (+specialFeatureId === +isCebOnly) {
      data = { recycleFlowRate_MiniCIP: 0, miniCIP: 0 };
    } else if (+UFData.ufSpecialFeatureID === +isCebOnly) {
      data = getUFFieldsDefaultValues(activeUFModule, ["miniCIP"]);
      data.recycleFlowRate_MiniCIP = UFData.recycleFlowRate;
    }
    updateStoreData({ data });
  };

  const getRange = (field, unitType) => {
    const { minValue, maxValue, uom } = defaultInputRangeConfig[field];
    if (!unitType)
      return {
        minValue: convertUptoDigits(minValue),
        maxValue: convertUptoDigits(maxValue),
        uom,
      };
    return {
      minValue: convertUptoDigits(convertFromMetric(minValue, unitType)),
      maxValue: convertUptoDigits(convertFromMetric(maxValue, unitType)),
    };
  };

  const validations = {
    filtrateFlux: getRange("filtrateFlux", UNIT_TYPES.FLUX),
    backwashFlux: getRange("backwashFlux", UNIT_TYPES.FLUX),
    cEBFlux: getRange("cEBFlux", UNIT_TYPES.FLUX),
    airFlow: getRange("airFlow", UNIT_TYPES.GAS_FLOW),
    aerationAirFlow: getRange("aerationAirFlow", UNIT_TYPES.GAS_FLOW),
    forwardFlushFlow: getRange("forwardFlushFlow", UNIT_TYPES.FLOW),
    recycleFlowRate: getRange("recycleFlowRate", UNIT_TYPES.FLOW),
    recycleFlowRate_MiniCIP: getRange("recycleFlowRate_MiniCIP", UNIT_TYPES.FLOW),
    disinfectionCEB: getRange("disinfectionCEB"),
    acidCEB: getRange("acidCEB"),
    miniCIP: getRange("miniCIP"),
    alkaliOxidantCEB: getRange("alkaliOxidantCEB"),
    cIP: getRange("cIP"),
    backwash_design: getRange("backwash_design"),
    offlinetimepertrain: FIELD_OFFLINE_TIME_PER_TRAIN,
  };

  const isRangeError = (field, currentVal) => {
    const value = currentVal || UFData[field];
    return !isValueInRange(value, validations[field]);
  };

  const handelOfflineTrain = e => {
    const { name, value } = e.target;
    if (!isNaN(value)) {
      const userInput = value == "" ? value : Number(value);
      setCustomValue(userInput);
      const newValue = value == "" ? value : Number(value);
      updateStoreData({ data: { [name]: newValue } });
      dispatch(setCustomOfflineTimePerUnit(true));
      dispatch(setUfDataUpdate(true));
    }
  };

  const checkError = id =>
    !(FIELD_OFFLINE_TIME_PER_TRAIN?.minValue <= UFData[id] && FIELD_OFFLINE_TIME_PER_TRAIN?.maxValue >= UFData[id]);

  const getError = name => {
    const value = UFData[name];
    const validator = FIELD_OFFLINE_TIME_PER_TRAIN;

    if (value < validator?.minValue || value > validator?.maxValue) {
      return <ErrorMessage texMsg={"Values out of range"} />;
    }
    return <InputRanges ranges={validator} />;
  };

  const formattedDesignTemp = useMemo(() => {
    const selectedTempUnit = unit.selectedUnits[UNIT_TYPES.TEMPERATURE];
    return `${convertFromMetric(tempDesign, UNIT_TYPES.TEMPERATURE)} ${selectedTempUnit}`;
  }, [tempDesign, unit.selectedUnits]);

  const getValue = field => (isFocused === field ? UFData[field] : convertUptoDigits(UFData[field]));

  return (
    <>
      <DesignStyled className='g-0'>
        <UFSystemDesignDiagram />
        <Col lg={12} md={12} sm={12}>
          <div className='uf-design'>
            <div className='card-wrapper-one'>
              {/* UF Feed Flow Rate */}
              <StyledCard className='uf-feed-flow' borderRadius='4px'>
                <Card.Header>
                  <CustomHeading
                    fontFamily='NotoSansRegular'
                    color={colors.PrimaryDarkAquaMarine}
                    label={`UF ${flowRateSelected} Flow Rate`}
                  />
                  <IconWithTooltip
                    label='Estimated UF Feed or Product flow, based on user input and estimated recoveries of other icons.'
                    icon={<InfoIcon />}
                  />
                </Card.Header>
                <CustomLabel disabled={true} label={ufTechnologyStrings.automatic} />
                <InputWithText
                  inputText={unit.selectedUnits[1]}
                  disabled={true}
                  isError={false}
                  value={flowRateValueSelected}
                  id='automatic'
                  onFocus={() => handleFocus(1)}
                  isFocused={isFocused === 1}
                  onBlur={handleBlur}
                  tabIndex={DISABLED_INPUTS.includes(DesignStrings.aerationAirFlow) ? -1 : 1}
                />
              </StyledCard>
              {/* UF Technology Selection */}
              <StyledCard className='uf-technology-selection' borderRadius='4px'>
                <Card.Header>
                  <CustomHeading
                    fontFamily='NotoSansRegular'
                    color={colors.PrimaryDarkAquaMarine}
                    label={ufTechnologyStrings.ufTechnologySelection}
                  />
                  <IconWithTooltip
                    label='Select family of DuPont UF products, which determines the default cleaning protocols.'
                    icon={<InfoIcon />}
                  />
                </Card.Header>
                <CustomLabel label={ufTechnologyStrings.ufTechnology} />
                <CustomSelect
                  type=''
                  id='ufTechnologies'
                  name='pUFTechnologyID'
                  placeholder='UF Technologies'
                  onChange={handleUFDesignTech}
                  value={UFData.pUFTechnologyID}
                  tabIndex={2}
                >
                  {ufTechnologies.map(technology => (
                    <option value={technology.pUFTechnologyID} key={`ufTech-${technology.companyName}`}>
                      {technology.pUFTechName}
                    </option>
                  ))}
                </CustomSelect>
              </StyledCard>
              {/* Module Selection */}
              <ModuleSelection showProductGuidelines />
            </div>
            <div className='card-wrapper-two'>
              {/* Design Instantaneous (Gross) Flux and Flow Rates */}
              <StyledCard className='flux-and-flow-rate' borderRadius='4px'>
                <Card.Header>
                  <CustomHeading
                    fontFamily='NotoSansRegular'
                    color={colors.PrimaryDarkAquaMarine}
                    label={ufTechnologyStrings.designFlowFluxRates}
                  />
                  <IconWithTooltip
                    label='Provide fluxes and flow rates for filtration and various steps in the cleaning protocols.'
                    icon={<InfoIcon />}
                  />
                </Card.Header>
                <div className='flux-and-flow-wrapper'>
                  <CustomLabel label={`Filtrate Flux (for ${formattedDesignTemp})`} />
                  <InputWithText
                    type='number'
                    inputText={unit.selectedUnits[4]}
                    id='filtrateInput'
                    name='filtrateFlux'
                    placeholder='0.00'
                    value={getValue("filtrateFlux")}
                    disabled={DISABLED_INPUTS.includes("Filtrate Flux")}
                    onChange={handleInputChange}
                    onFocus={() => handleFocus("filtrateFlux")}
                    isFocused={isFocused === "filtrateFlux"}
                    onBlur={handleBlur}
                    isError={isRangeError("filtrateFlux")}
                    tabIndex={DISABLED_INPUTS.includes("Filtrate Flux") ? -1 : 3}
                  />
                  <InputRanges ranges={validations.filtrateFlux} />
                </div>
                <div className='flux-and-flow-wrapper'>
                  <CustomLabel label={ufTechnologyStrings.backwashFlush} />
                  <InputWithText
                    type='number'
                    inputText={unit.selectedUnits[4]}
                    id='backwashFluxInput'
                    name='backwashFlux'
                    disabled={DISABLED_INPUTS.includes(DesignStrings.backwashFlush)}
                    isError={isRangeError("backwashFlux")}
                    placeholder='0.00'
                    value={getValue("backwashFlux")}
                    onChange={handleInputChange}
                    onFocus={() => handleFocus("backwashFlux")}
                    isFocused={isFocused === "backwashFlux"}
                    onBlur={handleBlur}
                    tabIndex={4}
                  />
                  <InputRanges ranges={validations.backwashFlux} />
                </div>
                <div className='flux-and-flow-wrapper'>
                  <CustomLabel label={ufTechnologyStrings.cebFlux} />
                  <InputWithText
                    type='number'
                    inputText={unit.selectedUnits[4]}
                    id='cEBFluxInput'
                    name='cEBFlux'
                    disabled={isMiniCIPOnly || DISABLED_INPUTS.includes("CEB Flux")}
                    isError={isRangeError("cEBFlux")}
                    placeholder='0.00'
                    value={getValue("cEBFlux")}
                    onChange={handleInputChange}
                    onFocus={() => handleFocus("cEBFlux")}
                    isFocused={isFocused === "cEBFlux"}
                    onBlur={handleBlur}
                    tabIndex={5}
                  />
                  <InputRanges ranges={validations.cEBFlux} />
                </div>
                <div className='flux-and-flow-wrapper'>
                  <CustomLabel label={ufTechnologyStrings.forwardFlushFlow} />
                  <InputWithText
                    type='number'
                    inputText={unit.selectedUnits[1] + "/module"}
                    id='forwardFlushFlowInput'
                    name='forwardFlushFlow'
                    disabled={DISABLED_INPUTS.includes(DesignStrings.forwardFlushFlow)}
                    isError={isRangeError("forwardFlushFlow")}
                    placeholder='0.00'
                    value={getValue("forwardFlushFlow")}
                    onChange={handleInputChange}
                    onFocus={() => handleFocus("forwardFlushFlow")}
                    isFocused={isFocused === "forwardFlushFlow"}
                    onBlur={handleBlur}
                    tabIndex={6}
                  />
                  <InputRanges ranges={validations.forwardFlushFlow} />
                </div>
                <div className='flux-and-flow-wrapper'>
                  <CustomLabel
                    disabled={DISABLED_INPUTS.includes(DesignStrings.airFlow)}
                    label={ufTechnologyStrings.airFlow}
                  />
                  <InputWithText
                    type='number'
                    inputText={unit.selectedUnits[18] + "/module"}
                    id='airFlowInput'
                    name='airFlow'
                    disabled={DISABLED_INPUTS.includes(DesignStrings.airFlow)}
                    isError={isRangeError("airFlow")}
                    placeholder='0.00'
                    value={isIngeSelected(UFData.pUFTechnologyID) ? 0 : getValue("airFlow")}
                    onChange={handleInputChange}
                    onFocus={() => handleFocus("airFlow")}
                    isFocused={isFocused === "airFlow"}
                    onBlur={handleBlur}
                    tabIndex={7}
                  />
                  {!isIngeSelected(UFData.pUFTechnologyID) && <InputRanges ranges={validations.airFlow} />}
                </div>
                <div className='flux-and-flow-wrapper'>
                  <CustomLabel
                    disabled={DISABLED_INPUTS.includes(DesignStrings.aerationAirFlow)}
                    label={ufTechnologyStrings.aerationAirFlow}
                  />
                  <InputWithText
                    type='number'
                    inputText={unit.selectedUnits[18] + "/module"}
                    id='aerationAirFlowInput'
                    name='aerationAirFlow'
                    disabled={DISABLED_INPUTS.includes(DesignStrings.aerationAirFlow)}
                    isError={isRangeError("aerationAirFlow")}
                    value={isFocused === "aerationAirFlow" ? UFData.airFlow : convertUptoDigits(UFData.aerationAirFlow)}
                    onChange={handleInputChange}
                    onFocus={() => handleFocus("aerationAirFlow")}
                    isFocused={isFocused === "aerationAirFlow"}
                    onBlur={handleBlur}
                    tabIndex={8}
                  />
                </div>
                <div className='flux-and-flow-wrapper'>
                  <div className='cip-recycle-flow-rate '>
                    <CustomLabel
                      disabled={DISABLED_INPUTS.includes(DesignStrings.cIPRecycleFlowRate)}
                      label={ufTechnologyStrings.cIPRecycleFlowRate}
                    />
                    <IconWithTooltip label={DesignStrings.cipRecycleFlowRateInfo} icon={<InfoIcon />} />
                  </div>
                  <InputWithText
                    type='number'
                    inputText={unit.selectedUnits[1] + "/module"}
                    id='recycleFlowRateInput'
                    name='recycleFlowRate'
                    disabled={DISABLED_INPUTS.includes(DesignStrings.cIPRecycleFlowRate)}
                    isError={isRangeError("recycleFlowRate")}
                    placeholder='0.00'
                    value={
                      isFocused === "recycleFlowRate"
                        ? UFData.recycleFlowRate
                        : convertUptoDigits(UFData.recycleFlowRate)
                    }
                    onChange={handleInputChange}
                    onFocus={() => handleFocus("recycleFlowRate")}
                    isFocused={isFocused === "recycleFlowRate"}
                    onBlur={handleBlur}
                    tabIndex={9}
                  />
                  <InputRanges ranges={validations.recycleFlowRate} />
                </div>
                <div className='flux-and-flow-wrapper'>
                  <CustomLabel
                    disabled={UFData.ufSpecialFeatureID == 1}
                    label={ufTechnologyStrings.miniCIPRecycleFlowRate}
                  />
                  <InputWithText
                    type='number'
                    inputText={unit.selectedUnits[1] + "/module"}
                    id='recycleFlowRate_MiniCIPInput'
                    name='recycleFlowRate_MiniCIP'
                    disabled={UFData.ufSpecialFeatureID == 1}
                    isError={isRangeError("recycleFlowRate_MiniCIP")}
                    placeholder='0.00'
                    value={getValue("recycleFlowRate_MiniCIP")}
                    onChange={handleInputChange}
                    onFocus={() => handleFocus("recycleFlowRate_MiniCIP")}
                    isFocused={isFocused === "recycleFlowRate_MiniCIP"}
                    onBlur={handleBlur}
                    tabIndex={10}
                  />
                </div>
                <InputRanges hide={UFData.ufSpecialFeatureID == 1} ranges={validations.recycleFlowRate_MiniCIP} />
              </StyledCard>
            </div>
            <div className='card-wrapper-three'>
              {/* Pending Special Features-------------- */}
              <StyledCard className='uf-special-feature' borderRadius='4px'>
                <Card.Header>
                  <CustomHeading
                    fontFamily='NotoSansRegular'
                    fontSize='14px'
                    fontWeight='400'
                    color={colors.PrimaryDarkAquaMarine}
                    label={ufTechnologyStrings.uFSpecialFeatures}
                  />
                  <IconWithTooltip
                    label='Adjust feed water chemistry, decide if regular CEB or mini-CIP cleaning protocols will be used.'
                    icon={<InfoIcon />}
                  />
                </Card.Header>
                <div>
                  <StandardLinkButtonWithIcon
                    label={ufTechnologyStrings.addChemicalDegas}
                    id='addChemicalBtn'
                    icon={<PlusIcon style={{ cursor: "notAllowed" }} />}
                    plusIcon={true}
                    padding='10px 0px'
                    onClick={handleOpenChemicalAdjustment}
                  />
                  {openChemicalAdjustment && <ChemicalAdjustmentModal close={() => setOpenChemicalAdjustment(false)} />}
                </div>
                <div className='uf_special_feature_radio'>
                  <CustomRadioCheck
                    type='radio'
                    name='ufSpecialFeatureID'
                    id='cebRadio'
                    label={ufTechnologyStrings.cEBOnly}
                    checked={UFData.ufSpecialFeatureID == 1}
                    value={1}
                    onChange={handleInputChange}
                  />
                  <CustomRadioCheck
                    type='radio'
                    name='ufSpecialFeatureID'
                    id='cipRadio'
                    label={ufTechnologyStrings.miniCIPOnly}
                    disabled={isIngeSelected(technologyID)}
                    checked={UFData.ufSpecialFeatureID == 2}
                    value={2}
                    onChange={handleInputChange}
                  />
                  <CustomRadioCheck
                    type='radio'
                    name='ufSpecialFeatureID'
                    id='cebCipRadio'
                    label={ufTechnologyStrings.CEBAndMiniCIP}
                    disabled={isIngeSelected(technologyID)}
                    onChange={handleInputChange}
                    value={3}
                    checked={UFData.ufSpecialFeatureID == 3}
                  />
                </div>
              </StyledCard>
              <StyledCard className='design-cycle-interval' borderRadius='4px'>
                <Card.Header>
                  <CustomHeading
                    fontFamily='NotoSansRegular'
                    color={colors.PrimaryDarkAquaMarine}
                    label={ufTechnologyStrings.designCycleInterval}
                  />
                  <IconWithTooltip
                    label='Provide the nominal interval between each cleaning protocol.'
                    icon={<InfoIcon />}
                  />
                </Card.Header>

                <div className='design_cycle_interval'>
                  <div className='design-filtration-left'>
                    <div className='filtration-duration'>
                      <CustomLabel
                        disabled={DISABLED_INPUTS.includes(ufTechnologyStrings.filtrationDuration)}
                        label={ufTechnologyStrings.filtrationDuration}
                      />
                      <InputWithText
                        type='number'
                        inputText={validations["backwash_design"]?.uom || "Min"}
                        id='filtrationDurationInput'
                        name='backwash_design'
                        disabled={DISABLED_INPUTS.includes(ufTechnologyStrings.filtrationDuration)}
                        isError={isRangeError("backwash_design")}
                        placeholder='0.00'
                        value={UFData.backwash_design}
                        onChange={handleInputChange}
                        onFocus={() => handleFocus("backwash_design")}
                        isFocused={isFocused === "backwash_design"}
                        onBlur={handleBlur}
                        tabIndex={11}
                      />
                      <InputRanges ranges={validations.backwash_design} />
                    </div>
                    <div className='cip'>
                      <CustomLabel disabled={DISABLED_INPUTS.includes("CIP")} label='CIP' />
                      <InputWithText
                        type='number'
                        inputText={validations["cIP"]?.uom}
                        id='cIPInput'
                        name='cIP'
                        disabled={DISABLED_INPUTS.includes("CIP")}
                        isError={isRangeError("cIP")}
                        placeholder='0.00'
                        value={UFData.cIP}
                        onChange={handleInputChange}
                        onFocus={() => handleFocus("cIP")}
                        isFocused={isFocused === "cIP"}
                        onBlur={handleBlur}
                        tabIndex={13}
                      />
                      <InputRanges ranges={validations.cIP} />
                    </div>
                    <div className='mini-cip'>
                      <CustomLabel
                        disabled={DISABLED_INPUTS.includes(ufTechnologyStrings.miniCIP)}
                        label={ufTechnologyStrings.miniCip}
                      />
                      <InputWithText
                        type='number'
                        inputText={validations["miniCIP"]?.uom}
                        id='miniCIPInput'
                        name='miniCIP'
                        disabled={UFData.ufSpecialFeatureID == 1 || isIngeSelected(technologyID)}
                        isError={isRangeError("miniCIP")}
                        placeholder='0.00'
                        value={UFData.miniCIP}
                        onChange={handleInputChange}
                        onFocus={() => handleFocus("miniCIP")}
                        isFocused={isFocused === "miniCIP"}
                        onBlur={handleBlur}
                        tabIndex={15}
                      />
                      {!(UFData.ufSpecialFeatureID == 1 || isIngeSelected(technologyID)) && (
                        <InputRanges ranges={validations.miniCIP} />
                      )}
                    </div>
                  </div>
                  <div className='design-filtration-right'>
                    <div className='acid-ceb'>
                      <CustomLabel
                        disabled={DISABLED_INPUTS.includes(ufTechnologyStrings.acidCEB)}
                        label={ufTechnologyStrings.acidCEB}
                      />
                      <InputWithText
                        type='number'
                        inputText={validations["acidCEB"]?.uom}
                        id='acidCEBInput'
                        name='acidCEB'
                        disabled={isMiniCIPOnly || DISABLED_INPUTS.includes(ufTechnologyStrings.acidCEB)}
                        isError={isRangeError("acidCEB")}
                        placeholder='0.00'
                        value={UFData.acidCEB}
                        onChange={handleInputChange}
                        onFocus={() => handleFocus("acidCEB")}
                        isFocused={isFocused === "acidCEB"}
                        onBlur={handleBlur}
                        tabIndex={12}
                      />
                      <InputRanges ranges={validations.acidCEB} />
                    </div>
                    <div className='alkaline-ceb'>
                      <CustomLabel
                        disabled={DISABLED_INPUTS.includes("Alkali/Oxidant CEB")}
                        label='Alkali/Oxidant CEB'
                      />
                      <InputWithText
                        type='number'
                        inputText={validations.alkaliOxidantCEB?.uom}
                        id='alkaliOxidantCEBInput'
                        name='alkaliOxidantCEB'
                        disabled={isMiniCIPOnly || DISABLED_INPUTS.includes("Alkali/Oxidant CEB")}
                        isError={isRangeError("alkaliOxidantCEB")}
                        placeholder='0.00'
                        value={UFData.alkaliOxidantCEB}
                        onChange={handleInputChange}
                        onFocus={() => handleFocus("alkaliOxidantCEB")}
                        isFocused={isFocused === "alkaliOxidantCEB"}
                        onBlur={handleBlur}
                        tabIndex={14}
                      />
                      <InputRanges ranges={validations.alkaliOxidantCEB} />
                    </div>
                    <div className='disinfection-ceb'>
                      <CustomLabel
                        disabled={DISABLED_INPUTS.includes(ufTechnologyStrings.disinfectionCEB)}
                        label={ufTechnologyStrings.disinfectionCEB}
                      />
                      <InputWithText
                        type='number'
                        inputText={validations.disinfectionCEB?.uom}
                        id='disinfectionCEBInput'
                        name='disinfectionCEB'
                        disabled={DISABLED_INPUTS.includes(ufTechnologyStrings.disinfectionCEB)}
                        isError={isRangeError("disinfectionCEB")}
                        placeholder='0.00'
                        value={UFData.disinfectionCEB}
                        onChange={handleInputChange}
                        onFocus={() => handleFocus("disinfectionCEB")}
                        isFocused={isFocused === "disinfectionCEB"}
                        onBlur={handleBlur}
                        tabIndex={16}
                      />
                      <InputRanges
                        ranges={validations.disinfectionCEB}
                        hide={DISABLED_INPUTS.includes(ufTechnologyStrings.disinfectionCEB)}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <CustomHeading
                    className='membrane-heading'
                    fontFamily='NotoSansRegular'
                    color={colors.PrimaryDarkAquaMarine}
                    label={ufTechnologyStrings.membraneIntegrityTesting}
                  />
                  <div className='cip'>
                    <CustomLabel
                      disabled={DISABLED_INPUTS.includes(ufTechnologyStrings.membraneIntegrityTesting)}
                      label={ufTechnologyStrings.offlineTimeperUnit}
                    />

                    <InputWithText
                      type='text'
                      inputText={FIELD_OFFLINE_TIME_PER_TRAIN?.uom}
                      id='offlinetimepertrain'
                      name='offlinetimepertrain'
                      disabled={DISABLED_INPUTS.includes(ufTechnologyStrings.membraneIntegrityTesting)}
                      isError={checkError("offlinetimepertrain")}
                      placeholder='0.00'
                      value={UFData.offlinetimepertrain}
                      onChange={handelOfflineTrain}
                      onFocus={() => handleFocus("offlinetimepertrain")}
                      isFocused={isFocused === "offlinetimepertrain"}
                      onBlur={handleBlur}
                      tabIndex={17}
                    />
                    {getError("offlinetimepertrain")}
                  </div>
                </div>
              </StyledCard>
            </div>
          </div>
        </Col>
      </DesignStyled>
      {isFieldValid && (
        <ProjectErrorPopup
          show={isFieldValid}
          close={() => {
            setIsFieldValid(false);
          }}
          message={message}
        />
      )}
    </>
  );
};

export default Design;
