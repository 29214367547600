import React from "react";
import { useDispatch, useSelector } from "react-redux";

import WPAccordion from "@components/WPAccordion";

import { setReportSectionData } from "@common/ReportSlice";
import CustomRadioCheck from "@common/styles/components/checkboxs/CustomRadioCheck";

import { reportSectionLabels } from "./WPReportSideNavFilter.constants";

import "./WPReportSideNavFilter.scss";

export const WPReportSideNavFilter = ({ id }) => {
  const dispatch = useDispatch();
  const reportSectionData = useSelector(state => state.report.reportSectionData);

  const currentSelectedList = reportSectionData?.[id] || {};

  const { name: sectionName, subLabels: sectionSubLabels } = reportSectionLabels[id];

  const handleSelectAllChange = event => {
    const isChecked = event.target.checked;
    const selectedList = Object.keys(sectionSubLabels).reduce((acc, labelKey) => {
      acc[labelKey] = isChecked;
      return acc;
    }, {});
    dispatch(setReportSectionData({ id, data: selectedList }));
  };

  const handleCheckboxChange = event => {
    const { name, checked } = event.target;
    const updatedSelectedList = { ...currentSelectedList, [name]: checked };
    dispatch(setReportSectionData({ id, data: updatedSelectedList }));
  };

  const allSelected = Object.values(currentSelectedList).every(value => value);

  const getTitle = () => (
    <CustomRadioCheck
      type='checkbox'
      checked={allSelected}
      label={sectionName}
      onChange={handleSelectAllChange}
      onClick={e => {
        e.stopPropagation();
      }}
    />
  );

  return (
    <div>
      <WPAccordion title={getTitle()} defaultOpen>
        <div className='wp-report-sidenav-accordion-content'>
          {Object.entries(sectionSubLabels).map(([key], index) => (
            <CustomRadioCheck
              type='checkbox'
              key={index}
              name={key}
              checked={currentSelectedList[key] || false}
              label={sectionSubLabels[key]}
              onChange={handleCheckboxChange}
            />
          ))}
        </div>
      </WPAccordion>
    </div>
  );
};
