import { useCallback, useMemo, useState } from "react";

import DupontLogger from "@utils/DupontLogger";

import CustomModal from "@components/CustomModal";
import WPButton from "@components/WPButton";

import { PROJECT_COST_FOOTER_BUTTONS } from "../chemicalLibrary.constants";
import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";
import useChemicalLibrary from "../ChemicalLIbraryHooks/useChemicalLibrary";
import ProjectCostTable from "../ProjectCostTable";

import "./index.scss";

const ProjectCostDialog = ({ onClose }) => {
    const Logger = DupontLogger("ProjectCostDialog");
    const { loaderText, chemicals, updateProjectCosts } = useChemicalLibrary();
    const [updatedChemicals, setUpdatedChemicals] = useState([]);

    const handleBtnClick = useCallback(({ target }) => {
        switch (target.id) {
            case "makeDefaultPriceBtn":
                Logger.log("Making current price the default");
                break;
            case "addEditChemicalBtn":
                Logger.log("Adding or editing chemicals");
                break;
            case "saveUpdatedChemicals":
                updateProjectCosts(updatedChemicals);
                setUpdatedChemicals([]);
                break;
            default:
                Logger.error("Unknown button clicked", target.id);
        }
    }, [updatedChemicals, updateProjectCosts]);

    const modalFooter = useMemo(() => PROJECT_COST_FOOTER_BUTTONS.map(btnConfig => {
        const isDisabled = btnConfig.id === "saveUpdatedChemicals" && !updatedChemicals.length;
        return (
            <WPButton
                key={btnConfig.id}
                variant={btnConfig.variant}
                id={btnConfig.id}
                onClick={handleBtnClick}
                disabled={isDisabled}
            >
                {btnConfig.label}
            </WPButton>
        );
    }), [handleBtnClick, updatedChemicals]);

    const onChemicalUpdate = useCallback(updatedChemical => {
        setUpdatedChemicals(prevChemicals => {
            const index = prevChemicals.findIndex(chem => chem.iD === updatedChemical.iD);
            if (index !== -1) {
                const newChemicals = [...prevChemicals];
                newChemicals[index] = updatedChemical;
                return newChemicals;
            }
            return [...prevChemicals, updatedChemical];
        });
    }, []);

    return (
        <CustomModal
            header={CHEMICALS_LIBRARY_STRINGS.projectCostDlgHeading}
            headerHelper={CHEMICALS_LIBRARY_STRINGS.projectCostDlgSubHeading}
            onModalClose={onClose}
            width="1024px"
            customclass="checmical--dialog-container"
            isLoading={Boolean(loaderText)}
            loadingText={loaderText}
            modalFooter={modalFooter}
        >
            <ProjectCostTable chemicals={chemicals} onChemicalUpdate={onChemicalUpdate} />
        </CustomModal>
    );
};

export default ProjectCostDialog;