export const reportStrings = {
  ufFlowDetails: "UF Flow Details",
  feed: "Feed",
  product: "Product",
  source: "Source",
  tempDesign: "Temperature : Design",
  turbidity: "Turbidity",
  organicsTOC: "Organics (TOC)",
  tss: "TSS",
  tds: "TDS",
  ph: "pH",
  duration: "Duration",
  flux: "Flux",
  flow: "Flow",
  interval: "Interval",
  filtration: "Filtration",
  instantaneous: "Instantaneous",
  trains: "trains",
  average: "Average",
  net: "Net",
  backwash: "Backwash",
  acidCEB: "Acid CEB",
  alkaliCEB: "Alkali CEB",
  disinfectionCEB: "Disinfection CEB",
  miniCip: "mini-CIP",
  cip: "CIP",
  membraneIntegrationTesting: "Membrane Integration Testing",
  bw: "BW",
  cebDisinf: "CEB (disinf.)",
  cipSls: "CIP SLS",
  miniCipSls: "mini-CIP SLS",
  peakFlow: "Peak Flow",
  averageFlow: "Average Flow",
  FeedGross: "Feed (Gross)",
  FeedUserFor: "Feed water used for",
  pretreatment: "Pretreatment",
  forwardFlushProcessStreams: "Forward Flush & Process streams",
  feedNet: "Feed (Net)",
  filtrateGross: "Filtrate (Gross)",
  filtrateUsedForCleaning: "Filtrate used for cleaning",
  filtrateNet: "Filtrate (Net)",
  air: "Air",
  air2: "Air 2",
  backwashBW: "Backwash (BW)",
  forwardFlush: "Forward Flush",
  ceb1Acid: "CEB 1 (Acid)",
  ceb2AlkaliOxidant: "CEB 2 (Alkali and/or Oxidant)",
  ceb3Disinfection: "CEB 3 (Disinfection)",
  miniCipRecycle: "mini-CIP Recycle",
  cipRecycle: "CIP Recycle",
  footNotes: "Foot Notes",
  remarks: "Remarks",
  maxPossibleFlowRate: "Maximum possible flow rate",
  ceb: "CEB",
  ufOperatingConditions: "UF Operating Conditions",
  UfWaterQuality: "UF Water Quality",
  standbyOption: "Standby Option",
  standbyTankOption: "Standby Tank Option",
  forwardFlushWaterSource: "Forward Flush Water Source",
  backwashWaterSource: "Backwash Water Source",
  cebWaterSource: "CEB Water Source",
  miniCipWaterSource: "mini-CIP Water Source",
  cipWaterSource: "CIP Water Source",
  ufConfigurationOptions: "UF Configuration Options",
  ufSystemOverview: "UF System Overview",
  ufDesignWarnings: "UF Design Warnings",
  systemDiagram: "System Diagram",
  ultraFiltration: "Ultrafiltration",
  sideNavSelectMsg: "You may select checkboxes below to view the details of a specific parameter on the right side.",
  downloadingReportMsg: "Downloading Report",
  ufSummaryReport: "Ultrafiltration Summary Report",
  ufPressureRatings: "UF Pressure Ratings",
  process: "Process",
  tmp: "TMP",
  ok: "OK",
  foulingMaxDp: "Fouling Max DP",
  pipingDp: "Piping DP",
  filtratePressure: "Filtrate Pressure",
  feedPressure: "Feed Pressure",
  pressureRating: "Pressure Rating",
  t: "T",
  minTemp: "Minimum Temp.",
  designTemp: "Design Temp.",
  maxTemp: "Maximum Temp.",
  pressureRatingsTableFootNotes1: "At Actual, average flux",
  pressureRatingsTableFootNotes2:
    "Sum of TMP, fouling ∆P, piping ∆P and filtrate pressure. Does not include pressure drop at the strainer. Pressure drops are based on user inputs. Default values should not be used for pump sizing",
  pressureRatingsTableFootNotes3:
    "Comparison of Feed pressure to Pressure rating--a conservative comparison due to piping losses between the feed pump and module inlet.",
  ufPumpHydraulicElectricalCost: "UF Pump Hydraulics and Electrical Cost",
  meteringPump: "Metering Pump",
  pump: "Pump",
  cipSolutionHeating: "CIP Solution Heating",
  airCompressorScour: "Air Compressor (Scour)",
  airCompressorAdbw: "Air Compressor (ADBW)",
  electricalValves: "Electrical Valves",
  plcAndInstrumentation: "PLC and Instrumentation",
  totalElectricalCost: "Total Electrical Cost",
  peakFlowRate: "Peak Flowrate",
  avgPressure: "Average Pressure",
  mechanicalPower: "Mechanical Power",
  electricalPower: "Electrical Power",
  energy: "Energy",
  cost: "Cost",
  ufStorageTanks: "UF Storage Tanks",
  storageTanksTableFootNotes1: "Storage Tank sized to maintain constant net filtrate flow",
  storageTanksTableFootNotes2:
    "The mimimum recommended volume for chemical storage tanks is sized for 30 days of storage.",
  name: "Name",
  bulkConc: "Bulk Conc.",
  minRecommVolume: "Minimum Recommended Volume",
  water: "Water",
  cipTank: "CIP Tank",
  miniCipTank: "mini-CIP Tank",
  chemicalStorage: "Chemical Storage",
  ufSystemSizeModuleDetails: "UF System Size and Module Details",
  moduleDetails: "Module Details",
  units: "Units",
  onlineUnits: "Online Units",
  standbyUnits: "Standby Units",
  redundantUnits: "Redundant Units",
  totalUnits: "Total Units",
  maxOfflineUnits: "Max Offline Units",
  modulePerUnit: "Modules per Unit",
  totalModules: "Total Modules",
  area: "Area",
  length: "Length",
  diameter: "Diameter",
  weightEmpty: "Weight (empty)",
  weightFilled: "Weight (Water Filled)",
  waterVolume: "Water Volume",
  moduleType: "Module Type",
  chemicals: "Chemicals",
  unitCost: "Unit Cost",
  dose: "Dose",
  volume: "Volume",
  numUnits: "# Units",
  modules: "# Modules",
  systemFlowRate: "System Flow Rate",
  trainFlowRate: "Train Flow Rate",
  ufSystemRecovery: "UF System Recovery",
  utilityWater: "Utility Water",
  online: "Online",
  standBy: "Standby",
  redundant: "Redundant",
  perUnit: "Per Unit",
  total: "Total",
  grossFeed: "Gross Feed",
  netProduct: "Net Product",
  ufUtilityChemicalCosts: "UF Utility and Chemical Costs",
  ceb1: "CEB1",
  ceb2: "CEB2",
  ceb3: "CEB3",
  totalChemicalCost: "Total Chemical Cost",
};
