import CHEMICALS_LIBRARY_STRINGS from "./chemicalLibrary.strings";

const { UNITS } = require("@constants/units.constant");

export const CHEMICAL_UNIT_TYPES = [UNITS.gallon, UNITS.pound, UNITS.kiloGram, UNITS.liter];
export const PROJECT_COST_FOOTER_BUTTONS = [
  {
    id: "makeDefaultPriceBtn",
    variant: "text",
    label: CHEMICALS_LIBRARY_STRINGS.makePriceNewDefault,
  },
  {
    id: "addEditChemicalBtn",
    variant: "text",
    label: CHEMICALS_LIBRARY_STRINGS.addEditChemicals,
  },
  {
    id: "saveUpdatedChemicals",
    variant: "contained",
    label: CHEMICALS_LIBRARY_STRINGS.saveChanges,
  },
];
