const DownloadIcon = () => (
  <>
    <svg width='24' height='24' viewBox='0 0 36 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.7466 24.609C17.7766 24.6474 17.815 24.6784 17.8589 24.6998C17.9028 24.7212 17.9509 24.7323 17.9997 24.7323C18.0485 24.7323 18.0966 24.7212 18.1405 24.6998C18.1843 24.6784 18.2227 24.6474 18.2528 24.609L22.7528 18.9157C22.9175 18.7067 22.7689 18.3974 22.4997 18.3974H19.5225V4.80092C19.5225 4.62414 19.3778 4.47949 19.201 4.47949H16.7903C16.6135 4.47949 16.4689 4.62414 16.4689 4.80092V18.3933H13.4997C13.2305 18.3933 13.0818 18.7027 13.2466 18.9116L17.7466 24.609ZM32.705 23.2027H30.2943C30.1175 23.2027 29.9729 23.3474 29.9729 23.5241V29.7116H6.02647V23.5241C6.02647 23.3474 5.88183 23.2027 5.70504 23.2027H3.29433C3.11754 23.2027 2.9729 23.3474 2.9729 23.5241V31.4795C2.9729 32.1907 3.54745 32.7652 4.25861 32.7652H31.7408C32.4519 32.7652 33.0265 32.1907 33.0265 31.4795V23.5241C33.0265 23.3474 32.8818 23.2027 32.705 23.2027Z'
        fill='black'
        fillOpacity='0.85'
      />
    </svg>
  </>
);

export default DownloadIcon;
