import React from "react";

import InputWithText from "@common/styles/components/inputs/InputWithText";

const ChemicalProjectCostRenderer = ({ data, context }) => {
    const handleInputBlur = ({ target }) => {
        const { value: bulkPrice } = target;
        if (context.onDataUpdate) {
            context.onDataUpdate({ ...data, bulkPrice });
        }
    };
    return <div className="d-flex align-content-center">
        <InputWithText id={`checmical_cost_input_${data.iD}`} inputText={"$"} value={data.bulkPrice} onBlur={handleInputBlur} />
    </div>;
};

export default ChemicalProjectCostRenderer;
