import React from "react";

import WPHeading from "@components/WPHeading";

const ChemicalDisplayNameRenderer = ({ value }) => (
  <WPHeading type="default" size="small" className="text-overflow-ellipsis">
    <span>{value}</span>
  </WPHeading>
);

export default ChemicalDisplayNameRenderer;