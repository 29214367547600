import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfWaterQualityTableColumns as columns } from "./WPUfWaterQualityTable.constants";

export const WPUfWaterQualityTable = () => {
  const { uf_water_quality: rows } = useSelector(state => state.report.ufReportTableData);

  return <WPTable sectionHeader={reportStrings.UfWaterQuality} columns={columns} rows={rows} />;
};
