export const APP_TOAST_NOTIFICATION = {
  //=================== SUCCESS MESSAGES ==================================
  PROJECT_SAVED: "Changes saved",
  PROJECT_DELETED: "Project deleted successfully",
  PROJECT_RESTORED: "Project restored successfully",
  FOLDER_CREATED: "Folder created successfully",
  FOLDER_DELETED: "Folder deleted successfully",
  FOLDER_RESTORED: "Folder restored successfully",
  PROJECT_EXPORTED: "Project exported successfully",
  PROJECT_DUPLICATED: "Project duplicated successfully",
  REPORT_DOWNLOADED: "Report downloaded successfully",
  WATER_LIB_SAVED: "Water library saved successfully",
  WATER_LIB_REPLACED: "Water library replaced successfully",
  //=================== FAILED MESSAGES ==================================
  PROJECT_EXPORT_FAILED: "Unable to export project. Please try again",
  WATER_LIB_GET_FAILED: "Unable to get library data. Please try again",
  FOLDER_DELETE_FAILED: "Unable to delete the folder. Please try again",
  WATER_LIB_SAVE_FAILED: "Unable to save library data. Please try again",
  PROJECT_DUPLICATE_FAILED: "Unable to duplicate project. Please try again",
  REPORT_DOWNLOAD_FAILED: "Unable to download PDF report. Please try again.",
  WATER_LIB_DELETE_FAILED: "Unable to delete library data. Please try again",
  COPY_TO_FEED_WATER_FAILED: "Unable to copy to feed water. Please try again",
  WATER_LIB_REPLACE_FAILED: "Unable to replace library data. Please try again",
};
