import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  systemDiagramBase64: null,
  ufCalcReportApiResp: {
    isLoading: false,
    data: null,
    error: null,
  },
  ufReportTableData: {},
  reportSectionData: {
    // default values for all uf section
    ufSectionData: {
      uf_system_overview: true,
      uf_operating_conditions: true,
      uf_water_quality: true,
      uf_design_quality: true,
    },
  },
};

export const ReportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setSystemDiagramBase64: (state, action) => {
      state.systemDiagramBase64 = action.payload;
    },
    setUfCalcReportApiResp: (state, action) => {
      state.ufCalcReportApiResp = { ...state.ufCalcReportApiResp, ...action.payload };
    },
    setUfReportTableData: (state, action) => {
      state.ufReportTableData = { ...state.ufReportTableData, ...action.payload };
    },
    setReportSectionData: (state, action) => {
      const { id, data } = action.payload;
      state.reportSectionData[id] = {
        ...state.reportSectionData[id],
        ...data,
      };
    },
  },
});

export const { setSystemDiagramBase64, setUfCalcReportApiResp, setUfReportTableData, setReportSectionData } =
  ReportSlice.actions;

export default ReportSlice.reducer;
