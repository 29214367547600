import { PERCENTAGE_RANGE } from "@constants/global.constants";

import UnitConversion from "@common/utils/UnitConversion";

import {
  AMBERLITE_62_I_ID,
  REGENERATION_RATIO,
  REGENERATION_RATIO_RANGE,
  RESIN_ID,
  SAC_SAFETY_FACTOR,
  SAFETY_FACTOR_RANGE,
  SBA_SAFETY_FACTOR,
  SPECIES_UNIT_MICROGRAM_LITRE,
  TECH_IXD,
  VESSEL_ID,
  WAC_OVERRUN,
  WAC_SAFETY_FACTOR,
  WBA_OVERRUN,
  WBA_SAFETY_FACTOR,
} from "../constants/IXDConstants";

export const regenerationDefaultValue = temp => {
  const value = {
    regenerantID: 0,
    temperature: temp || 25,
    temperatureID: 0,
    step1_ind: true,
    step2_ind: false,
    step3_ind: false,
    step1Con: 0,
    step2Con: 0,
    step3Con: 0,
    step1DosFrac: 0,
    step2DosFrac: 0,
    step3DosFrac: 0,
    serviceWater: null,
    backwash: null,
    chemicalID: "",
  };
  return [value, value];
};

export const createProductQualityObject = (defaultValues, advRegen) => ({
  productQualityDoseID: 0,
  resinRegenerationID: 0,
  speciesLblNameID: 0,
  averageSpeciesVal: defaultValues.averageSpeciesVal,
  endpoingSpeciesVal: defaultValues.endpoingSpeciesVal,
  averageConductivityVal: defaultValues.averageConductivityVal,
  endpointConductivityVal: defaultValues.endpointConductivityVal,
  speciesTwoLblNameID: 0,
  regenerantDoseLbl1ID: 0,
  regeneratDoseVal1: 0,
  regenerantDoseLbl2ID: 0,
  regenerantDoseLbl4ID: 0,
  regenerantDoseVal2: 0,
  regenerantDoseVal3: 0,
  regenerantDoseVal4: advRegen?.typicalValue?.regenerantDoseTypical || 0,
  overAllEfficiency: 0,
  overAllComputation: 0,
  doseOptimization: 0,
  naturalEffect: 0,
  saftyFactorLbl: 0,
  saftyFactorVal: 0,
  speciesUnit: 1,
  volume: null,
  flowRate: null,
  time: null,
  regenerationRatio: advRegen?.typicalValue?.regenerantRatioTypical || 0,
});

export const updateResinFlags = (cationResin, anionResin, vessel1, vessel2, vessel3, vessel4, inert1, inert2) => {
  const resinFlags = { flag1: false, flag2: false, flag3: false, flag4: false };

  const isFourOrFive = value => value === VESSEL_ID.AIR_BLOCK_ID || value === VESSEL_ID.WATER_BLOCK_ID;
  const isCationResin = cationResin === RESIN_ID.WAC_SAC_TWO_VESSEL_RESIN_ID;
  const isAnionResin = anionResin === RESIN_ID.WBA_SBA_TWO_VESSEL_RESIN_ID;
  const isInertNone = inert => inert === RESIN_ID.INERT_NONE_ID;

  // Check conditions for cation resin
  if (isCationResin && isInertNone(inert1)) {
    resinFlags.flag1 = isFourOrFive(vessel1);
    resinFlags.flag2 = isFourOrFive(vessel2);
  } else if (isInertNone(inert1)) {
    resinFlags.flag1 = isFourOrFive(vessel1);
  }
  // Check conditions for anion resin
  if (isAnionResin) {
    if (isCationResin && isInertNone(inert2)) {
      resinFlags.flag3 = isFourOrFive(vessel3);
      resinFlags.flag4 = isFourOrFive(vessel4);
    } else if (isInertNone(inert2) && !isCationResin) {
      resinFlags.flag2 = isFourOrFive(vessel2);
      resinFlags.flag3 = isFourOrFive(vessel3);
    }
  } else {
    if (isInertNone(inert2) && !isCationResin) {
      resinFlags.flag2 = isFourOrFive(vessel2);
    } else if (isInertNone(inert2) && isCationResin) {
      resinFlags.flag3 = isFourOrFive(vessel3);
    }
  }

  return resinFlags;
};

/**
 * Determines if the inert bed height calculation is required based on the selected vessel and inert presence.
 * It evaluates the conditions based on the vessel number and the data provided, including the specific values
 * in the data object that indicate the presence of inert material.
 * This function specifically checks for the following vessels to determine if an inert calculation is required:
 * - COCURRENT_ID: Represents a cocurrent flow vessel.
 * - AMBERPACK_ID: Represents an Amberpack vessel.
 * - MB_EXTERNAL_ID: Represents a mixed bed with external regeneration.
 * - MB_INTERNAL_ID: Represents a mixed bed with internal regeneration.
 * @param {Object} data - The data object containing vessel and inert information.
 * @param {number} vesselNo - The number of the vessel being evaluated.
 * @returns {boolean} - True if the inert bed height calculation is required, false otherwise.
 */

const vesselTypesRequiringCalculation = [
  VESSEL_ID.COCURRENT_ID,
  VESSEL_ID.AMBERPACK_ID,
  VESSEL_ID.MB_EXTERNAL_ID,
  VESSEL_ID.MB_INTERNAL_ID,
];
export const isInertCalculationRequired = (data, vesselNo) => {
  const myArray = [data.v4, data.v3, data.v2, data.v1].filter(Boolean);
  const vesselType = data[`v${vesselNo}`];
  if (myArray.length == 2) {
    return vesselTypesRequiringCalculation.includes(vesselType) ? data[`inert${vesselNo}`] != 0 : true;
  } else if (myArray.length == 4) {
    const inertNumber = vesselNo <= 2 ? 1 : 2;
    return vesselTypesRequiringCalculation.includes(vesselType) ? data[`inert${inertNumber}`] != 0 : true;
  } else {
    if (data.cation == 5) {
      const inertNumber = vesselNo <= 2 ? 1 : 2;
      return vesselTypesRequiringCalculation.includes(vesselType) ? data[`inert${inertNumber}`] != 0 : true;
    } else {
      const inertNumber = vesselNo <= 1 ? 1 : 2;
      return vesselTypesRequiringCalculation.includes(vesselType) ? data[`inert${inertNumber}`] != 0 : true;
    }
  }
};

export const calculateVesselWallThickness = value => {
  let defaultWallThickness = 0;
  if (value < 1.2) {
    defaultWallThickness = 0.016;
  } else if (value < 2.0) {
    defaultWallThickness = 0.02;
  } else {
    defaultWallThickness = 0.026;
  }
  return defaultWallThickness;
};

export const calculateFreeboardMinimum = (
  ixStore,
  vessel,
  Height_BW,
  Target_Freeboard_BW,
  Resin_Height_Max,
  Target_Freeboard_PB,
) => {
  if (ixStore[vessel] != null) {
    if (
      ixStore[vessel] === VESSEL_ID.COCURRENT_ID ||
      ixStore[vessel] === VESSEL_ID.AIR_BLOCK_ID ||
      ixStore[vessel] === VESSEL_ID.WATER_BLOCK_ID ||
      ixStore[vessel] === VESSEL_ID.MB_INTERNAL_ID
    ) {
      return Height_BW * Target_Freeboard_BW;
    } else if (ixStore[vessel] === VESSEL_ID.UPCORE_ID || ixStore[vessel] === VESSEL_ID.AMBERPACK_ID) {
      return Math.max((Resin_Height_Max * Target_Freeboard_PB) / (1 - Target_Freeboard_PB), 0.05);
    } else if (ixStore[vessel] === VESSEL_ID.MB_EXTERNAL_ID) {
      return 0.05;
    }
  }
  return null;
};

// The below function parseAndFormatNumber is used to parse a string to a float and return 0 if the value is not a number
export const parseAndFormatNumber = value => {
  const number = parseFloat(value);
  return isNaN(number) ? 0 : number;
};

// The below function formatToFourDecimals is used for formatting the values up to 4 digits without rounding off
export function formatToFourDecimals(num) {
  const convertedNumber = Number(num);
  const [integerPart, decimalPart = ""] = convertedNumber.toString().split(".");
  return `${integerPart}.${(decimalPart + "0000").slice(0, 4)}`;
}

// This function is used globally for FPA screen unit conversion and returning the whole list of items with converted values.
export function convertAndFormatProperties(item, propertiesToConvert, convertUnitNumber, fixedDigit = 0) {
  const updatedItem = { ...item };
  propertiesToConvert.forEach(({ key, toUnit, fromUnit }) => {
    const convertedValue = convertUnitNumber(item[key], toUnit, fromUnit, fixedDigit);
    updatedItem[key] = formatToFourDecimals(convertedValue);
  });

  return updatedItem;
}

export function getSwellingValue(data, key) {
  return data && data[key] !== 0 ? data[key] : 1;
}

export const getRangesInCurrentUnit = (ranges, selectedUnit) => ({
  min: UnitConversion(TECH_IXD, ranges.min, selectedUnit, SPECIES_UNIT_MICROGRAM_LITRE).toFixed(2),
  max: UnitConversion(TECH_IXD, ranges.max, selectedUnit, SPECIES_UNIT_MICROGRAM_LITRE).toFixed(2),
});

export const isAvgEndPointValuesOutOfRange = (value, rangeType, unit) => {
  const range = getRangesInCurrentUnit(rangeType, unit);
  const parsedValue = parseFloat(value);
  return isNaN(parsedValue) || parsedValue < range.min || parsedValue > range.max;
};

/**
 * The isInertVessel function is used for the design to determine if it is using inert or not.
 *
 * i. UPCORE: required inert.
 * ii. Water Block, Air block: required; if none selected, it turns into a flag for using excess regular resin, so inert is required.
 * iii. MB internal or external: optional, none selected means none used, so inert is not required; otherwise, inert is required.
 * iv. Amberpack: optional, none selected means none used, so inert is not required; otherwise, inert is required.
 * v. Cocurrent: not required.
 */
export const isInertVessel = (inert1, inert2, vessel1, vessel2, vessel3, vessel4) => {
  const vessels = [vessel1, vessel2, vessel3, vessel4];
  return (
    (inert1 !== null || inert2 !== null) &&
    (vessels.includes(VESSEL_ID.WATER_BLOCK_ID) ||
      vessels.includes(VESSEL_ID.AIR_BLOCK_ID) ||
      Boolean(inert1) ||
      Boolean(inert2))
  );
};

export const isValueOutOfRange = (value, labelValue) => {
  if (isNaN(value)) {
    return true;
  }
  const overrunLabels = [WAC_OVERRUN, WBA_OVERRUN];
  const safetyFactorLabels = [SAC_SAFETY_FACTOR, WAC_SAFETY_FACTOR, SBA_SAFETY_FACTOR, WBA_SAFETY_FACTOR];
  if (overrunLabels.includes(labelValue)) {
    return value < PERCENTAGE_RANGE.min || value > PERCENTAGE_RANGE.max;
  } else if (labelValue === REGENERATION_RATIO) {
    return value < REGENERATION_RATIO_RANGE.min || value > REGENERATION_RATIO_RANGE.max;
  } else if (safetyFactorLabels.includes(labelValue)) {
    return value < SAFETY_FACTOR_RANGE.min || value > SAFETY_FACTOR_RANGE.max;
  }
  return false;
};

// Function to format a number to four decimal places(with rounding)
export function roundToFourDecimals(num) {
  return num.toFixed(4);
}

/**
 * This function evaluates the conditions for cation and anion resins to determine if the upcore
 * condition is met in the specified vessels. It returns an object with boolean properties indicating
 * whether the upcore condition is met for cation and anion resins.
 */
export function checkUpcoreSelected(cationResin, anionResin, vessel1, vessel2, vessel3, vessel4) {
  const isCationResinWAC_SAC = cationResin => cationResin === RESIN_ID.WAC_SAC_TWO_VESSEL_RESIN_ID;
  const isAnionResinWBA_SBA = anionResin => anionResin === RESIN_ID.WBA_SBA_TWO_VESSEL_RESIN_ID;
  const isVesselUpcore = vessel => vessel === VESSEL_ID.UPCORE_ID;
  const cationCondition =
    (isCationResinWAC_SAC(cationResin) && (isVesselUpcore(vessel1) || isVesselUpcore(vessel2))) ||
    (!isCationResinWAC_SAC(cationResin) && isVesselUpcore(vessel1));
  const anionCondition =
    (isAnionResinWBA_SBA(anionResin) &&
      isCationResinWAC_SAC(cationResin) &&
      (isVesselUpcore(vessel3) || isVesselUpcore(vessel4))) ||
    (!isAnionResinWBA_SBA(anionResin) && isCationResinWAC_SAC(cationResin) && isVesselUpcore(vessel3)) ||
    (isAnionResinWBA_SBA(anionResin) &&
      !isCationResinWAC_SAC(cationResin) &&
      (isVesselUpcore(vessel2) || isVesselUpcore(vessel3))) ||
    (!isAnionResinWBA_SBA(anionResin) && !isCationResinWAC_SAC(cationResin) && isVesselUpcore(vessel2));
  return {
    isCationUpcore: cationCondition,
    isAnionUpcore: anionCondition,
  };
}

//Function to get the default selected resin ID and resin name if upcore is selected.
export function getSelectedResin(ixStore, inerts, isUpcore) {
  // Determine the selected resin ID based on the provided conditions
  const selectedResinId =
    ixStore[0]?.inert ||
    (isUpcore && inerts?.listIXResins.some(item => item.ResinId === AMBERLITE_62_I_ID)
      ? AMBERLITE_62_I_ID
      : inerts?.listIXResins[0]?.ResinId);

  // Find the resin name using the selected resin ID
  const resinName = inerts?.listIXResins.find(item => item.ResinId === selectedResinId)?.ResinName;
  return { selectedResinId, resinName };
}

export const isConditionOverrunDisabled = (cationResin, anionResin, isIXMB) => {
  const {
    WAC_RESIN_ID,
    SAC_RESIN_ID,
    WBA_RESIN_ID,
    SBA_RESIN_ID,
    SBA_SAC_SINGLE_CHAMBER_RESIN_ID,
    SBA_SAC_TWO_CHAMBER_RESIN_ID,
  } = RESIN_ID || {};
  const isCationResinValid = cationResin === WAC_RESIN_ID || cationResin === SAC_RESIN_ID;
  const isAnionResinValid = anionResin === WBA_RESIN_ID || anionResin === SBA_RESIN_ID;
  const isSingleOrTwoChamberResin =
    cationResin === SBA_SAC_SINGLE_CHAMBER_RESIN_ID || cationResin === SBA_SAC_TWO_CHAMBER_RESIN_ID;

  return (isCationResinValid && isAnionResinValid) || isSingleOrTwoChamberResin || isIXMB;
};
