import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isDataFetched: false,
  isUpdateActiveModule: false,
  data: 0,
  title: "Stream 1",
  val: 0.0,
  dataisChanged: false,
  streamData: {
    lstrequestsavefeedwater: [
      {
        streams: [],
      },
    ],
  },
  waterTypes: [],
  waterSubTypes: [],
  selectedWaterSubType: "",
  waterSubTypesByCompany: {},
  isCopyToFeedWaterSuccess: false,
};
export const FeedsetupSlice = createSlice({
  name: "Feedsetupdetailsdatapanel",
  initialState,
  reducers: {
    Feedsetupdetailsdata: (state, action) => {
      state.data = action.payload;
      state.dataisChanged = false;
      state.isDataFetched = true;
    },
    updateFeedData: (state, action) => {
      state.data[0] = { ...state.data[0], ...action.payload };
    },
    updatetitle: (state, action) => {
      state.title = action.payload;
    },
    Addedvalues: (state, action) => {
      state.val = action.payload;
    },
    UpdatedStream: (state, action) => {
      state.streamData = action.payload;
    },
    detectDataChange: (state, action) => {
      state.dataisChanged = action.payload;
    },
    updateFeedSetupStoreData: (state, action) => ({ ...state, ...action.payload }),
    resetFeedSetupStoreData: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  Feedsetupdetailsdata,
  updatetitle,
  detectDataChange,
  Addedvalues,
  UpdatedStream,
  updateFeedData,
  setWaterTypes,
  setWaterSubtTypes,
  updateFeedSetupStoreData,
  resetFeedSetupStoreData,
} = FeedsetupSlice.actions;

export default FeedsetupSlice.reducer;
