import React, { useMemo, useState } from "react";

import CustomModal from "@components/CustomModal";
import IconButton from "@components/IconButton";
import { QuestionIcon } from "@components/SVGs";

import ChemicalLibraryHelp from "../ChemcialLibraryHelp";
import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";
import useChemicalLibrary from "../ChemicalLIbraryHooks/useChemicalLibrary";
import ChemicalLibraryTable from "../ChemicalLibraryTable/ChemicalLibraryTable";

import "./index.scss";

const ChemicalLibraryDialog = ({ onClose }) => {
    const { loaderText, chemicals } = useChemicalLibrary();
    const [showHelp, setShowHelp] = useState(false);

    const headerContent = useMemo(() => (
        <div>
            {CHEMICALS_LIBRARY_STRINGS.chemicalLibDlgHeading}
            <IconButton
                tooltip={CHEMICALS_LIBRARY_STRINGS.questionIconTooltip}
                className="question-icon"
                onClick={() => setShowHelp(true)}
            >
                <QuestionIcon />
            </IconButton>
        </div>
    ), []);

    const hanldeHelpDlgClose = () => {
        setShowHelp(false);
    };

    return (
        <>
            <CustomModal
                header={headerContent}
                headerHelper={CHEMICALS_LIBRARY_STRINGS.chemicalLibDlgSubHeading}
                confirmBtn={CHEMICALS_LIBRARY_STRINGS.saveChanges}
                onModalClose={onClose}
                confirmBtnDisabled
                size="lg"
                customclass="checmical--dialog-container"
                isLoading={Boolean(loaderText)}
                loadingText={loaderText}
                invisible={showHelp}
            >
                <ChemicalLibraryTable chemicals={chemicals} />
            </CustomModal>
            {showHelp && <ChemicalLibraryHelp onClose={hanldeHelpDlgClose} />}
        </>
    );
};

export default ChemicalLibraryDialog;