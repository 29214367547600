/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import useUnitConversion from "@hooks/useUnitConversion";

import { convertUptoDigits } from "@utils/appUtils";

import CustomCard from "@components/CustomCard";
import InputRanges from "@components/InputRanges";
import { typographyVariants, WPTypogrpahy } from "@components/WPTypography";

import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import InputReferenceText from "@common/styles/components/headings/InputReferenceText";
import CalcEngineInputBox from "@common/styles/components/inputs/CalcEngineInputBox";
import CustomInput from "@common/styles/components/inputs/CustomInput";
import { handleTabAndEnter } from "@common/styles/components/inputs/InputFocusUtililty";
import InputWithText from "@common/styles/components/inputs/InputWithText";
import { colors } from "@common/styles/Theme";

import ProjectErrorPopup from "../../modals/ProjectErrorPopup";
import { setUfDataUpdate, updateAdditionalData } from "../uf/UFSlice";

import { TYPICAL_PUMP_RAMP, TYPICAL_WAIT_DURATION } from "./constants/UFConstants";
import AdditionalSettingStyled from "./AdditionalSettingStyled";
import { isIngeSelected } from "./ufUtils";

const IS_DUPONT_FLOW_ONLY = true;

const AdditionalSetting = () => {
  const dispatch = useDispatch();
  const { convertUnit } = useUnitConversion();

  const [isFocused, setIsFocused] = useState(null);
  const ufStoreData = useSelector(state => state.UFStore);
  const ufStore = useSelector(state => state.UFStore.data);
  const designTemp = useSelector(
    state => state?.Feedsetupdetailsdatapanel?.streamData?.lstrequestsavefeedwater[0]?.streams[0]?.tempDesign,
  );
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const { ufInputRangeConfig, defaultInputRangeConfig } = ufStoreData;
  const UFData = useSelector(state => state.UFStore.data);
  const [errorOperator, setErrorOperator] = useState({
    show: false,
    message: "",
  });
  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = event => {
    if (event.key === "Enter" || event.key === "Tab") {
      closeErrorMessag();
      handleTabAndEnter(event);
    }
  };

  const closeErrorMessag = () => {
    setErrorOperator({
      show: false,
      message: "",
    });
  };

  const technologyID = useSelector(state => state.UFStore.data.pUFTechnologyID);

  const isIngeSelectedMemo = useMemo(() => isIngeSelected(technologyID), [technologyID]);

  // Function to filter ufInputRangeConfig array based on label
  const filterConfigByLabel = label => ufInputRangeConfig.filter(config => config.label === label);

  const fieldMaximumAirScourPressure = filterConfigByLabel("Maximum Air Scour Pressure");
  const fieldMaximumProcessAirPressure = filterConfigByLabel("Maximum ADBW Air Pressure");
  const fieldFiltratePressure = filterConfigByLabel("Filtrate Pressure");
  const fieldFiltratePipingPressure = filterConfigByLabel("Non-IntegraPac Filtration Pressure Drop");
  const fieldStrainerPressure = filterConfigByLabel("IntegraPac Filtration Pressure Drop");
  const fieldBackwashPipingPressureDrop = filterConfigByLabel("Backwash Piping Pressure Drop");
  const fieldCIPPipingPressureDrop = filterConfigByLabel("CIP Piping Pressure Drop");
  const fieldValvesPerUnit = filterConfigByLabel("Valves per train");
  const fieldTypicalWaitDurationDupont = !isIngeSelectedMemo
    ? filterConfigByLabel("Typical Wait Duration_Dupont")
    : filterConfigByLabel("Typical Wait Duration_Inge");
  const fieldTypicalPumpRampDupont = !isIngeSelectedMemo
    ? filterConfigByLabel("Typical Pump Ramp_Dupont")
    : filterConfigByLabel("Typical Pump Ramp_Inge");
  const fieldTankSizeFactorBF = filterConfigByLabel("Tank Size Factor (BW+Filtrate Tank)");
  const fieldTankSizeFactorB = filterConfigByLabel("Tank Size Factor (BW only Tank)");
  const fieldTankSizeFactorCIP = filterConfigByLabel("Tank Size Factor (CIP Tank)");
  const fieldChemicalStorageTime = filterConfigByLabel("Chemical Storage Time (days)");
  const fieldBackwashOnlyTankRefillRate = filterConfigByLabel("Backwash Only Tank Refill Rate");
  const fieldPLCPowerRequirementperTrain = filterConfigByLabel("PLC Power Requirement per Train");
  const fieldValvePowerRequirementsperValve = filterConfigByLabel("Valve Power Requirements per Valve");
  const fieldStrainerRecovery = filterConfigByLabel("Strainer Recovery");
  const fieldStrainerSize = filterConfigByLabel("Strainer Size");
  const fieldBackwashTMPIncrease = filterConfigByLabel("Backwash TMP Increase");
  const fieldAcidCEBTMPIncrease = filterConfigByLabel("Acid CEB TMP Increase");
  const fieldAlkaliCEBTMPincrease = filterConfigByLabel("Alkali CEB TMP increase");
  const fieldCIPTMPincrease = filterConfigByLabel("CIP TMP increase");
  const fieldADBWDisplacement = filterConfigByLabel("ADBW Displacement_by 8_Memcor");
  const fieldFTLDisplacement = filterConfigByLabel("FTL Displacement");
  const fieldValveOpenCloseActionDuration = defaultInputRangeConfig["valveOpenCloseDuration"];

  const handleFocus = id => {
    setIsFocused(id);
  };

  const giveErrorMessage = (label, value, precision = 2) =>
    `The ${label} value entered is outside the allowed range (${value.minValue.toFixed(precision)} to ${value.maxValue.toFixed(precision)}). Please revise your input.`;

  const handleBlur = (e, validations, label, precision = 2) => {
    const { name, value } = e.target;
    if (
      name === "maxAirScourPressure" ||
      name === "maxAirProcPressure" ||
      name === "filteratePressure" ||
      name === "nonIntegraPacTrainPresDrop" ||
      name === "integraPacFiltrationPreDrop" ||
      name === "backwashPipingPreDrop" ||
      name === "cIPPipingPreDrop" ||
      name === "backwash_Filtration" ||
      name === "acidCEB_Filtration" ||
      name === "alkaliCEB_Filtration" ||
      name === "cIP_Filtration" ||
      name === "miniCIP_Filtration"
    ) {
      if (unit.selectedUnits[3] !== "bar") {
        const unitValidation = {
          minValue: Number(unitValidations(name)[0]),
          maxValue: Number(unitValidations(name)[1]),
        };
        if (value < unitValidation?.minValue || value > unitValidation?.maxValue) {
          setErrorOperator({
            show: true,
            message: giveErrorMessage(label, unitValidation, precision),
          });
          setIsFocused(name);
          setTimeout(() => {
            e.target.focus();
          }, 0);
        } else {
          setIsFocused(null);
          dispatch(updateAdditionalData({ target: name, value: Number(value).toFixed(precision) }));
          setErrorOperator({
            show: false,
            message: "",
          });
        }
      } else if (value < validations?.minValue || value > validations?.maxValue) {
        setErrorOperator({
          show: true,
          message: giveErrorMessage(label, validations, precision),
        });
        setIsFocused(name);
        setTimeout(() => {
          e.target.focus();
        }, 0);
      } else {
        setIsFocused(null);
        dispatch(updateAdditionalData({ target: name, value: Number(value).toFixed(precision) }));
        setErrorOperator({
          show: false,
          message: "",
        });
      }
    } else if (name === "volvePowerReqPerTrain" || name === "pLCPowerReqPertrain") {
      if (unit.selectedUnits[9] !== "kW") {
        const unitValidation = {
          minValue: Number(unitValidations(name)[0]),
          maxValue: Number(unitValidations(name)[1]),
        };
        if (value < unitValidation?.minValue || value > unitValidation?.maxValue) {
          setErrorOperator({
            show: true,
            message: giveErrorMessage(label, unitValidation, precision),
          });
          setIsFocused(name);
          setTimeout(() => {
            e.target.focus();
          }, 0);
        } else {
          setIsFocused(null);
          dispatch(updateAdditionalData({ target: name, value: Number(value).toFixed(precision) }));
          setErrorOperator({
            show: false,
            message: "",
          });
        }
      } else if (value < validations?.minValue || value > validations?.maxValue) {
        setErrorOperator({
          show: true,
          message: giveErrorMessage(label, validations, precision),
        });
        setIsFocused(name);
        setTimeout(() => {
          e.target.focus();
        }, 0);
      } else {
        setIsFocused(null);
        dispatch(updateAdditionalData({ target: name, value: Number(value).toFixed(precision) }));
        setErrorOperator({
          show: false,
          message: "",
        });
      }
    } else if (value < validations?.minValue || value > validations?.maxValue) {
      setErrorOperator({
        show: true,
        message: giveErrorMessage(label, validations, precision),
      });
      setIsFocused(name);
      setTimeout(() => {
        e.target.focus();
      }, 0);
    } else {
      setIsFocused(null);

      dispatch(updateAdditionalData({ target: name, value: Number(value).toFixed(precision) }));

      setErrorOperator({
        show: false,
        message: "",
      });
    }
  };

  const handleKeyDownEvent = evt => {
    const events = ["e", "E", "+", "-", "ArrowUp", "ArrowDown"];
    if (evt.target.name === "valvesPerTrain") {
      events.push(".");
    }
    return events.includes(evt.key) && evt.preventDefault();
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    if (!isNaN(value)) {
      const sendData = { target: name, value: value };
      dispatch(updateAdditionalData(sendData));
      dispatch(setUfDataUpdate(true));
    }
  };
  const pressureData = [
    {
      id: 1,
      label: "Maximum Air Scour Pressure",
      className: "settingContainer",
      inputText: "Bar",
      defaultValue: 0.0,
      calcInput: false,
      disabled: isIngeSelectedMemo,
      name: fieldMaximumAirScourPressure,
      value: "maxAirScourPressure",
    },
    {
      id: 2,
      label: "Maximum Process Air Pressure",
      className: "settingContainer",
      inputText: "Bar",
      defaultValue: 0.0,
      calcInput: true,
      disabled: true,
      name: fieldMaximumProcessAirPressure,
      value: "maxAirProcPressure",
    },
    {
      id: 3,
      label: "Filtrate Pressure",
      inputText: "Bar",
      className: "settingContainer",
      defaultValue: 0.5,
      calcInput: false,
      disabled: false,
      name: fieldFiltratePressure,
      value: "filteratePressure",
    },
    {
      id: 4,
      label: `Filtration Piping Pressure Drop: ${unit.selectedUnits[2] !== "°C" ? Number(convertUnit(designTemp, 2, "°C", 2)) : designTemp}${unit.selectedUnits[2]}`,
      inputText: "Bar",
      className: "settingContainer",
      defaultValue: 0.4,
      calcInput: false,
      disabled: false,
      name: fieldFiltratePipingPressure,
      value: "nonIntegraPacTrainPresDrop",
    },
    {
      id: 5,
      label: `Strainer Pressure Drop: ${unit.selectedUnits[2] !== "°C" ? Number(convertUnit(designTemp, 2, "°C", 2)) : designTemp}${unit.selectedUnits[2]}`,
      className: "settingContainer",
      inputText: "Bar",
      defaultValue: 0.1,
      calcInput: false,
      disabled: false,
      name: fieldStrainerPressure,
      value: "integraPacFiltrationPreDrop",
    },
    {
      id: 6,
      label: `Backwash Piping Pressure Drop: ${
        unit.selectedUnits[2] !== "°C" ? Number(convertUnit(designTemp, 2, "°C", 2)) : designTemp
      }${unit.selectedUnits[2]}`,
      className: "settingContainer",
      inputText: "Bar",
      defaultValue: 0.1,
      calcInput: false,
      disabled: false,
      name: fieldBackwashPipingPressureDrop,
      value: "backwashPipingPreDrop",
    },
    {
      id: 7,
      label: `CIP Piping Pressure Drop: ${parseFloat(ufStore.recycleTemperature).toFixed(2)}${unit.selectedUnits[2]}`,
      className: "settingContainer",
      inputText: "Bar",
      // defaultValue: 2.5,
      calcInput: false,
      disabled: false,
      name: fieldCIPPipingPressureDrop,
      value: "cIPPipingPreDrop",
    },
  ];
  const valveData = [
    {
      id: 9,
      label: "Typical Wait Duration",
      className: "settingContainer marginTop10",
      inputText: "Sec",
      defaultValue: 7,
      placeholder: 0.0,
      name: fieldTypicalWaitDurationDupont,
      value: TYPICAL_WAIT_DURATION[technologyID],
    },
    {
      id: 10,
      label: "Typical Pump Ramp",
      className: "settingContainer marginTop10",
      inputText: "Sec",
      defaultValue: 5,
      placeholder: 0.0,
      name: fieldTypicalPumpRampDupont,
      value: TYPICAL_PUMP_RAMP[technologyID],
    },
  ];

  const memCorData = [
    {
      id: 11,
      label: "BW, Aeration, Sweep Flow",
      className: "settingContainer marginTop10",
      inputText: `m${"\u00B3"}/h/module`,
      defaultValue: 0.0,
      placeholder: 0.0,
      disabled: true,
    },
    {
      id: 12,
      label: "Aeration with Sweep Flow",
      className: "settingContainer marginTop10",
      inputText: `m${"\u00B3"}/h/module`,
      defaultValue: 0.0,
      placeholder: 0.0,
      disabled: true,
    },
    {
      id: 13,
      label: "Sweep Flow",
      className: "settingContainer marginTop10",
      inputText: `m${"\u00B3"}/h/module`,
      defaultValue: 0.0,
      placeholder: 0.0,
      disabled: true,
    },
    {
      id: 14,
      label: "Lumen Fill Flow",
      className: "settingContainer marginTop10",
      inputText: `m${"\u00B3"}/h/module`,
      defaultValue: 0.0,
      placeholder: 0.0,
      disabled: true,
    },
  ];
  const unitValidations = field => {
    if (field == "maxAirScourPressure") {
      const minRange =
        unit.selectedUnits[3] === "bar"
          ? fieldMaximumAirScourPressure[0]?.minValue
          : convertUnit(fieldMaximumAirScourPressure[0]?.minValue, 3, "bar");
      const maxRange =
        unit.selectedUnits[3] === "bar"
          ? fieldMaximumAirScourPressure[0]?.maxValue
          : convertUnit(fieldMaximumAirScourPressure[0]?.maxValue, 3, "bar");
      return [Number(minRange), Number(maxRange)];
    }
    if (field == "maxAirProcPressure") {
      const minRange =
        unit.selectedUnits[3] === "bar"
          ? fieldMaximumProcessAirPressure[0]?.minValue
          : convertUnit(fieldMaximumProcessAirPressure[0]?.minValue, 3, "bar", 0);
      const maxRange =
        unit.selectedUnits[3] === "bar"
          ? fieldMaximumProcessAirPressure[0]?.maxValue
          : convertUnit(fieldMaximumProcessAirPressure[0]?.maxValue, 3, "bar", 0);
      return [Number(minRange), Number(maxRange)];
    }
    if (field == "filteratePressure") {
      const minRange =
        unit.selectedUnits[3] === "bar"
          ? fieldFiltratePressure[0]?.minValue
          : convertUnit(fieldFiltratePressure[0]?.minValue, 3, "bar");
      const maxRange =
        unit.selectedUnits[3] === "bar"
          ? fieldFiltratePressure[0]?.maxValue
          : convertUnit(fieldFiltratePressure[0]?.maxValue, 3, "bar");
      return [Number(minRange), Number(maxRange)];
    }
    if (field == "nonIntegraPacTrainPresDrop") {
      const minRange =
        unit.selectedUnits[3] === "bar"
          ? fieldFiltratePipingPressure[0]?.minValue
          : convertUnit(fieldFiltratePipingPressure[0]?.minValue, 3, "bar", 2);
      const maxRange =
        unit.selectedUnits[3] === "bar"
          ? fieldFiltratePipingPressure[0]?.maxValue
          : convertUnit(fieldFiltratePipingPressure[0]?.maxValue, 3, "bar", 2);
      return [Number(minRange), Number(maxRange)];
    }
    if (field == "integraPacFiltrationPreDrop") {
      const minRange =
        unit.selectedUnits[3] === "bar"
          ? fieldStrainerPressure[0]?.minValue
          : convertUnit(fieldStrainerPressure[0]?.minValue, 3, "bar", 2);
      const maxRange =
        unit.selectedUnits[3] === "bar"
          ? fieldStrainerPressure[0]?.maxValue
          : convertUnit(fieldStrainerPressure[0]?.maxValue, 3, "bar", 2);
      return [Number(minRange), Number(maxRange)];
    }
    if (field == "backwashPipingPreDrop") {
      const minRange =
        unit.selectedUnits[3] === "bar"
          ? fieldBackwashPipingPressureDrop[0]?.minValue
          : convertUnit(fieldBackwashPipingPressureDrop[0]?.minValue, 3, "bar", 2);
      const maxRange =
        unit.selectedUnits[3] === "bar"
          ? fieldBackwashPipingPressureDrop[0]?.maxValue
          : convertUnit(fieldBackwashPipingPressureDrop[0]?.maxValue, 3, "bar", 2);
      return [Number(minRange), Number(maxRange)];
    }
    if (field == "cIPPipingPreDrop") {
      const minRange =
        unit.selectedUnits[3] === "bar"
          ? fieldCIPPipingPressureDrop[0]?.minValue
          : convertUnit(fieldCIPPipingPressureDrop[0]?.minValue, 3, "bar", 2);
      const maxRange =
        unit.selectedUnits[3] === "bar"
          ? fieldCIPPipingPressureDrop[0]?.maxValue
          : convertUnit(fieldCIPPipingPressureDrop[0]?.maxValue, 3, "bar", 2);
      return [Number(minRange), Number(maxRange)];
    }
    if (field == "backwash_Filtration") {
      const minRange =
        unit.selectedUnits[3] === "bar"
          ? fieldBackwashTMPIncrease[0]?.minValue
          : convertUnit(fieldBackwashTMPIncrease[0]?.minValue, 3, "bar", 2);

      const maxRange =
        unit.selectedUnits[3] === "bar"
          ? fieldBackwashTMPIncrease[0]?.maxValue
          : convertUnit(fieldBackwashTMPIncrease[0]?.maxValue, 3, "bar", 2);
      return [Number(minRange), Number(maxRange)];
    }
    if (field == "acidCEB_Filtration") {
      const minRange =
        unit.selectedUnits[3] === "bar"
          ? fieldAcidCEBTMPIncrease[0]?.minValue
          : convertUnit(fieldAcidCEBTMPIncrease[0]?.minValue, 3, "bar", 2);
      const maxRange =
        unit.selectedUnits[3] === "bar"
          ? fieldAcidCEBTMPIncrease[0]?.maxValue
          : convertUnit(fieldAcidCEBTMPIncrease[0]?.maxValue, 3, "bar", 2);
      return [Number(minRange), Number(maxRange)];
    }
    if (field == "alkaliCEB_Filtration") {
      const minRange =
        unit.selectedUnits[3] === "bar"
          ? fieldAlkaliCEBTMPincrease[0]?.minValue
          : convertUnit(fieldAlkaliCEBTMPincrease[0]?.minValue, 3, "bar", 2);
      const maxRange =
        unit.selectedUnits[3] === "bar"
          ? fieldAlkaliCEBTMPincrease[0]?.maxValue
          : convertUnit(fieldAlkaliCEBTMPincrease[0]?.maxValue, 3, "bar", 2);
      return [Number(minRange), Number(maxRange)];
    }
    if (field == "cIP_Filtration" || field == "miniCIP_Filtration") {
      const minRange =
        unit.selectedUnits[3] === "bar"
          ? fieldCIPTMPincrease[0]?.minValue
          : convertUnit(fieldCIPTMPincrease[0]?.minValue, 3, "bar", 2);
      const maxRange =
        unit.selectedUnits[3] === "bar"
          ? fieldCIPTMPincrease[0]?.maxValue
          : convertUnit(fieldCIPTMPincrease[0]?.maxValue, 3, "bar", 2);
      return [Number(minRange), Number(maxRange)];
    }
    if (field == "pLCPowerReqPertrain") {
      const minRange =
        unit.selectedUnits[9] === "kW"
          ? fieldPLCPowerRequirementperTrain[0]?.minValue
          : convertUnit(fieldPLCPowerRequirementperTrain[0]?.minValue, 9, "kW", 0);
      const maxRange =
        unit.selectedUnits[9] === "kW"
          ? fieldPLCPowerRequirementperTrain[0]?.maxValue
          : convertUnit(fieldPLCPowerRequirementperTrain[0]?.maxValue, 9, "kW", 0);
      return [Number(minRange), Number(maxRange)];
    }
    if (field == "volvePowerReqPerTrain") {
      const minRange =
        unit.selectedUnits[9] === "kW"
          ? fieldValvePowerRequirementsperValve[0]?.minValue
          : convertUnit(fieldValvePowerRequirementsperValve[0]?.minValue, 9, "kW", 0);
      const maxRange =
        unit.selectedUnits[9] === "kW"
          ? fieldValvePowerRequirementsperValve[0]?.maxValue
          : convertUnit(fieldValvePowerRequirementsperValve[0]?.maxValue, 9, "kW", 0);
      return [Number(minRange), Number(maxRange)];
    }
  };
  return (
    <>
      <AdditionalSettingStyled className='g-0'>
        <div className='first-card-wrapper'>
          <StyledCard className='pressure-card'>
            <Card.Header>
              <CustomHeading fontFamily='NotoSansRegular' color={colors.PrimaryDarkAquaMarine} label='Pressure' />
            </Card.Header>
            {pressureData.map((item, index) => (
              <div key={index} className={item.className}>
                {item.calcInput ? (
                  <>
                    <CustomHeading label={item.label} />
                    <CalcEngineInputBox
                      className='pressureCalc'
                      name={item.value}
                      type='number'
                      disabled={item.disabled}
                      inputText={unit.selectedUnits[3] == "bar" ? "Bar" : unit.selectedUnits[3]}
                      id={item.id}
                      placeholder='0.0'
                      value={isFocused === item.id ? ufStore[item.value] : Number(ufStore[item.value]).toFixed(2)}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDownEvent}
                      onWheel={e => e.target.blur()}
                      isFocused={isFocused === item.id}
                      onBlur={e => handleBlur(e, item.name[0], item.label)}
                      onFocus={() => handleFocus(item.id)}
                      isError={
                        ufStore[item.value] < unitValidations(item.value)[0] ||
                        ufStore[item.value] > unitValidations(item.value)[1]
                          ? true
                          : false
                      }
                      isAutoPopulated={item.isAutoPopulated}
                      tabIndex={index + 1}
                    />
                  </>
                ) : (
                  <>
                    <CustomHeading label={item.label} />
                    <InputWithText
                      className='pressure'
                      name={item.value}
                      type='number'
                      disabled={item.disabled}
                      inputText={unit.selectedUnits[3] == "bar" ? "Bar" : unit.selectedUnits[3]}
                      id={item.id}
                      placeholder='0.0'
                      value={isFocused === item.id ? ufStore[item.value] : Number(ufStore[item.value]).toFixed(2)}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDownEvent}
                      onWheel={e => e.target.blur()}
                      isFocused={isFocused === item.id}
                      onBlur={e => handleBlur(e, item.name[0], item.label)}
                      onFocus={() => handleFocus(item.id)}
                      isError={
                        Number(ufStore[item.value]) < Number(unitValidations(item.value)[0]) ||
                        Number(ufStore[item.value]) > Number(unitValidations(item.value)[1])
                          ? true
                          : false
                      }
                      tabIndex={index + 1}
                    />
                  </>
                )}
                {item.name[0].label !== "Maximum ADBW Air Pressure" ? (
                  <InputReferenceText
                    refText={`Ranges ${unit.selectedUnits[3] === "bar" ? item.name[0]?.minValue : convertUnit(item.name[0]?.minValue, 3, "bar", 2)} - ${unit.selectedUnits[3] === "bar" ? item.name[0]?.maxValue : convertUnit(item.name[0]?.maxValue, 3, "bar", 2)}`}
                  />
                ) : (
                  <></>
                )}
              </div>
            ))}
            <div>
              <CustomHeading label='Pressure drops are based on user inputs. Default values should not be used for pump sizing' />
            </div>
          </StyledCard>
          <StyledCard className='filtration-tmp-card'>
            <Card.Header>
              <CustomHeading
                fontFamily='NotoSansRegular'
                color={colors.PrimaryDarkAquaMarine}
                label='Filtration TMP Increase Between Processes'
              />
            </Card.Header>
            <div className='two-input-wrapper'>
              <div className='backwash'>
                <CustomHeading label='Backwash' />
                <InputWithText
                  name='backwash_Filtration'
                  type='number'
                  disabled={false}
                  inputText={
                    unit.selectedUnits[3] == "bar"
                      ? "mbar/h"
                      : unit.selectedUnits[3] == "psi"
                        ? "psi/1000h"
                        : unit.selectedUnits[3]
                  }
                  id='backwashInput'
                  placeholder='0.0'
                  value={
                    isFocused === "back"
                      ? ufStore["backwash_Filtration"]
                      : Number(ufStore["backwash_Filtration"]).toFixed(1)
                  }
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDownEvent}
                  onWheel={e => e.target.blur()}
                  isFocused={isFocused === "back"}
                  onBlur={e => handleBlur(e, fieldBackwashTMPIncrease[0], "Backwash")}
                  onFocus={() => handleFocus("back")}
                  isError={
                    ufStore.backwash_Filtration < unitValidations("backwash_Filtration")[0] ||
                    ufStore.backwash_Filtration > unitValidations("backwash_Filtration")[1]
                      ? true
                      : false
                  }
                  tabIndex={pressureData.length + 1}
                />
                <InputReferenceText
                  refText={`Ranges ${unit.selectedUnits[3] === "bar" ? fieldBackwashTMPIncrease[0]?.minValue : convertUnit(fieldBackwashTMPIncrease[0]?.minValue, 3, "bar", 2)} - ${unit.selectedUnits[3] === "bar" ? fieldBackwashTMPIncrease[0]?.maxValue : convertUnit(fieldBackwashTMPIncrease[0]?.maxValue, 3, "bar", 2)}`}
                />
              </div>
              <div className='acid-ceb'>
                <CustomHeading label='Acid CEB' />
                <InputWithText
                  name='acidCEB_Filtration'
                  type='number'
                  disabled={false}
                  inputText={
                    unit.selectedUnits[3] == "bar"
                      ? "mbar/h"
                      : unit.selectedUnits[3] == "psi"
                        ? "psi/1000h"
                        : unit.selectedUnits[3]
                  }
                  id='acidInput'
                  placeholder='0.0'
                  value={
                    isFocused === "acid"
                      ? ufStore["acidCEB_Filtration"]
                      : Number(ufStore["acidCEB_Filtration"]).toFixed(1)
                  }
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDownEvent}
                  onWheel={e => e.target.blur()}
                  isFocused={isFocused === "acid"}
                  onBlur={e => handleBlur(e, fieldAcidCEBTMPIncrease[0], "Acid CEB")}
                  onFocus={() => handleFocus("acid")}
                  isError={
                    ufStore.acidCEB_Filtration < unitValidations("acidCEB_Filtration")[0] ||
                    ufStore.acidCEB_Filtration > unitValidations("acidCEB_Filtration")[1]
                      ? true
                      : false
                  }
                  tabIndex={pressureData.length + 2}
                />
                <InputReferenceText
                  refText={`Ranges ${
                    unit.selectedUnits[3] === "bar"
                      ? fieldAcidCEBTMPIncrease[0]?.minValue
                      : convertUnit(fieldAcidCEBTMPIncrease[0]?.minValue, 3, "bar", 2)
                  } - ${
                    unit.selectedUnits[3] === "bar"
                      ? fieldAcidCEBTMPIncrease[0]?.maxValue
                      : convertUnit(fieldAcidCEBTMPIncrease[0]?.maxValue, 3, "bar", 2)
                  }`}
                />
              </div>
            </div>
            <div className='two-input-wrapper'>
              <div>
                <CustomHeading label='mini-CIP' />
                <CalcEngineInputBox
                  name='miniCIP_Filtration'
                  type='number'
                  disabled={UFData.ufSpecialFeatureID == 1}
                  inputText={
                    unit.selectedUnits[3] == "bar"
                      ? "mbar/h"
                      : unit.selectedUnits[3] == "psi"
                        ? "psi/1000h"
                        : unit.selectedUnits[3]
                  }
                  id='miniCipInput'
                  value={
                    isFocused === "miniCip"
                      ? ufStore["miniCIP_Filtration"]
                      : convertUptoDigits(ufStore["miniCIP_Filtration"], 2)
                  }
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDownEvent}
                  onWheel={e => e.target.blur()}
                  isFocused={isFocused === "miniCip"}
                  onBlur={e => handleBlur(e, fieldCIPTMPincrease[0], "Mini-CIP")}
                  onFocus={() => handleFocus("miniCip")}
                  isError={
                    ufStore.miniCIP_Filtration < unitValidations("cIP_Filtration")[0] ||
                    ufStore.miniCIP_Filtration > unitValidations("cIP_Filtration")[1]
                      ? true
                      : false
                  }
                  tabIndex={pressureData.length + 3}
                />
                <InputReferenceText
                  refText={`Ranges ${
                    unit.selectedUnits[3] === "bar"
                      ? fieldCIPTMPincrease[0]?.minValue
                      : convertUnit(fieldCIPTMPincrease[0]?.minValue, 3, "bar", 2)
                  } - ${
                    unit.selectedUnits[3] === "bar"
                      ? fieldCIPTMPincrease[0]?.maxValue
                      : convertUnit(fieldCIPTMPincrease[0]?.maxValue, 3, "bar", 2)
                  }`}
                />
              </div>
              <div>
                <CustomHeading label='Alkali CEB' />
                <InputWithText
                  name='alkaliCEB_Filtration'
                  type='number'
                  disabled={false}
                  inputText={
                    unit.selectedUnits[3] == "bar"
                      ? "mbar/h"
                      : unit.selectedUnits[3] == "psi"
                        ? "psi/1000h"
                        : unit.selectedUnits[3]
                  }
                  id='alkaliCebInput'
                  placeholder='0.0'
                  value={
                    isFocused === "alkali"
                      ? ufStore["alkaliCEB_Filtration"]
                      : convertUptoDigits(ufStore["alkaliCEB_Filtration"], 1)
                  }
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDownEvent}
                  onWheel={e => e.target.blur()}
                  isFocused={isFocused === "alkali"}
                  onBlur={e => handleBlur(e, fieldAlkaliCEBTMPincrease[0], "Alkali CEB")}
                  onFocus={() => handleFocus("alkali")}
                  isError={
                    ufStore.alkaliCEB_Filtration < unitValidations("alkaliCEB_Filtration")[0] ||
                    ufStore.alkaliCEB_Filtration > unitValidations("alkaliCEB_Filtration")[1]
                      ? true
                      : false
                  }
                  tabIndex={pressureData.length + 4}
                />
                <InputReferenceText
                  refText={`Ranges ${
                    unit.selectedUnits[3] === "bar"
                      ? fieldAlkaliCEBTMPincrease[0]?.minValue
                      : convertUnit(fieldAlkaliCEBTMPincrease[0]?.minValue, 3, "bar", 2)
                  } - ${
                    unit.selectedUnits[3] === "bar"
                      ? fieldAlkaliCEBTMPincrease[0]?.maxValue
                      : convertUnit(fieldAlkaliCEBTMPincrease[0]?.maxValue, 3, "bar", 2)
                  }`}
                />
              </div>
            </div>
            <div>
              <CustomHeading label='CIP' />
              <InputWithText
                className='widthCIP'
                name='cIP_Filtration'
                type='number'
                disabled={false}
                inputText={
                  unit.selectedUnits[3] == "bar"
                    ? "mbar/h"
                    : unit.selectedUnits[3] == "psi"
                      ? "psi/1000h"
                      : unit.selectedUnits[3]
                }
                id='cipInput'
                placeholder='0.0'
                value={
                  isFocused === "cip" ? ufStore["cIP_Filtration"] : convertUptoDigits(ufStore["cIP_Filtration"], 1)
                }
                onChange={handleInputChange}
                onKeyDown={handleKeyDownEvent}
                onWheel={e => e.target.blur()}
                isFocused={isFocused === "cip"}
                onBlur={e => handleBlur(e, fieldCIPTMPincrease[0], "CIP")}
                onFocus={() => handleFocus("cip")}
                isError={
                  ufStore.cIP_Filtration < unitValidations("cIP_Filtration")[0] ||
                  ufStore.cIP_Filtration > unitValidations("cIP_Filtration")[1]
                    ? true
                    : false
                }
                tabIndex={pressureData.length + 5}
              />
              <InputReferenceText
                refText={`Ranges ${
                  unit.selectedUnits[3] === "bar"
                    ? fieldCIPTMPincrease[0]?.minValue
                    : convertUnit(fieldCIPTMPincrease[0]?.minValue, 3, "bar", 2)
                } - ${
                  unit.selectedUnits[3] === "bar"
                    ? fieldCIPTMPincrease[0]?.maxValue
                    : convertUnit(fieldCIPTMPincrease[0]?.maxValue, 3, "bar", 2)
                }`}
              />
            </div>
          </StyledCard>
        </div>
        <div className='second-card-wrapper'>
          <StyledCard className='tank-storage-card'>
            <Card.Header>
              <CustomHeading
                fontFamily='NotoSansRegular'
                color={colors.PrimaryDarkAquaMarine}
                label='Tank Storage Parameters'
              />
            </Card.Header>
            <div className='chemical-storage-time'>
              <CustomHeading label='Chemical Storage Time' />
              <InputWithText
                className='widthChanges'
                name='chemicalStorageTime'
                type='number'
                inputText='Days'
                id='chemStTime'
                placeholder='0.0'
                value={ufStore["chemicalStorageTime"]}
                onChange={handleInputChange}
                onKeyDown={handleKeyDownEvent}
                isFocused={isFocused === "chem"}
                onBlur={e => handleBlur(e, fieldChemicalStorageTime[0], "Chemical Storage Time")}
                onFocus={() => handleFocus("chem")}
                isError={
                  ufStore.chemicalStorageTime < fieldChemicalStorageTime[0]?.minValue ||
                  ufStore.chemicalStorageTime > fieldChemicalStorageTime[0]?.maxValue
                }
                tabIndex={pressureData.length + 6}
              />
              <InputRanges ranges={fieldChemicalStorageTime[0]} />
            </div>
            <div className='marginTop10'>
              <CustomHeading label='Backwash Only Tank Refill Rate' />
              <CalcEngineInputBox
                className='widthChanges'
                name='bWTankRefillRate'
                type='number'
                inputText={
                  fieldBackwashOnlyTankRefillRate[0].uom == "%"
                    ? "% of filtrate flow"
                    : fieldBackwashOnlyTankRefillRate[0].uom
                }
                id='refillRate'
                placeholder='0.0'
                value={ufStore["bWTankRefillRate"]}
                onChange={handleInputChange}
                onKeyDown={handleKeyDownEvent}
                isFocused={isFocused === "refill"}
                onBlur={e => handleBlur(e, fieldBackwashOnlyTankRefillRate[0], "Backwash Only Tank Refill Rate")}
                onFocus={() => handleFocus("refill")}
                isError={
                  ufStore.bWTankRefillRate < fieldBackwashOnlyTankRefillRate[0]?.minValue ||
                  ufStore.bWTankRefillRate > fieldBackwashOnlyTankRefillRate[0]?.maxValue
                }
                tabIndex={pressureData.length + 7}
              />
              <InputRanges ranges={fieldBackwashOnlyTankRefillRate[0]} />
            </div>
          </StyledCard>
          <StyledCard className='tank-size-factor-card'>
            <Card.Header>
              <CustomHeading
                fontFamily='NotoSansRegular'
                color={colors.PrimaryDarkAquaMarine}
                label='Tank Size Factor'
              />
            </Card.Header>
            <div className='bw-plus'>
              <CustomHeading label='BW + Filtrate Tank' />
              <CalcEngineInputBox
                className='widthChanges'
                name='filterateTank'
                type='number'
                inputText='% of computed minimum'
                id='bw'
                placeholder='0.0'
                defaultValue={fieldTankSizeFactorBF[0].defaultValue}
                value={ufStore["filterateTank"]}
                onChange={handleInputChange}
                onKeyDown={handleKeyDownEvent}
                onWheel={e => e.target.blur()}
                isFocused={isFocused === "bw"}
                onBlur={e => handleBlur(e, fieldTankSizeFactorBF[0], "BW + Filtrate Tank")}
                onFocus={() => handleFocus("bw")}
                tabIndex={pressureData.length + 8}
              />
              <InputRanges ranges={fieldTankSizeFactorBF[0]} />
            </div>
            <div className='marginTop10'>
              <CustomHeading label='BW Only Tank' />
              <CalcEngineInputBox
                className='widthChanges'
                name='bWTank'
                type='number'
                disabled={false}
                inputText='% of computed minimum'
                id='bwOnly'
                placeholder='0.0'
                defaultValue={fieldTankSizeFactorB[0].defaultValue}
                value={ufStore["bWTank"]}
                onChange={handleInputChange}
                onKeyDown={handleKeyDownEvent}
                onWheel={e => e.target.blur()}
                isFocused={isFocused === "bwOnly"}
                onBlur={e => handleBlur(e, fieldTankSizeFactorB[0], "BW Only Tank")}
                onFocus={() => handleFocus("bwOnly")}
                isError={
                  ufStore.bWTank < fieldTankSizeFactorB[0]?.minValue ||
                  ufStore.bWTank > fieldTankSizeFactorB[0]?.maxValue
                    ? true
                    : false
                }
                tabIndex={pressureData.length + 9}
              />
              <InputReferenceText
                refText={`Ranges ${fieldTankSizeFactorB[0]?.minValue.toFixed(2)} - ${fieldTankSizeFactorB[0]?.maxValue.toFixed(2)}`}
              />
            </div>
            <div className='marginTop10'>
              <CustomHeading label='CIP Tank' />
              <InputWithText
                className='widthChanges'
                name='cIPTank'
                type='number'
                disabled={false}
                inputText='% of module volume'
                id='cipTank'
                placeholder='0.0'
                defaultValue={fieldTankSizeFactorCIP[0].defaultValue}
                value={ufStore["cIPTank"]}
                onChange={handleInputChange}
                onKeyDown={handleKeyDownEvent}
                onWheel={e => e.target.blur()}
                isFocused={isFocused === "cipTank"}
                onBlur={e => handleBlur(e, fieldTankSizeFactorCIP[0], "CIP Tank")}
                onFocus={() => handleFocus("cipTank")}
                isError={
                  ufStore.cIPTank < fieldTankSizeFactorCIP[0]?.minValue ||
                  ufStore.cIPTank > fieldTankSizeFactorCIP[0]?.maxValue
                    ? true
                    : false
                }
                tabIndex={pressureData.length + 10}
              />
              <InputReferenceText
                refText={`Ranges ${fieldTankSizeFactorCIP[0]?.minValue.toFixed(2)} - ${fieldTankSizeFactorCIP[0]?.maxValue.toFixed(2)}`}
              />
            </div>
          </StyledCard>
          {!IS_DUPONT_FLOW_ONLY && (
            <StyledCard className='displacement-volume-card'>
              <Card.Header>
                <CustomHeading
                  fontFamily='NotoSansRegular'
                  color={colors.PrimaryDarkAquaMarine}
                  label='Displacement Volumes'
                />
              </Card.Header>
              <div className='two-input-wrapper'>
                <div>
                  <CustomLabel label='ADBW Displacement' disabled={true} />
                  <InputWithText
                    name='aDBWDisplacement'
                    type='number'
                    disabled={true}
                    inputText={fieldADBWDisplacement[0].uom}
                    id='adbw'
                    placeholder='0.0'
                    defaultValue={fieldADBWDisplacement[0].defaultValue}
                    value={ufStore["aDBWDisplacement"]}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDownEvent}
                    onWheel={e => e.target.blur()}
                    isFocused={isFocused === "adbw"}
                    onBlur={e => handleBlur(e, fieldADBWDisplacement[0], "ADBW Displacement")}
                    onFocus={() => handleFocus("adbw")}
                    tabIndex={pressureData.length + 11}
                  />
                </div>
                <div>
                  <CustomLabel label='FTL Displacement' disabled={true} />
                  <InputWithText
                    name='fTLDisplacement'
                    type='number'
                    disabled={true}
                    inputText={fieldFTLDisplacement[0].uom}
                    id='ftl'
                    placeholder='0.0'
                    defaultValue={fieldFTLDisplacement[0].defaultValue}
                    value={ufStore["fTLDisplacement"]}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDownEvent}
                    onWheel={e => e.target.blur()}
                    isFocused={isFocused === "ftl"}
                    onBlur={e => handleBlur(e, fieldFTLDisplacement[0], "FTL Displacement")}
                    onFocus={() => handleFocus("ftl")}
                    tabIndex={pressureData.length + 12}
                  />
                </div>
              </div>
            </StyledCard>
          )}
          <StyledCard className='strainer-specification-card'>
            <Card.Header>
              <CustomHeading
                fontFamily='NotoSansRegular'
                color={colors.PrimaryDarkAquaMarine}
                label='Strainer Specification'
              />
            </Card.Header>
            <div className='two-input-wrapper'>
              <div>
                <CustomHeading label='Strainer Recovery' />
                <InputWithText
                  name='strainerRecovery'
                  type='number'
                  disabled={false}
                  inputText={fieldStrainerRecovery[0].uom}
                  id='strainerR'
                  placeholder='0.0'
                  defaultValue={fieldStrainerRecovery[0].defaultValue}
                  value={ufStore["strainerRecovery"]}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDownEvent}
                  onWheel={e => e.target.blur()}
                  isFocused={isFocused === "strainerR"}
                  onBlur={e => handleBlur(e, fieldStrainerRecovery[0], "Strainer Recovery")}
                  onFocus={() => handleFocus("strainerR")}
                  isError={
                    ufStore.strainerRecovery < fieldStrainerRecovery[0]?.minValue ||
                    ufStore.strainerRecovery > fieldStrainerRecovery[0]?.maxValue
                      ? true
                      : false
                  }
                  tabIndex={18}
                />
                <InputReferenceText
                  refText={`Ranges ${fieldStrainerRecovery[0]?.minValue.toFixed(2)} - ${fieldStrainerRecovery[0]?.maxValue.toFixed(2)}`}
                />
              </div>
              <div>
                <CustomHeading label='Strainer Size' />
                <InputWithText
                  name='strainerSize'
                  type='number'
                  disabled={false}
                  inputText={fieldStrainerSize[0].uom}
                  id='strainerS'
                  placeholder='0.0'
                  value={ufStore["strainerSize"]}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDownEvent}
                  onWheel={e => e.target.blur()}
                  isFocused={isFocused === "strainerS"}
                  onBlur={e => handleBlur(e, fieldStrainerSize[0], "Strainer Size")}
                  onFocus={() => handleFocus("strainerS")}
                  isError={
                    ufStore.strainerSize < fieldStrainerSize[0]?.minValue ||
                    ufStore.strainerSize > fieldStrainerSize[0]?.maxValue
                      ? true
                      : false
                  }
                  tabIndex={19}
                />
                <InputReferenceText
                  refText={`Ranges ${fieldStrainerSize[0]?.minValue.toFixed(2)} - ${fieldStrainerSize[0]?.maxValue.toFixed(2)}`}
                />
              </div>
            </div>
          </StyledCard>
          {/* <CustomCard className={"toc-rejection-card"} header='UF TOC Rejection'>
            <WPTypogrpahy
              variant={typographyVariants.xsText}
              text='Note: This feature is used to account for TOC rejection by UF membranes. A default value is given but users are encouraged to use a revalant figure to their system.'
              style={{ marginBottom: "16px" }}
            />
            <CustomLabel label='UF TOC Rejction' />
            <InputWithText name='tocRejection' type='number' />
            <InputRanges ranges={{ minValue: 0, maxValue: 100 }} />
          </CustomCard> */}
        </div>
        <div className='third-card-wrapper'>
          <StyledCard className='power-card'>
            <Card.Header>
              <CustomHeading color={colors.PrimaryDarkAquaMarine} label='Power' />
            </Card.Header>
            <div className='plc-power'>
              <CustomHeading label='PLC Power Requirement per Train' />
              <InputWithText
                className='widthChanges'
                name='pLCPowerReqPertrain'
                type='number'
                disabled={false}
                inputText={unit.selectedUnits[9]}
                id='plcPower'
                placeholder='0.0'
                value={
                  isFocused === "plcPower"
                    ? ufStore["pLCPowerReqPertrain"]
                    : Number(ufStore["pLCPowerReqPertrain"]).toFixed(2)
                }
                onChange={handleInputChange}
                onKeyDown={handleKeyDownEvent}
                onWheel={e => e.target.blur()}
                isFocused={isFocused === "plcPower"}
                onBlur={e => handleBlur(e, fieldPLCPowerRequirementperTrain[0], "PLC Power Requirement per Train")}
                onFocus={() => handleFocus("plcPower")}
                isError={
                  ufStore.pLCPowerReqPertrain < unitValidations("pLCPowerReqPertrain")[0] ||
                  ufStore.pLCPowerReqPertrain > unitValidations("pLCPowerReqPertrain")[1]
                    ? true
                    : false
                }
                tabIndex={20}
              />
              <InputReferenceText
                refText={`Ranges ${
                  unit.selectedUnits[9] === "kW"
                    ? fieldPLCPowerRequirementperTrain[0]?.minValue
                    : convertUnit(fieldPLCPowerRequirementperTrain[0]?.minValue, 9, "kW", 2)
                } - ${
                  unit.selectedUnits[9] === "kW"
                    ? fieldPLCPowerRequirementperTrain[0]?.maxValue
                    : convertUnit(fieldPLCPowerRequirementperTrain[0]?.maxValue, 9, "kW", 2)
                }`}
              />
            </div>
            <div className='marginTop10'>
              <CustomHeading label='Valve Power Requirement per Valve' />
              <InputWithText
                className='widthChanges'
                name='volvePowerReqPerTrain'
                type='number'
                disabled={false}
                inputText={unit.selectedUnits[9]}
                id='valvePower'
                placeholder='0.0'
                value={
                  isFocused === "valvePower"
                    ? ufStore["volvePowerReqPerTrain"]
                    : Number(ufStore["volvePowerReqPerTrain"]).toFixed(2)
                }
                onChange={handleInputChange}
                onKeyDown={handleKeyDownEvent}
                onWheel={e => e.target.blur()}
                isFocused={isFocused === "valvePower"}
                onBlur={e => handleBlur(e, fieldValvePowerRequirementsperValve[0], "Valve Power Requirement per Valve")}
                onFocus={() => handleFocus("valvePower")}
                isError={
                  ufStore.volvePowerReqPerTrain < unitValidations("volvePowerReqPerTrain")[0] ||
                  ufStore.volvePowerReqPerTrain > unitValidations("volvePowerReqPerTrain")[1]
                    ? true
                    : false
                }
                tabIndex={21}
              />
              <InputReferenceText
                refText={`Ranges ${
                  unit.selectedUnits[9] === "kW"
                    ? fieldValvePowerRequirementsperValve[0]?.minValue
                    : convertUnit(fieldValvePowerRequirementsperValve[0]?.minValue, 9, "kW", 2)
                } - ${
                  unit.selectedUnits[9] === "kW"
                    ? fieldValvePowerRequirementsperValve[0]?.maxValue
                    : convertUnit(fieldValvePowerRequirementsperValve[0]?.maxValue, 9, "kW", 2)
                }`}
              />
            </div>
          </StyledCard>
          <StyledCard className='valves-card'>
            <Card.Header>
              <CustomHeading
                fontFamily='NotoSansRegular'
                color={colors.PrimaryDarkAquaMarine}
                label='Miscellaneous timings'
              />
            </Card.Header>
            <div className='valves-per-unit'>
              <CustomHeading label='Valves per Unit' />
              <CustomInput
                className='pressureCalc'
                name='valvesPerTrain'
                type='number'
                defaultValue={fieldValvesPerUnit[0].defaultValue.toFixed(0)}
                value={ufStore["valvesPerTrain"]}
                onChange={handleInputChange}
                onKeyDown={handleKeyDownEvent}
                onWheel={e => e.target.blur()}
                isFocused={isFocused === "valvesPerTrain"}
                onBlur={e => handleBlur(e, fieldValvesPerUnit[0], "Valves per Unit", 0)}
                onFocus={() => handleFocus("valvesPerTrain")}
                isError={
                  ufStore.valvesPerTrain < fieldValvesPerUnit[0]?.minValue ||
                  ufStore.valvesPerTrain > fieldValvesPerUnit[0]?.maxValue
                    ? true
                    : false
                }
                placeholder='0'
                id='valvesPerUnit'
                tabIndex={22}
              />
              <InputReferenceText
                refText={`Ranges ${fieldValvesPerUnit[0]?.minValue.toFixed(0)} - ${fieldValvesPerUnit[0]?.maxValue.toFixed(0)}`}
              />
            </div>
            <div className='marginTop10'>
              <CustomHeading label='Valve Open/Close Action Duration' />
              <InputWithText
                className='pressureCalc'
                name='valveOpenCloseDuration'
                type='number'
                inputText={
                  fieldValveOpenCloseActionDuration?.uom == "s" ? "Sec" : fieldValveOpenCloseActionDuration?.uom
                }
                value={ufStore["valveOpenCloseDuration"]}
                onChange={handleInputChange}
                onKeyDown={handleKeyDownEvent}
                onWheel={e => e.target.blur()}
                isFocused={isFocused === "valveOpenCloseDuration"}
                onBlur={e => handleBlur(e, fieldValveOpenCloseActionDuration, "Valve Open/Close Action Duration")}
                onFocus={() => handleFocus("valveOpenCloseDuration")}
                isError={
                  ufStore.valveOpenCloseDuration < fieldValveOpenCloseActionDuration?.minValue ||
                  ufStore.valveOpenCloseDuration > fieldValveOpenCloseActionDuration?.maxValue
                    ? true
                    : false
                }
                placeholder='0.00'
                id='valve-open'
                tabIndex={23}
              />
              <InputReferenceText
                refText={`Ranges ${fieldValveOpenCloseActionDuration?.minValue.toFixed(2)} - ${fieldValveOpenCloseActionDuration?.maxValue.toFixed(2)}`}
              />
            </div>
            {valveData.map((item, index) => (
              <div key={index} className={item.className}>
                <CustomHeading label={item.label} />
                <InputWithText
                  className='pressureCalc'
                  name={item.value}
                  type='number'
                  inputText={item.name[0].uom == "s" ? "Sec" : item.name[0].uom}
                  id={item.className}
                  placeholder='0.0'
                  value={ufStore[item.value]}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDownEvent}
                  onWheel={e => e.target.blur()}
                  isFocused={isFocused === item.className}
                  onBlur={e => handleBlur(e, item.name[0], item.label)}
                  onFocus={() => handleFocus(item.className)}
                  isError={
                    ufStore[item.value] < item.name[0]?.minValue || ufStore[item.value] > item.name[0]?.maxValue
                      ? true
                      : false
                  }
                  tabIndex={24 + index}
                />
                <InputReferenceText
                  refText={`Ranges ${item.name[0]?.minValue.toFixed(2)} - ${item.name[0]?.maxValue.toFixed(2)}`}
                />
              </div>
            ))}
          </StyledCard>
          {!IS_DUPONT_FLOW_ONLY && (
            <StyledCard className='mem-flow-card'>
              <Card.Header>
                <CustomHeading fontFamily='NotoSansRegular' color={colors.PrimaryDarkAquaMarine} label='Memcor Flows' />
              </Card.Header>
              {memCorData.map((item, index) => (
                <div key={index} className={item.className}>
                  <CustomLabel label={item.label} disabled={item.disabled} />
                  <InputWithText
                    className='memcorWidth'
                    type='number'
                    disabled={item.disabled}
                    inputText={item.inputText}
                    id='plcPower'
                    placeholder={item.placeholder}
                    value={item.defaultValue.toFixed(2)}
                    isFocused={isFocused === item.id}
                    onBlur={e => handleBlur(e, item.name[0], item.label)}
                    onFocus={() => handleFocus(item.id)}
                    isError={false}
                    tabIndex={pressureData.length + 25 + valveData.length + index}
                  />
                </div>
              ))}
            </StyledCard>
          )}
        </div>
        {errorOperator.show && (
          <ProjectErrorPopup show={errorOperator.show} close={closeErrorMessag} message={errorOperator.message} />
        )}
      </AdditionalSettingStyled>
    </>
  );
};

export default AdditionalSetting;
