import React from "react";

import IconButton from "@components/IconButton";
import { DeleteIcon, EditIcon } from "@components/SVGs";

const ChemicalActionBtnRenderer = ({ data }) => (
    <div className="d-flex">
        <IconButton tooltip='Edit' aria-label="edit">
            <EditIcon />
        </IconButton>
        <IconButton disabled={data.isSystem} tooltip='Delete' aria-label="delete">
            <DeleteIcon disabled={data.isSystem} />
        </IconButton>
    </div>
);

export default ChemicalActionBtnRenderer;