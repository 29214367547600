import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  path {
    fill: rgba(255, 255, 255, 0.7);
  }
  &:hover {
    path {
      fill: #007672;
      stroke: #007672;
      stroke-width: 1;
    }
  }
`;

const CasesIcon = () => (
  <StyledSvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <g id='file'>
      <path
        id='Vector'
        d='M20.0297 6.76641C20.1703 6.90703 20.25 7.09687 20.25 7.29609V21.75C20.25 22.1648 19.9148 22.5 19.5 22.5H4.5C4.08516 22.5 3.75 22.1648 3.75 21.75V2.25C3.75 1.83516 4.08516 1.5 4.5 1.5H14.4539C14.6531 1.5 14.8453 1.57969 14.9859 1.72031L20.0297 6.76641ZM18.5203 7.64062L14.1094 3.22969V7.64062H18.5203Z'
      />
    </g>
  </StyledSvg>
);

export default CasesIcon;
