import { useReportUtils } from "@features/report-new/useReportUtils";

import {
  WPUfOperatingConditionsTableRowsData as rowsData,
  WPUfOperatingConditionsTableUnitMapping as unitMapping,
} from "./WPUfOperatingConditionsTable.constants";

export const useWPUfOperatingConditionsTableData = () => {
  const { ufReportRespData, formatConvertValueWithUnit, formatLabel } = useReportUtils();
  const { uf_operating_conditions } = ufReportRespData || {};

  const operatingConditionsData = {
    ...uf_operating_conditions,
    ...uf_operating_conditions?.filtration,
    ...uf_operating_conditions?.filtration?.instantaneous,
  };

  const getData = (value, rowInfo, key, precision) => {
    const unit = rowInfo?.unit?.[key] || unitMapping?.[key];
    return formatConvertValueWithUnit(value, unit, precision);
  };

  const convertToUfOperatingConditionsTableData = () => {
    const data = Object.entries(rowsData).reduce((acc, curr) => {
      const [key, row] = curr;
      const { duration, interval, flux, flow, display = true, num } = operatingConditionsData[key] || {};
      if (!display) return acc;
      let label = row.label;
      if (key === "units_ins_one" || key === "units_ins_two") {
        label = `${num} ${label}`;
      }
      acc.push({
        col1: formatLabel(label, row.indentLevel),
        col2: getData(duration, row, "duration"),
        col3: getData(interval, row, "interval"),
        col4: getData(flux, row, "flux"),
        col5: getData(flow, row, "flow", 2),
      });
      return acc;
    }, []);

    return data;
  };

  return { convertToUfOperatingConditionsTableData };
};
