import React from "react";

import { reportStrings } from "@constants/report.strings";

import { typographyVariants, WPTypogrpahy } from "@components/WPTypography";

import { WPReportSideNavFilter } from "../WPReportSideNavFilter/WPReportSideNavFilter";

export const WPReportSideNav = () => (
  <>
    <WPTypogrpahy variant={typographyVariants.xsText} text={reportStrings.sideNavSelectMsg} />
    <WPReportSideNavFilter id='ufSectionData' />
  </>
);
