import React, { memo } from "react";
import { AgGridReact } from "ag-grid-react";
import PropTypes from "prop-types";

import "./WPAgGrid.scss";

const WPAgGrid = ({ defaultColDef, rowData, columnDefs, tableHeight, context, onGridReady, gridRef, getRowId }) => (
    <div className='ag-theme-alpine wp-ag-grid' style={{ height: tableHeight }}>
        <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            context={context}
            onGridReady={onGridReady}
            getRowId={getRowId}
            defaultColDef={defaultColDef}
        />
    </div>
);

WPAgGrid.propTypes = {
    rowData: PropTypes.array,
    columnDefs: PropTypes.array,
    tableHeight: PropTypes.string,
};

WPAgGrid.defaultProps = {
    rowData: [],
    columnDefs: [],
    tableHeight: "300px",
};

export default memo(WPAgGrid);
