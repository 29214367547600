/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import CloseCircleGreenIcon from "@common/icons/CloseCircleGreenIcon";
import CloseCircleRedIcon from "@common/icons/CloseCircleRedIcon";

import { colors, fontStyles } from "../../Theme";
import CircularLoader from "../circularLoader";

const StyledInputWrapper = styled.div`
  display: inline-flex;
  max-height: 32px;
  min-height: 2rem;
  width: 100%;
  align-items: center;
  color: ${colors.Black};
  gap: -1px;
  align-self: stretch;
  border-radius: 2px;
  border: 1px solid;
  border-color: ${({ isError, isFocused, disabled, isWarning }) =>
    disabled ? "#e1e1e1" : isError ? "#E4001C" : isWarning ? "#FF9E19" : isFocused ? "#007672" : "#e1e1e1"};
  background-color: ${({ disabled }) => (disabled ? "#e1e1e1" : "#fff")};
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
  box-shadow: ${({ disabled }) => (disabled ? "none" : "")};
  &:hover {
    box-shadow: ${({ isFocused, disabled }) =>
      isFocused
        ? "0px 0px 0px 4px rgba(0, 169, 224, 0.20)"
        : disabled
          ? "none"
          : "0px 0px 0px 4px rgba(0, 169, 224, 0.20)"};
  }
`;
const StyledInput = styled.input`
  width: 100%;
  height: -webkit-fill-available;
  background-color: ${colors.White};
  border: none;
  outline: none;
  padding: 4px 10px;
  ${fontStyles.diodrum14}
  &::placeholder {
    color: ${colors.Grey96};
  }
  &:disabled {
    background-color: ${colors.GreyE1};
    pointer-events: none;
    color: ${colors.Grey96};
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
const StyledInputText = styled.span`
  background-color: ${props => props.unitBgColor || colors.GreyF8};
  padding: 4px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${fontStyles.diodrum14};
  line-height: 1.3rem;
  color: ${({ disabled }) => (disabled ? colors.Grey96 : colors.Black)};
  border-left: 1px solid #e1e1e1;
`;

const StyledInputIconWrapper = styled.span`
  background-color: "transparent";
  padding: 4px 5px;
  ${fontStyles.diodrum14};
  color: ${({ disabled }) => (disabled ? colors.Grey96 : colors.Black)};
  opacity: ${({ disabled }) => (disabled ? "0" : "1")};
  cursor: pointer;
`;

const InputWithText = ({
  isError,
  className,
  value,
  name,
  step,
  unitBgColor,
  min,
  max,
  isWarning,
  onChange,
  placeholder,
  inputText,
  onBlur,
  onFocus,
  onWheel,
  onScroll,
  disabled,
  defaultValue,
  type,
  id,
  onKeyDown,
  onKeyPress,
  showCancelIcon,
  onCancelIconClick,
  isLoading,
  tabIndex,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value, isFocused]);

  const handleFocus = e => {
    setIsFocused(true);
    onFocus && onFocus(e);
  };

  const handleBlur = e => {
    setIsFocused(false);
    onBlur && onBlur(e);
  };

  const handleKeyPress = event => {
    if (onKeyPress) {
      onKeyPress(event);
    } else if (event.key === "Enter") {
      event.target?.blur();
    }
  };

  const handleChange = e => {
    setCurrentValue(e.target.value);
    onChange && onChange(e);
  };

  const handleKeyDown = event => {
    if (onKeyDown) {
      onKeyDown(event);
    } else {
      ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(event.key) && event.preventDefault();
    }
  };

  const handleWheel = event => {
    if (onWheel) {
      onWheel(event);
    } else {
      event.target.blur();
    }
  };

  return (
    <>
      <StyledInputWrapper
        className={className}
        isError={isError}
        isWarning={isWarning}
        isFocused={isFocused}
        disabled={disabled}
      >
        <StyledInput
          id={id}
          type={type}
          step={step}
          value={currentValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onWheel={handleWheel}
          onScroll={onScroll}
          onChange={handleChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          name={name}
          disabled={disabled}
          min={min}
          max={max}
          onKeyDown={handleKeyDown}
          onKeyPress={handleKeyPress}
          tabIndex={tabIndex}
        />
        {inputText && (
          <StyledInputText disabled={disabled} unitBgColor={unitBgColor}>
            {inputText}
          </StyledInputText>
        )}

        {showCancelIcon && (
          <StyledInputIconWrapper onClick={onCancelIconClick}>
            {isError ? <CloseCircleRedIcon /> : <CloseCircleGreenIcon />}
          </StyledInputIconWrapper>
        )}

        {isLoading && <CircularLoader className='loader' size='20px'></CircularLoader>}
      </StyledInputWrapper>
    </>
  );
};

export default InputWithText;
