import { useCallback, useRef } from "react";

import WPAgGrid from "@components/WPAgGrid/WPAgGrid";

import { PROJECT_COST_TABLE_CONFIG } from "./ProjectCostTable.config";

const ProjectCostTable = ({ chemicals, onChemicalUpdate }) => {
    const gridRef = useRef();

    const getRowId = params => params.data.iD;

    const onDataUpdate = useCallback(newData => {
        if (gridRef) {
            const rowNode = gridRef.current.api.getRowNode(newData.iD);
            rowNode.updateData(newData);
            onChemicalUpdate(newData);
        }
    }, [gridRef]);

    return (
        <WPAgGrid
            gridRef={gridRef}
            columnDefs={PROJECT_COST_TABLE_CONFIG}
            rowData={chemicals}
            tableHeight='500px'
            context={{ onDataUpdate }}
            getRowId={getRowId}
        />
    );
};

export default ProjectCostTable;
