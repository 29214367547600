import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SELECTED_UNIT, UNITS } from "@constants/units.constant";

import useUFChemicalsHandler from "@hooks/useUFChemicalsHandler";
import useUnitConversion from "@hooks/useUnitConversion";

import { convertUptoDigits } from "@utils/appUtils";
import { ValidationStrings } from "@utils/StringConstants";

import CustomCard from "@components/CustomCard";
import InputRanges from "@components/InputRanges";

import CustomLabel from "@common/styles/components/headings/CustomLabel";
import ErrorMessage from "@common/styles/components/headings/ErrorMessage";
import WarningMessage from "@common/styles/components/headings/WarningMessage";
import CustomInput from "@common/styles/components/inputs/CustomInput";
import { handleTabAndEnter } from "@common/styles/components/inputs/InputFocusUtililty";
import InputWithText from "@common/styles/components/inputs/InputWithText";
import CustomSelect from "@common/styles/components/selects/CustomSelect";

import ProjectErrorPopup from "../../modals/ProjectErrorPopup";

import {
  CHEMICAL_TYPES,
  MAX_TEMP_CELCIUS,
  MAX_TEMP_CELCIUS_WARNING,
  UF_CHEMICAL_SCREENS,
  WATER_TYPE_IDS,
} from "./constants/UFConstants";
import CIPStyled from "./CIPStyled";
import UFChemicalFields from "./UFChemicalFields";
import { setUfDataUpdate, updateUFStoreData } from "./UFSlice";
import UFSystemDesignDiagram from "./UFSystemDesignDiagram";
import { isIngeSelected } from "./ufUtils";

const CIP_FIELDS = {
  recycleTemperature: "Recycle Temperature",
  bWStepInCIP: "Initial BW Cycles",
  cIPRinseSoakCycle: "Rinse/Soak Cycles",
  rinseBWCycle: "Rinse BW Cycles",
  uFCIPWaterTypeID: "CIP Water Source",
  heatingStepDuration: "Heating Step",
  recycleDuration: "Recycle",
  chemicalSoakingDuration_CIP: "Chemical Soaking",
};

const CIP = () => {
  const dispatch = useDispatch();
  const { unitConversionByName } = useUnitConversion();
  const { isValueInPh, getUFChemicalData, getChemicalRanges } = useUFChemicalsHandler();

  const cipData = useSelector(state => state.UFStore.data);
  const { ufInputRangeConfig, ufInputRangeConfigByWaterType } = useSelector(state => state.UFStore);
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const { waterType } = useSelector(state => state.UFStore.cipDropdownData);
  const { tempDesign, waterTypeID } =
    useSelector(state => state.Feedsetupdetailsdatapanel.streamData?.lstrequestsavefeedwater[0]?.streams[0]) || {};

  const [isFocused, setIsFocused] = useState(null);
  const [inputError, setInputError] = useState();

  const provideRange = (label, hardCoded) => {
    let foundRange = ufInputRangeConfigByWaterType.find(item => item.label == label && item.waterSubType);
    if (!foundRange) {
      foundRange = ufInputRangeConfig.find(item => item.label == label);
      if (!foundRange) {
        return hardCoded;
      } else {
        return foundRange;
      }
    } else {
      return foundRange;
    }
  };

  const selectedTempUnit = useMemo(() => unit.selectedUnits[SELECTED_UNIT.TEMPERATURE], [unit.selectedUnits]);

  const isSufractantEnabled = useMemo(() => {
    const waterTypeStr = waterTypeID?.toString();
    const isWaterWater = waterTypeStr === WATER_TYPE_IDS.WASTE_WATER.toString();
    const isInge = isIngeSelected(cipData.pUFTechnologyID);
    return isInge && isWaterWater;
  }, [cipData.pUFTechnologyID, waterTypeID]);

  const validations = useMemo(
    () => ({
      ...getChemicalRanges(isValueInPh ? "ph" : "mgL", UF_CHEMICAL_SCREENS.CIP),
      bWStepInCIP: { minValue: 1, maxValue: 10 },
      cIPRinseSoakCycle: { minValue: 1, maxValue: 10 },
      rinseBWCycle: { minValue: 1, maxValue: 10 },
      chemicalSoakingDuration_CIP: provideRange(ValidationStrings.cipSoak, {
        minValue: 5,
        maxValue: 720,
      }),
      recycleTemperature: {
        minValue: +unitConversionByName(tempDesign, selectedTempUnit, UNITS.celsius),
        maxValue: +unitConversionByName(MAX_TEMP_CELCIUS, selectedTempUnit, UNITS.celsius),
      },
      recycleDuration: provideRange(ValidationStrings.cipRecycle, { minValue: 7.5, maxValue: 240 }),
      heatingStepDuration: provideRange(ValidationStrings.cipHeatingStep, {
        minValue: 1,
        maxValue: 360,
        defaultValue: 60,
      }),
    }),
    [isValueInPh, selectedTempUnit],
  );

  const updateStoreData = newData => dispatch(updateUFStoreData(newData));
  const closeErrorMessag = () => setInputError();

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = event => {
    if (event.key === "Enter" || event.key === "Tab") {
      closeErrorMessag();
      handleTabAndEnter(event);
    }
  };

  const checkError = id => !(validations[id]?.minValue <= cipData[id] && validations[id]?.maxValue >= cipData[id]);
  const handleFocus = id => setIsFocused(id);

  const handleBlur = event => {
    const { id, name, value } = event.target;
    const field = CIP_FIELDS[id];
    const fixedRange = name === "cIPRinseSoakCycle" || name === "rinseBWCycle" || name === "bWStepInCIP" ? 0 : 2;
    const { minValue, maxValue } = validations[name] || {};
    if (value < minValue || value > maxValue) {
      const error = `The ${field} value entered is outside the allowed range (${convertUptoDigits(minValue, fixedRange)}
                      to ${convertUptoDigits(maxValue, fixedRange)}). Please revise your input.`;
      setInputError(error);
      setTimeout(() => event.target.focus(), 0);
    } else {
      updateStoreData({ data: { [name]: convertUptoDigits(value, fixedRange) }, calcEngineDataRefreshCount: 1 });
      setIsFocused(null);
      setInputError();
    }
  };

  const isHeatingStepDisabled = useMemo(() => {
    const recycleTemperature = convertUptoDigits(cipData.recycleTemperature, 1);
    let designTemp = unitConversionByName(tempDesign, selectedTempUnit, UNITS.celsius);
    designTemp = convertUptoDigits(designTemp, 1);
    return +recycleTemperature === +designTemp;
  }, [cipData.recycleTemperature, tempDesign, selectedTempUnit]);

  useEffect(() => {
    const heatingStepDuration = isHeatingStepDisabled
      ? 0
      : cipData.heatingStepDuration === 0
        ? validations["heatingStepDuration"].defaultValue
        : undefined;

    if (heatingStepDuration !== undefined) {
      updateStoreData({ data: { heatingStepDuration }, calcEngineDataRefreshCount: 1 });
    }
  }, [cipData.recycleTemperature, isHeatingStepDisabled]);

  const handleSelect = e => {
    const { value, name, id } = e.target;
    const chemicalValue = getUFChemicalData(id, value, UF_CHEMICAL_SCREENS.CIP);
    const eventData = { target: { id, value: chemicalValue, name: `${id}Value_CIP` } };
    handleInputChange(eventData);
    dispatch(
      updateUFStoreData({ data: { [name]: value.toString() }, calcEngineDataRefreshCount: 1, isUfDataUpdated: true }),
    );
  };

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    if (!isNaN(value)) {
      updateStoreData({ data: { [name]: value } });
      dispatch(setUfDataUpdate(true));
    }
  };

  const isRecycleTempError = useMemo(() => {
    const { recycleTemperature } = cipData;
    const { minValue, maxValue } = validations.recycleTemperature;
    const tempWarningUpperLimit = unitConversionByName(MAX_TEMP_CELCIUS_WARNING, selectedTempUnit, UNITS.celsius);
    const errors = {
      warning:
        recycleTemperature > tempWarningUpperLimit ? `Warning Ranges (${minValue} - ${tempWarningUpperLimit})` : null,
      error:
        recycleTemperature < minValue || recycleTemperature > maxValue
          ? `Values out of range(${minValue} - ${maxValue})`
          : null,
    };
    return errors;
  }, [cipData.recycleTemperature]);

  const handleKeyDownEvent = evt =>
    ["e", "E", "+", "-", "ArrowUp", "ArrowDown", "."].includes(evt.key) && evt.preventDefault();

  const getValue = (field, fixedDigits) =>
    isFocused === field ? cipData[field] : convertUptoDigits(cipData[field], fixedDigits);

  return (
    <>
      <CIPStyled>
        <UFSystemDesignDiagram />
        <div className='cip-temp-water-cycle'>
          <CustomCard
            className='cip-temp-card'
            header='CIP Temperature'
            tooltipLabel='CIPs can be done with heated water to improve cleaning efficiency'
          >
            <div className='temp-cip'>
              <CustomLabel label={CIP_FIELDS.recycleTemperature} />
              <InputWithText
                id='recycleTemperature'
                name='recycleTemperature'
                type='number'
                isError={checkError("recycleTemperature")}
                isWarning={isRecycleTempError.warning}
                inputText={selectedTempUnit}
                placeholder='0.00'
                onChange={handleInputChange}
                value={getValue("recycleTemperature")}
                isFocused={isFocused === "recycleTemperature"}
                onBlur={handleBlur}
                onFocus={() => handleFocus("recycleTemperature")}
                tabIndex={1}
              />

              {isRecycleTempError.error ? (
                <ErrorMessage texMsg={isRecycleTempError.error} />
              ) : isRecycleTempError.warning ? (
                <WarningMessage txtMsg={isRecycleTempError.warning} />
              ) : (
                <InputRanges ranges={validations.recycleTemperature} fixedDigits={1} />
              )}
            </div>
          </CustomCard>
          <CustomCard
            className='cycles-card'
            header='Cycles'
            tooltipLabel='Provide number of initial BW cycles, chemical rinse/soak cycles, and final rinse BW cycles.'
          >
            <div className='cycle-input-wrapper'>
              <div className='initial-bw-cycle'>
                <CustomLabel label={CIP_FIELDS.bWStepInCIP} />
                <CustomInput
                  type='number'
                  id='bWStepInCIP'
                  name='bWStepInCIP'
                  isError={checkError("bWStepInCIP")}
                  placeholder='0'
                  value={getValue("bWStepInCIP", 0)}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDownEvent}
                  onWheel={e => e.target.blur()}
                  isFocused={isFocused === "bWStepInCIP"}
                  onBlur={handleBlur}
                  onFocus={() => handleFocus("bWStepInCIP")}
                  tabIndex={2}
                />
                <InputRanges ranges={validations.bWStepInCIP} fixedDigits={0} />
              </div>
              <div className='rinse-soak-cycle'>
                <CustomLabel label={CIP_FIELDS.cIPRinseSoakCycle} />
                <CustomInput
                  type='number'
                  id='cIPRinseSoakCycle'
                  name='cIPRinseSoakCycle'
                  value={getValue("cIPRinseSoakCycle", 0)}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDownEvent}
                  onWheel={e => e.target.blur()}
                  isError={checkError("cIPRinseSoakCycle")}
                  placeholder='0'
                  defaultValue='1'
                  isFocused={isFocused === "cIPRinseSoakCycle"}
                  onBlur={handleBlur}
                  onFocus={() => handleFocus("cIPRinseSoakCycle")}
                  tabIndex={3}
                />
                <InputRanges ranges={validations.cIPRinseSoakCycle} fixedDigits={0} />
              </div>
              <div className='rinse-bw-cycle'>
                <CustomLabel label={CIP_FIELDS.rinseBWCycle} />
                <CustomInput
                  type='number'
                  id='rinseBWCycle'
                  name='rinseBWCycle'
                  value={getValue("rinseBWCycle", 0)}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDownEvent}
                  onWheel={e => e.target.blur()}
                  isError={checkError("rinseBWCycle")}
                  placeholder='0'
                  isFocused={isFocused === "rinseBWCycle"}
                  onBlur={handleBlur}
                  onFocus={() => handleFocus("rinseBWCycle")}
                  tabIndex={4}
                />
                <InputRanges ranges={validations.rinseBWCycle} fixedDigits={0} />
              </div>
            </div>
          </CustomCard>
          <CustomCard
            className='cip-water-card'
            header='Water Source'
            tooltipLabel='Select source of water for cleaning protocols, RO permeate available if RO in design.'
          >
            <div>
              <CustomLabel label={CIP_FIELDS.uFCIPWaterTypeID} />
              <CustomSelect
                onChange={handleSelect}
                name='uFCIPWaterTypeID'
                value={cipData.uFCIPWaterTypeID}
                id='waterType'
              >
                {waterType.map(item => (
                  <option key={item.uFCIPWaterTypeID} value={item.uFCIPWaterTypeID}>
                    {item.cIPWaterTypeName}
                  </option>
                ))}
              </CustomSelect>
            </div>
          </CustomCard>
        </div>
        <div className='acid-alkaline-surfactant'>
          <CustomCard
            className='acid-cip-card'
            header='Acid CIP'
            tooltipLabel='Select mineral acid and organic acid chemical reagents and target doses or pH'
          >
            <UFChemicalFields
              type={CHEMICAL_TYPES.mineral}
              chemicalRanges={validations}
              screen={UF_CHEMICAL_SCREENS.CIP}
              tabIndex={5}
            />
            <UFChemicalFields
              type={CHEMICAL_TYPES.organic}
              chemicalRanges={validations}
              screen={UF_CHEMICAL_SCREENS.CIP}
              tabIndex={6}
            />
          </CustomCard>

          <CustomCard
            className='alkaline-cip-card'
            header='Alkali/Oxidant CIP'
            tooltipLabel='Select alkali and oxidant chemical reagents and target doses or pH.'
          >
            <UFChemicalFields
              type={CHEMICAL_TYPES.alkali}
              chemicalRanges={validations}
              screen={UF_CHEMICAL_SCREENS.CIP}
              tabIndex={7}
            />
            <UFChemicalFields
              type={CHEMICAL_TYPES.oxidant}
              chemicalRanges={validations}
              screen={UF_CHEMICAL_SCREENS.CIP}
              tabIndex={8}
            />
            <div className='CIP-scaling'>
              <CustomLabel label={CIP_FIELDS.cip_LSI} />
              <InputWithText
                type='number'
                disabled
                name='cip_LSI'
                inputText='LSI'
                isFocused={isFocused === 9}
                onBlur={handleBlur}
                onFocus={() => handleFocus(9)}
                onChange={handleInputChange}
                value={convertUptoDigits(cipData.cip_LSI)}
                tabIndex={9}
              />
            </div>
          </CustomCard>
          <CustomCard
            className='surfactant-card'
            header='Surfactant CIP'
            tooltipLabel='Select surfactant chemical reagent and target doses or pH.'
          >
            <UFChemicalFields
              type={CHEMICAL_TYPES.oxidant2}
              chemicalRanges={validations}
              screen={UF_CHEMICAL_SCREENS.CIP}
              disabled={!isSufractantEnabled}
              tabIndex={10}
            />
          </CustomCard>
        </div>
        <div className='duration'>
          <CustomCard
            className='duration-card'
            header='Duration'
            tooltipLabel='Duration of chemical treatment steps. Initial and rinse BW steps follow normal BW cycle durations.'
          >
            <div className='duration-input-wrapper'>
              <div>
                <CustomLabel label={CIP_FIELDS.heatingStepDuration} />
                <InputWithText
                  type='number'
                  id='heatingStepDuration'
                  name='heatingStepDuration'
                  disabled={isHeatingStepDisabled}
                  isError={checkError("heatingStepDuration")}
                  inputText='min'
                  placeholder='0.00'
                  value={
                    isFocused === "heatingStepDuration"
                      ? cipData.heatingStepDuration
                      : convertUptoDigits(cipData.heatingStepDuration)
                  }
                  onChange={handleInputChange}
                  isFocused={isFocused === "heatingStepDuration"}
                  onBlur={handleBlur}
                  onFocus={() => handleFocus("heatingStepDuration")}
                  tabIndex={11}
                />
                <InputRanges ranges={validations.heatingStepDuration} />
              </div>
              <div>
                <CustomLabel label={CIP_FIELDS.recycleDuration} />
                <InputWithText
                  type='number'
                  id='recycleDuration'
                  name='recycleDuration'
                  isError={checkError("recycleDuration")}
                  inputText='min'
                  isFocused={isFocused === "recycleDuration"}
                  value={
                    isFocused === "recycleDuration"
                      ? cipData.recycleDuration
                      : convertUptoDigits(cipData.recycleDuration)
                  }
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onFocus={() => handleFocus("recycleDuration")}
                  tabIndex={12}
                />
                <InputRanges ranges={validations.recycleDuration} />
              </div>
              <div>
                <CustomLabel label={CIP_FIELDS.chemicalSoakingDuration_CIP} />
                <InputWithText
                  type='number'
                  id='chemicalSoakingDuration_CIP'
                  name='chemicalSoakingDuration_CIP'
                  isError={checkError("chemicalSoakingDuration_CIP")}
                  inputText='min'
                  placeholder='0.00'
                  defaultValue='90'
                  value={
                    isFocused === "chemicalSoakingDuration_CIP"
                      ? cipData.chemicalSoakingDuration_CIP
                      : convertUptoDigits(cipData.chemicalSoakingDuration_CIP)
                  }
                  onChange={handleInputChange}
                  isFocused={isFocused === "chemicalSoakingDuration_CIP"}
                  onBlur={handleBlur}
                  onFocus={() => handleFocus("chemicalSoakingDuration_CIP")}
                  tabIndex={13}
                />
                <InputRanges ranges={validations.chemicalSoakingDuration_CIP} />
              </div>
            </div>
          </CustomCard>
        </div>
        {inputError && <ProjectErrorPopup show close={closeErrorMessag} message={inputError} />}
      </CIPStyled>
    </>
  );
};

export default CIP;
