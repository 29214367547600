import html2pdf from "html2pdf.js";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getUfCalcReport } from "src/api/reportApi/reportApi";

import { APP_TOAST_NOTIFICATION } from "@constants/message.constants";
import { reportStrings } from "@constants/report.strings";

import { useFullScreenLoader } from "@components/WPFullScreenLoader";
import { useToastNotification } from "@components/WPToastNotification";

import { setUfCalcReportApiResp } from "@common/ReportSlice";

import useProjectDetails from "./useProjectDetails";
import useUFConfig from "./useUFConfig";

export const useReport = () => {
  const { getConfigPayload } = useUFConfig();
  const { projectDetails } = useProjectDetails();
  const { showFullScreenLoader, hideFullScreenLoader } = useFullScreenLoader();

  const { activeCaseId: caseID, projectID, projectName, activeCaseName } = projectDetails;
  const dispatch = useDispatch();

  const { showSuccessNotification, showErrorNotification } = useToastNotification();

  const getUfReportData = async () => {
    try {
      dispatch(setUfCalcReportApiResp({ isLoading: true }));
      const { requestConfig: ufReport } = getConfigPayload();
      const response = await getUfCalcReport({ ufReport, projectID, caseID });
      dispatch(setUfCalcReportApiResp({ data: response.data, error: null }));
    } catch (err) {
      dispatch(setUfCalcReportApiResp({ data: null, error: err }));
    }
    dispatch(setUfCalcReportApiResp({ isLoading: false }));
  };

  const convertToPdf = async () => {
    await new Promise(resolve => setTimeout(resolve, 0)); // added to show downloading loader immediately
    const formattedDateTimeNow = moment().format("YYYY-MM-DD:HH:mm:ss");
    const reportName = `${projectName}_${activeCaseName}_${formattedDateTimeNow}.pdf`;

    const element = document.getElementById("content-to-print");

    const options = {
      margin: 0.5,
      filename: reportName,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      pagebreak: {
        // TODO: explore more about this
        mode: ["avoid-all", "css", "legacy"],
      },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    return await html2pdf().from(element).set(options).save();
  };

  const generatePDF = async () => {
    showFullScreenLoader(reportStrings.downloadingReportMsg);
    try {
      await convertToPdf();
      showSuccessNotification(APP_TOAST_NOTIFICATION.REPORT_DOWNLOADED);
    } catch (error) {
      showErrorNotification(APP_TOAST_NOTIFICATION.REPORT_DOWNLOAD_FAIL);
    } finally {
      hideFullScreenLoader();
    }
  };

  return { getUfReportData, generatePDF };
};
