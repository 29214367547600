import styled from "styled-components";

const StyledSVGPath = styled.svg`
  path {
    fill: var(--du-color-icon);
   // fill-opacity: 0.7;
    ${({ disabled }) => disabled && `
      fill: var(--du-color-icon-disabled)
    `}
  }
`;
const SVGContainer = styled.div`
  &:hover ${StyledSVGPath} path {
    ${({ disabled }) => !disabled && `
      fill: var(--du-color-icon-hover);
      stroke: var(--du-color-icon-hover);
      stroke-width: 1;
    `}
  }
`;

const SVGContainerPath = props => (
  <SVGContainer disabled={props.disabled}>
    <StyledSVGPath {...props} />
  </SVGContainer>
);

export default SVGContainerPath;