import { useReportUtils } from "@features/report-new/useReportUtils";

import { WPUfConfiguringationOptionsTableLabels as TableLabels } from "./WPUfConfiguringationOptionsTable.constants";

export const useWPUfConfigurationOptionsTableData = () => {
  const { ufReportRespData } = useReportUtils();

  const convertToUfConfigurationOptionsTableData = () => {
    const data = Object.keys(TableLabels).map(label => {
      const value = ufReportRespData?.uf_configuration_options?.[label];
      return value ? { col1: TableLabels[label], col2: value } : {};
    });

    return data;
  };

  return { convertToUfConfigurationOptionsTableData };
};
